import {
  CHAT,
  HAPPY_MOMENTS,
  POST,
  PROFILE,
  STREAM,
  UNSET,
} from "enums/giftRecipientType";
import { GIFTS_SET_CONTEXT } from "state/actionTypes";

export const actionCreators = {
  setLiveStreamGiftingContext: ({
    streamId,
    broadcasterId,
    isBroadcast = false,
  }) => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: STREAM,
      recipientAccountId: broadcasterId,
      secondaryRecipientId: streamId,
      isBroadcast,
    },
  }),
  setTimelinePostGiftingContext: ({ postId, authorId }) => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: POST,
      recipientAccountId: authorId,
      secondaryRecipientId: postId,
    },
  }),
  setUserProfileGiftingContext: ({ userId }) => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: PROFILE,
      recipientAccountId: userId,
      secondaryRecipientId: "",
    },
  }),
  setHappyMomentsGiftingContext: ({ streamerId, storyId }) => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: HAPPY_MOMENTS,
      recipientAccountId: streamerId,
      secondaryRecipientId: storyId,
    },
  }),
  setChatGiftingContext: ({ chatId, recipientId }) => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: CHAT,
      recipientAccountId: recipientId,
      secondaryRecipientId: chatId,
    },
  }),
  resetGiftingContext: () => ({
    type: GIFTS_SET_CONTEXT,
    payload: {
      recipientType: UNSET,
      recipientAccountId: "",
      secondaryRecipientId: "",
    },
  }),
};

export const selectors = {
  getRecipientType: (state) => state.recipientType,
  getRecipientAccountId: (state) => state.recipientAccountId,
  getSecondaryRecipientId: (state) => state.secondaryRecipientId,
};

const initialState = {
  recipientType: UNSET,
  recipientAccountId: "",
  secondaryRecipientId: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GIFTS_SET_CONTEXT:
      return {
        ...state,
        ...action.payload,
      };
  }
  return state;
};
