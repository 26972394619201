import { createContext } from "react";

export interface ConversationMessagesAnimatedMenuContextData {
  headerHeight: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

export const ConversationMessagesAnimatedMenuContext =
  createContext<ConversationMessagesAnimatedMenuContextData>({
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    height: 0,
    headerHeight: 0,
  });
