import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import classnames from "classnames";
import { MemoizedChatContent as ChatContent } from "chat/components/ChatContent/ChatContent";
import { ConversationListTangoScreenViewAnalytics } from "chat/components/ConversationListTangoScreenViewAnalytics";
import ConversationPlaceholder from "chat/components/ConversationPlaceholder";
import ConversationPlaceholderSkeleton from "chat/components/ConversationPlaceholder/ConversationPlaceholderSkeleton";
import EmptyState from "chat/components/EmptyState/EmptyState";
import BannersSkeleton from "chat/components/common/BannersSkeleton";
import DeleteAllButton from "chat/components/common/DeleteAllButton/DeleteAllButton";
import { LiveBarSkeleton } from "chat/components/common/LiveBarSkeleton/LiveBarSkeleton";
import CurrentConversation from "chat/components/currentConversation/CurrentConversation";
import { useChatEvents } from "chat/hooks/useChatEvents";
import { useDeleteChatToast } from "chat/hooks/useDeleteChatToast";
import { useMessageAndReactionReadIndication } from "chat/hooks/useMessageAndReactionReadIndication";
import { GridBanners, Scrollbar } from "chat/imports/components";
import {
  BannerType,
  Breakpoints,
  TANGO_ACCOUNT_ID,
  linkToChat,
  linkToMessageRequest,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { RootState } from "chat/imports/state";
import {
  BackHeader,
  HeaderControls,
  MessageRequestsEmptyState,
} from "chat/messageRequest/exports/components";
import { messageRequestSelectors } from "chat/messageRequest/state/selectors";
import {
  getIsChatLiveBarEnabled,
  getIsMessageReadIndicatorEnabled,
} from "chat/soc/chatSoc";
import { StoredConversation } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import styles from "./ChatLayout.scss";

interface ChatLayoutProps {
  conversations: StoredConversation[];
  currentConversation: "" | StoredConversation | undefined;
  currentConversationId: string | undefined;
  isMessageRequestEnabled: boolean;
  isRequestsVisible?: boolean;
  setIsRequestsVisible: (state: boolean) => void;
}

const selector = (state: RootState) => ({
  isConversationsLoading: chatSelectors.meta(state).loading,
  isMessageRequestLoading: messageRequestSelectors.meta(state).loading,
  isMessageRequestError: messageRequestSelectors.meta(state).error,
  messageRequestsCount:
    messageRequestSelectors.getTotalConversationRequestCount(state),
  unreadStatus: chatSelectors.getUnreadMessagesStatus(state),
  isFetchUnreadMessagesInProgress:
    chatSelectors.getIsUnreadRequestInProgress(state),
  lastReadTs: chatSelectors.getLastReadTs(state),
  isMessageReadIndicatorEnabled: getIsMessageReadIndicatorEnabled(state),
  isChatLiveBarEnabled: getIsChatLiveBarEnabled(state),
});

const ChatLayout: React.FC<ChatLayoutProps> = ({
  currentConversationId,
  conversations,
  isRequestsVisible,
  setIsRequestsVisible,
  currentConversation,
  isMessageRequestEnabled,
}) => {
  const {
    isConversationsLoading,
    isMessageRequestLoading,
    isMessageRequestError,
    messageRequestsCount,
    isChatLiveBarEnabled,
  } = useSelector(selector, shallowEqual);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const backHeaderRef = useRef<HTMLDivElement>(null);
  const chatContentRef = useRef<HTMLDivElement>(null);
  const isLoadingTriggeredByActions = useRef(true);
  const breakpoint = useBreakpointPrecise();
  const history = useHistory();
  const isMessageRequestRoute = !!useRouteMatch(linkToMessageRequest);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const noChatsWithUsers =
    conversations.length === 1 &&
    conversations[0].conversation_id === TANGO_ACCOUNT_ID;

  useChatEvents();
  useMessageAndReactionReadIndication();
  useDeleteChatToast();

  const onClickRequestsVisible = useCallback(() => {
    if (isDesktop) {
      setIsRequestsVisible(!isRequestsVisible);
    } else {
      isMessageRequestRoute
        ? history.push(linkToChat)
        : history.push(linkToMessageRequest);
    }
  }, [
    history,
    isDesktop,
    isMessageRequestRoute,
    isRequestsVisible,
    setIsRequestsVisible,
  ]);

  const scrollConversationsListToTop = useCallback(() => {
    if (scrollbarRef.current) {
      if (isDesktop) {
        scrollbarRef.current.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [scrollbarRef, isDesktop]);

  useEffect(() => {
    scrollConversationsListToTop();
  }, [isRequestsVisible]);

  const isShowHeaderControls = isMessageRequestEnabled && isDesktop;

  const isShowBackHeader =
    isMessageRequestEnabled &&
    isRequestsVisible &&
    (!isMessageRequestLoading || messageRequestsCount > 0);

  const renderEmptyState = useMemo(() => {
    if (
      isConversationsLoading &&
      isMessageRequestLoading &&
      !conversations.length
    ) {
      return <ConversationPlaceholderSkeleton />;
    }

    if (noChatsWithUsers) {
      return <EmptyState />;
    }

    if (!isDesktop && conversations.length) {
      return null;
    }

    if (!isDesktop && isRequestsVisible && !conversations.length) {
      return <MessageRequestsEmptyState breakpoint={breakpoint} />;
    }

    return <ConversationPlaceholder />;
  }, [
    isConversationsLoading,
    isMessageRequestLoading,
    conversations.length,
    noChatsWithUsers,
    isDesktop,
    isRequestsVisible,
    breakpoint,
  ]);

  const renderConversation = useMemo(
    () =>
      currentConversation ? (
        <CurrentConversation
          conversation={currentConversation}
          setIsRequestsVisible={setIsRequestsVisible}
        />
      ) : (
        renderEmptyState
      ),
    [currentConversation, renderEmptyState, setIsRequestsVisible]
  );

  const renderConversationsList = useMemo(
    () => (
      <ConversationListTangoScreenViewAnalytics
        isChatRequestConversationList={!!isRequestsVisible}
      >
        <Scrollbar
          wrapperClassName={styles.conversationsList}
          ref={scrollbarRef}
          data-testid={
            isRequestsVisible
              ? "message-requests-container-scrollbar"
              : "chat-container-scrollbar"
          }
        >
          {isShowHeaderControls && !isRequestsVisible && (
            <HeaderControls
              onClick={onClickRequestsVisible}
              count={messageRequestsCount}
            />
          )}
          {isShowBackHeader && (
            <BackHeader
              onClick={onClickRequestsVisible}
              isDesktop={isDesktop}
              isShowDescription={
                conversations.length > 0 && !isMessageRequestError
              }
              ref={backHeaderRef}
            />
          )}

          <ChatContent
            isRequestsVisible={isRequestsVisible}
            conversations={conversations}
            currentConversationId={currentConversationId}
            isLoadingTriggeredByActions={isLoadingTriggeredByActions}
            breakpoint={breakpoint}
            ref={chatContentRef}
          />
        </Scrollbar>

        {isRequestsVisible && conversations.length > 0 && (
          <div className={styles.footer}>
            <DeleteAllButton className={styles.deleteAll} />
          </div>
        )}
      </ConversationListTangoScreenViewAnalytics>
    ),
    [
      breakpoint,
      isShowHeaderControls,
      isRequestsVisible,
      onClickRequestsVisible,
      isShowBackHeader,
      isDesktop,
      conversations,
      isMessageRequestError,
      currentConversationId,
      messageRequestsCount,
    ]
  );

  const renderChatContent = useMemo(() => {
    if (isDesktop) {
      return (
        <>
          {renderConversationsList}
          {renderConversation}
        </>
      );
    }

    return currentConversationId ? (
      renderConversation
    ) : (
      <>
        {!isRequestsVisible &&
          (isConversationsLoading && !conversations.length ? (
            <BannersSkeleton className={styles.banners} />
          ) : (
            <GridBanners className={styles.banners} type={BannerType.CHATS} />
          ))}

        {renderConversationsList}
        {!isConversationsLoading && renderEmptyState}
      </>
    );
  }, [
    isDesktop,
    isRequestsVisible,
    isConversationsLoading,
    conversations.length,
    renderConversationsList,
    renderEmptyState,
    renderConversation,
    currentConversationId,
  ]);

  useEffect(() => {
    if (!isShowBackHeader && !currentConversation) {
      return;
    }

    if (backHeaderRef.current && chatContentRef.current) {
      const { height } = backHeaderRef.current.getBoundingClientRect();

      chatContentRef.current.style.setProperty(
        "--chat-container-margin-top",
        `${height}px`
      );
    }
  }, [isShowBackHeader, currentConversation]);

  return (
    <div
      className={classnames(styles.root, styles[breakpoint])}
      data-testid="chat-container"
    >
      {
        // TODO: https://tango-me.atlassian.net/browse/WEB-9521 add real load condition instead isConversationsLoading
        isChatLiveBarEnabled && !isDesktop && isConversationsLoading && (
          <LiveBarSkeleton breakpoint={breakpoint} />
        )
      }
      {renderChatContent}
    </div>
  );
};

export default memo(ChatLayout);
