import React, { FC, Suspense, forwardRef } from "react";
import { useSelector } from "react-redux";
import { DeviceType } from "src/enums";
import { PlayerType, getPlayerType } from "src/state/SOC/playback";
import TangoPlayer from "src/ui/player/TangoPlayer";
import lazy from "src/utils/lazyWithPageReload";
import { deviceInfoSelectors } from "state/selectors";
import HLSPlayer from "./HLSPlayer";
import { PlayerProps } from "./PlayerProps";

const Player = lazy(
  () =>
    import(
      /* webpackChunkName: "player" */ /* webpackPrefetch: true */ "./Player"
    )
);

const LazyPlayerImpl = forwardRef((props, ref) => (
  <Suspense fallback={null}>
    {/* @ts-ignore */}
    <Player {...props} forwardedRef={ref} />
  </Suspense>
));

LazyPlayerImpl.displayName = "LazyPlayerImpl";

export const LazyPlayer = forwardRef((props, ref) => (
  <LazyPlayerImpl {...props} ref={ref} />
));

LazyPlayer.displayName = "LazyPlayer";

interface MetaPlayerProps extends PlayerProps {
  autoPlay: boolean;
  className: string;
  onHlsPlaybackStartError: VoidFunction;
  playInline: boolean;
}

const MetaPlayer: FC<MetaPlayerProps> = forwardRef<
  HTMLCanvasElement | HTMLVideoElement,
  MetaPlayerProps
>((props, ref) => {
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);
  const playerType = useSelector(getPlayerType(deviceType));
  const { playInline, autoPlay, onHlsPlaybackStartError, ...rest } = props;
  switch (playerType) {
    case PlayerType.TANGO_PLAYER:
      return (
        <TangoPlayer
          className={props.className}
          url={props.src}
          muted={props.muted}
          paused={props.paused}
          listeners={props.videoEventListeners}
        />
      );
    case PlayerType.HLS_PLAYER: {
      if (deviceType === DeviceType.DESKTOP) {
        return (
          <LazyPlayer {...{ onHlsPlaybackStartError, ...rest }} ref={ref} />
        );
      }

      return (
        <HLSPlayer
          {...{ playInline, autoPlay, ...rest }}
          forwardedRef={ref as React.MutableRefObject<HTMLVideoElement>}
        />
      );
    }
  }
});

MetaPlayer.displayName = "MetaPlayer";

export default MetaPlayer;
