import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPost } from "src/features/feed/api/feed";
import { ApiError } from "src/features/feed/imports/api";
import {
  PicturePost as PicturePostProto,
  StoryPost as StoryPostProto,
  VideoPost as VideoPostProto,
} from "src/features/feed/imports/generated";
import {
  FetcherMetaV2,
  HTTP_CODE_ERROR,
} from "src/features/feed/imports/state";
import {
  convertLongToString,
  parseMessageFromBase64,
} from "src/features/feed/imports/utils";
import {
  FeedInnerSliceName,
  FeedPostErrorMessage,
} from "src/features/feed/state/types";
import { Post as PostInterface } from "src/features/feed/types/post";
import { PostType } from "src/features/feed/types/postType";
import { RawPost } from "src/features/feed/types/rawPost";
import { ResponseCode } from "src/features/feed/types/responseCode";

const parseRawPost = (
  rawPost: RawPost,
  proto: typeof PicturePostProto | typeof StoryPostProto | typeof VideoPostProto
): PostInterface => {
  const parsedWithLongs = parseMessageFromBase64(rawPost.content, proto);
  const parsedWithLongStrings = convertLongToString(parsedWithLongs);

  return { ...rawPost, content: parsedWithLongStrings } as PostInterface;
};

export const fetchFeedPost = createAsyncThunk<
  Awaited<PostInterface>,
  FetcherMetaV2 & Parameters<typeof fetchPost>[0],
  { rejectValue: FeedPostErrorMessage }
>(`${FeedInnerSliceName.POST}/fetch`, async (postId, { rejectWithValue }) => {
  try {
    const { code, post: rawPost } = await fetchPost(postId);

    if (code !== ResponseCode.OK) {
      return rejectWithValue(FeedPostErrorMessage.NOT_FOUND);
    }

    if (!rawPost) {
      return rejectWithValue(FeedPostErrorMessage.NOT_FOUND);
    }

    if (
      rawPost.type !== PostType.PICTURE &&
      rawPost.type !== PostType.STORY &&
      rawPost.type !== PostType.EDITED_STORY &&
      rawPost.type !== PostType.VIDEO
    ) {
      return rejectWithValue(FeedPostErrorMessage.TYPE_NOT_SUPPORTED);
    }

    if (!rawPost.unlocked) {
      return rejectWithValue(FeedPostErrorMessage.FANS_ONLY_NOT_SUPPORTED);
    }

    switch (rawPost.type) {
      case PostType.PICTURE:
        return parseRawPost(rawPost, PicturePostProto);

      case PostType.STORY:
      case PostType.EDITED_STORY:
        return parseRawPost(rawPost, StoryPostProto);

      case PostType.VIDEO:
        return parseRawPost(rawPost, VideoPostProto);
    }
  } catch (error) {
    /*
     * Usually, when the provided postId is not supported by the backend.
     * The backend expects a long integer.
     */
    if (error instanceof ApiError && error.status === HTTP_CODE_ERROR) {
      return rejectWithValue(FeedPostErrorMessage.NOT_FOUND);
    }

    return rejectWithValue(FeedPostErrorMessage.SOMETHING_WENT_WRONG);
  }
});
