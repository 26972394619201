import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveBar } from "chat/liveBar/api/liveBarRequestApi";
import {
  GetLiveBarRequestsParams,
  GetLiveBarRequestsResponse,
} from "chat/liveBar/types";
import { RootState } from "chat/messageRequest/imports/types";
import { isApiError } from "chat/messageRequest/imports/utils";
import { FetcherMetaV2 } from "state/hor/addFetcherActionsToBuilder";

export const fetchLiveBarRequest = createAsyncThunk<
  GetLiveBarRequestsResponse,
  FetcherMetaV2 & GetLiveBarRequestsParams,
  { rejectValue: string; state: RootState }
>("lwc/liveBar/fetchLiveBarRequest", async (requestParams, api) => {
  try {
    return await getLiveBar(requestParams);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
