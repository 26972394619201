import React, { FC, memo, useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { DEFAULT_GIFT_LINK } from "src/constants";
import { Shader } from "src/features/giftAnimation/exports";
import { getIsARGiftsEnabled } from "src/features/gifts/exports/giftsSoc";
import { useBanubaSDKEnabled } from "src/features/stream/exports/hooks";
import {
  getGiftAnimationsAlternativeDomainContentSupportEnabled,
  getGiftsSoundMp4Enabled,
  getGiftsVideoMp4Enabled,
} from "src/state/abTests";
import { RootState } from "src/state/delegate";
import {
  streamsCacheSelectors,
  viewerSessionSelectors,
} from "src/state/selectors";
import { VoidCallback } from "src/types/common";
import { Gift, SpecialKind } from "src/types/gift";
import GiftAnimation from "src/ui/animations/GiftAnimation";
import GiftAnimationZip from "src/ui/animations/GiftAnimationZip";
import GiftVideo, { GiftVideoProps } from "src/ui/animations/GiftVideo";
import useGenerateEnabledVideoSources from "src/ui/animations/useGenerateVideoSources";
import { useMakeAlternativeDomainUrl } from "src/ui/hooks/useMakeAlternativeDomainUrl";
import MuteContext from "ui/scenes/stream/MuteContext";
import GiftARAnimation from "./GiftARAnimation";

export interface GiftAnimationProps {
  components?: {
    GiftVideo?: FC<GiftVideoProps>;
    overlayImageUrl?: null | string;
  };
  gift: Gift;
  onComplete?: VoidCallback;
  onFailed?: VoidCallback;
}

const selector = (state: RootState) => ({
  isGiftsVideoMp4Enabled: getGiftsVideoMp4Enabled(state),
  isGiftsSoundMp4Enabled: getGiftsSoundMp4Enabled(state),
  isARGiftsEnabled: getIsARGiftsEnabled(state),
  streamSettings: streamsCacheSelectors.getStreamSettingsById(
    state,
    viewerSessionSelectors.getStreamId(state)
  ),
});

const GiftAnimationFactory: FC<GiftAnimationProps> = ({
  components = {},
  gift,
  onComplete,
  onFailed,
}) => {
  const { muted: isStreamMuted } = useContext(MuteContext);
  const sources = useGenerateEnabledVideoSources(gift);
  const {
    isGiftsVideoMp4Enabled,
    isGiftsSoundMp4Enabled,
    isARGiftsEnabled,
    streamSettings,
  } = useSelector(selector, shallowEqual);

  const isGiftsSoundEnabled =
    isGiftsSoundMp4Enabled && !streamSettings?.muteGiftSound && !isStreamMuted;

  const { isBanubaSDKEnabled } = useBanubaSDKEnabled();

  const {
    lottieAnimationUrl,
    lottieAnimationZipUrl,
    icon,
    name,
    assetBundle,
    id,
    mp4AnimationUrl,
    special,
    arAnimationUrlV2,
    arGiftDetails,
  } = gift;

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftAnimationsAlternativeDomainContentSupportEnabled
  );

  const iconUrl = makeAlternativeDomainUrl(icon);
  const defaultGiftLink = makeAlternativeDomainUrl(DEFAULT_GIFT_LINK);

  if (
    isARGiftsEnabled &&
    isBanubaSDKEnabled &&
    special === SpecialKind.AR_GIFT &&
    arAnimationUrlV2
  ) {
    return (
      <GiftARAnimation
        name={name}
        iconUrl={iconUrl}
        bundleUrl={makeAlternativeDomainUrl(arAnimationUrlV2)}
        duration={
          arGiftDetails?.duration ? Number(arGiftDetails.duration) : undefined
        }
        onComplete={onComplete}
      />
    );
  }

  if (isGiftsVideoMp4Enabled && mp4AnimationUrl) {
    return (
      <Shader
        videoUrl={makeAlternativeDomainUrl(mp4AnimationUrl)}
        overlayImageUrl={components.overlayImageUrl}
        isSoundEnabled={isGiftsSoundEnabled}
        onComplete={onComplete}
        onFailed={onFailed}
      />
    );
  }

  if (lottieAnimationUrl) {
    return (
      <GiftAnimation
        url={makeAlternativeDomainUrl(lottieAnimationUrl)}
        onComplete={onComplete}
      />
    );
  }

  if (sources.length) {
    const Component = components.GiftVideo || GiftVideo;

    return <Component sources={sources} onComplete={onComplete} />;
  }

  if (lottieAnimationZipUrl) {
    return (
      <GiftAnimationZip
        url={makeAlternativeDomainUrl(lottieAnimationZipUrl)}
        assetsPath={iconUrl}
        onComplete={onComplete}
        giftId={id}
        defaultGiftLink={defaultGiftLink}
      />
    );
  }

  if (assetBundle) {
    return (
      <GiftAnimationZip
        url={makeAlternativeDomainUrl(assetBundle)}
        assetsPath={iconUrl}
        onComplete={onComplete}
        giftId={id}
        defaultGiftLink={defaultGiftLink}
      />
    );
  }

  if (icon) {
    return (
      <GiftAnimation
        url={defaultGiftLink}
        assetsPath={iconUrl}
        onComplete={onComplete}
      />
    );
  }

  return null;
};

export default memo(GiftAnimationFactory);
