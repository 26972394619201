import { batch } from "react-redux";
import {
  fetchGiftsBalance,
  fetchTotalCredits,
  fetchUserBalance,
} from "api/gifts";
import { loadPoints } from "src/api/points";
import {
  setBalanceAction,
  setFailedBalanceAction,
} from "src/state/tree/pointsSlice";
import {
  failedToFetchGiftsBalance,
  failedToFetchUserBalance,
  fetchedGiftsBalance,
  fetchedTotalCredits,
  fetchedUserBalance,
  willFetchGiftsBalance,
  willFetchUserBalance,
} from "state/actionCreators/balance";
import { genericNetworkError } from "state/actionCreators/networkError";
import { awaitForAcme } from "state/middleware/acmeObserver";
import {
  connectionManagerSelectors,
  loginSelectors,
  userSelectors,
} from "state/selectors";

export const getCleanPoints = (points, suspiciousPoints) => {
  if (!suspiciousPoints) {
    return points;
  }

  if (suspiciousPoints >= points) {
    return 0;
  }

  return points - suspiciousPoints;
};

const loadBalances =
  (force = false) =>
  async (dispatch, getState) => {
    const state = getState();
    if (!loginSelectors.isLoggedIn(state)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject();
    }
    if (
      !force &&
      ((userSelectors.isCoinsBalanceUpToDate(state) &&
        userSelectors.isPointsBalanceUpToDate(state)) ||
        userSelectors.isFetchingBalance(state))
    ) {
      return;
    }
    const accountId = userSelectors.getMyAccountId(state);
    const userName = connectionManagerSelectors.getUsername(state);

    batch(() => {
      dispatch(willFetchUserBalance());
      dispatch(willFetchGiftsBalance());
    });

    return Promise.all(
      [
        fetchUserBalance(),
        fetchGiftsBalance(),
        loadPoints({ accountId, userName }),
      ].map((p) =>
        p
          .then((res) => ({ success: true, value: res }))
          .catch((e) => ({ success: false, value: e }))
      )
    ).then(([userBalanceRes, giftsBalanceRes, pointsBalance]) => {
      if (userBalanceRes.success) {
        const {
          userBalance: {
            credits,
            points,
            vipInfo: { level: vipStatus = null, coinsLeft, vipLevelInfo } = {},
          },
        } = userBalanceRes.value;
        let cleanPoints = points;

        if (pointsBalance.success) {
          cleanPoints = getCleanPoints(
            points,
            pointsBalance.value.suspiciousPoints
          );
        }

        dispatch(
          fetchedUserBalance({
            credits,
            points: cleanPoints,
            vipStatus,
            accountId,
            coinsLeft,
            vipLevelInfo,
          })
        );
      } else {
        dispatch(failedToFetchUserBalance(userBalanceRes.value));
      }

      if (giftsBalanceRes.success) {
        dispatch(fetchedGiftsBalance(giftsBalanceRes.value));
      } else {
        dispatch(failedToFetchGiftsBalance(giftsBalanceRes.value));
      }

      if (pointsBalance.success) {
        dispatch(setBalanceAction(pointsBalance.value));
      } else {
        dispatch(setFailedBalanceAction(pointsBalance.value));
      }
    });
  };

export const loadBalancesIfNoAcmeWithinTimeout =
  (timeoutMillis) => async (dispatch) => {
    try {
      await awaitForAcme(
        { serviceName: "gift", serviceIdentifier: "syncCredit" },
        timeoutMillis
      );
    } catch (e) {
      return dispatch(loadBalances(true));
    }
  };

export default loadBalances;

export const refreshCoinsTotal = () => async (dispatch, getState) => {
  if (userSelectors.isCoinsTotalBalanceUpToDate(getState())) {
    return;
  }
  try {
    const totalCredits = await fetchTotalCredits();
    dispatch(fetchedTotalCredits(totalCredits));
  } catch (error) {
    dispatch(genericNetworkError(error));
  }
};
