import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { getMessageFlag } from "chat/analytics/emitMessageActionEvent";
import { MessageReaction } from "chat/components/currentConversation/components/MessageReactions/MessageReaction";
import {
  CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID,
  CHAT_ANALYTICS_PARAMS_CLICK_ACTION,
  CHAT_ANALYTICS_PARAMS_EMOJI_TARGET,
} from "chat/constants";
import { REACTION } from "chat/enums";
import { chatSelectors } from "chat/exports/state/selectors";
import { RootState } from "chat/imports/state";
import {
  cleanTouchReactionLottieData,
  sendMessageReaction,
  updateMessageReaction,
} from "chat/state";
import { MessageIdentifier, TangoScreenState } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import styles from "./MessageReactions.scss";

const selector =
  (messageId: number, currentConversationId: null | string) =>
  (state: RootState) => ({
    allMessageReactions: chatSelectors.getMessageReactions(
      state,
      currentConversationId,
      messageId
    ),
    isMessageHasSingleReaction: chatSelectors.getIsMessageHasSingleReaction(
      state,
      currentConversationId,
      messageId
    ),
    storedMessage: chatSelectors.getMessageById(
      state,
      currentConversationId,
      messageId
    ),
    messageReaction: chatSelectors.getMyMessageReaction(
      state,
      currentConversationId,
      messageId
    ),
    touchedReactionInfo: useSelector(chatSelectors.getTouchedReactionInfo),
  });

interface MessageReactionsProps {
  accountId?: string;
  isMyMessage: boolean;
  message: MessageIdentifier;
  messageSenderId: string | undefined;
}

export const MessageReactions = memo(
  ({
    isMyMessage,
    messageSenderId,
    message,
    accountId = "",
  }: MessageReactionsProps) => {
    const dispatch = useDispatch();
    const [showAnimationId, setShowAnimationId] = useState<
      REACTION | undefined
    >(undefined);
    const conversationId = useSelector(chatSelectors.getCurrentConversationId);
    const isGroupChat = isGroupChatId(conversationId);
    const {
      allMessageReactions,
      isMessageHasSingleReaction,
      touchedReactionInfo,
      storedMessage,
      messageReaction,
    } = useSelector(selector(message.id, conversationId), shallowEqual);

    const myReactionIndex = useMemo(
      () =>
        allMessageReactions?.messageReactions.findIndex(
          (reaction) =>
            allMessageReactions?.myReactionId === reaction.reactionId
        ),
      [allMessageReactions?.messageReactions, allMessageReactions?.myReactionId]
    );

    const handleAnalyticsParams = useCallback(
      () => ({
        target: CHAT_ANALYTICS_PARAMS_EMOJI_TARGET,
        action: CHAT_ANALYTICS_PARAMS_CLICK_ACTION,
        chatId: conversationId ?? "",
        flags: [getMessageFlag(storedMessage!)],
        tangoScreen: isGroupChat
          ? TangoScreenState.CHAT_GROUP
          : TangoScreenState.CHAT_SINGLE,
        messageId: storedMessage?.id.id,
        sourceId: CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID,
        recipientAccountId: accountId ?? "",
        itemType: messageReaction,
      }),
      [conversationId, storedMessage, accountId, messageReaction, isGroupChat]
    );

    const onReactionClickHandler = useCallback(
      (reaction: REACTION) =>
        (
          e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
        ) => {
          e.stopPropagation();
          if (allMessageReactions?.myReactionId === reaction) {
            dispatch(
              sendMessageReaction({
                reactionId: reaction,
                deleteReaction: true,
                messageSenderId,
                message,
                analyticsParams: handleAnalyticsParams(),
              })
            );
          } else if (allMessageReactions.myReactionId) {
            dispatch(
              updateMessageReaction({
                oldReaction: allMessageReactions?.myReactionId,
                newReaction: reaction,
                message,
                messageSenderId,
                analyticsParams: handleAnalyticsParams(),
              })
            );
          } else {
            dispatch(
              sendMessageReaction({
                reactionId: reaction,
                deleteReaction: false,
                messageSenderId,
                message,
                analyticsParams: handleAnalyticsParams(),
              })
            );
          }
        },
      [
        allMessageReactions?.myReactionId,
        dispatch,
        message,
        messageSenderId,
        handleAnalyticsParams,
      ]
    );

    const handleLottieComplete = useCallback(() => {
      setShowAnimationId(undefined);
      dispatch(cleanTouchReactionLottieData());
    }, [dispatch]);

    useEffect(() => {
      if (message.id === touchedReactionInfo?.messageId) {
        setShowAnimationId(touchedReactionInfo.reaction);
      }
    }, [message.id, touchedReactionInfo]);

    return (
      <div
        className={classnames(styles.root, {
          [styles.myMessage]: isMyMessage,
          [styles.singleReaction]: isMessageHasSingleReaction,
          [styles.myReactionFirst]: myReactionIndex === 0,
          [styles.myReactionLast]:
            myReactionIndex ===
            allMessageReactions?.messageReactions.length - 1,
        })}
      >
        {(allMessageReactions?.messageReactions ?? []).map(
          ({ reactionId, reactionCount }) => (
            <MessageReaction
              key={reactionId}
              handleLottieComplete={handleLottieComplete}
              reactionId={reactionId}
              onReactionClickHandler={onReactionClickHandler}
              reactionCount={reactionCount}
              isShowAnimationId={reactionId === showAnimationId}
              isMyReaction={allMessageReactions.myReactionId === reactionId}
              isMessageHasSingleReaction={isMessageHasSingleReaction}
            />
          )
        )}
      </div>
    );
  }
);

MessageReactions.displayName = "MessageReactions";
