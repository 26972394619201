import React, { FC } from "react";
import classnames from "classnames";
import { Breakpoints } from "chat/imports/constants";
import styles from "./LiveBarSkeleton.scss";

const REPEATED_LIFE_BAR_DESKTOP_COUNT = 4;
const REPEATED_LIFE_BAR_COUNT = 16;

export const LiveBarDesktopSkeleton: FC = () => (
  <div className={styles.liveBarDesktop}>
    <div className={styles.circleArrow} />
    {Array.from({ length: REPEATED_LIFE_BAR_DESKTOP_COUNT }, (_, i) => (
      <div key={i} className={styles.liveBarItem}>
        <div className={styles.circle} />
        <div className={styles.liveBarText} />
      </div>
    ))}
    <div className={styles.circleArrow} />
  </div>
);

export const LiveBarSkeleton = ({
  breakpoint,
}: {
  breakpoint: Breakpoints;
}) => (
  <div className={classnames(styles.liveBar, styles[breakpoint])}>
    <div className={styles.overlay} />
    {Array.from({ length: REPEATED_LIFE_BAR_COUNT }, (_, i) => (
      <div key={i} className={styles.liveBarItem}>
        <div className={styles.circle} />
        <div className={styles.liveBarText} />
      </div>
    ))}
  </div>
);
