import {
  parseBooleanEnabledSOC,
  parseStringSOC,
} from "src/features/customGift/imports/abTests";

export const getIsCustomGiftViewEnabled = parseBooleanEnabledSOC(
  "web.giftsDrawer.custom.gift.view.enabled",
  "0"
);

export const getIsCustomGiftCreationEnabled = parseBooleanEnabledSOC(
  "web.giftsDrawer.custom.gift.creation.enabled",
  "0"
);

export const getCustomGiftStarIcon = parseStringSOC(
  "web.giftsDrawer.custom.gift.star.icon",
  `"https://resources.tango.me/gifts/custom-gift/star-unselected.png"`
);

export const getCustomGiftStarActiveIcon = parseStringSOC(
  "web.giftsDrawer.custom.gift.star.active.icon",
  `"https://resources.tango.me/gifts/custom-gift/star-selected.png"`
);
