import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { MarketingClientVisitEvent } from "generated/proto/MarketingClientVisitEventModel";
import {
  FACEBOOK_C_COOKIE_NAME,
  FACEBOOK_P_COOKIE_NAME,
  SNAPCHAT_COOKIE_NAME,
} from "src/constants";
import { sendVisitMarketing } from "src/features/marketing/api/marketing";
import {
  AdditionalDataPoints,
  DEFAULT_SESSION_ID,
  EventNames,
  MARKETING_PLATFORM,
  Nullable,
  RootState,
  SearchParams,
  UTM_PARAMS,
  UtmParams,
  afParameters,
  cleanSearchParamsStorageByKeys,
  deviceInfoSelectors,
  getIrClickIdEnabled,
  getSnapchatEnabled,
  getTwitterMarketingEnabled,
  getVoluumEnabled,
  searchParamsStorage,
  serverOwnedConfigSelectors,
  setAppsFlyerDirectLinkURL,
  setAppsFlyerOneLinkURL,
} from "src/features/marketing/imports";
import { useIsPageForVisitor } from "src/features/marketing/imports/hooks";
import {
  getBingMarketingEnabled,
  getFacebookMarketingEnabled,
  getGoogleMarketingEnabled,
  getGooglePlayApkLink,
  getOneLinkTemplate,
  getTiktokMarketingEnabled,
  getUtmParamsEnabled,
} from "src/features/marketing/imports/state";
import {
  dataMeshTrackEvent,
  datadogLogs,
} from "src/features/marketing/imports/utils";
import { marketingSelectors } from "src/features/marketing/state/selectors";
import { setMarketingVisitData } from "src/features/marketing/state/slice";

const createPlatformPayload = (
  isEnabled: boolean,
  clickId: Nullable<string>,
  platform: MARKETING_PLATFORM
) => (isEnabled && clickId ? { [platform]: { clickId } } : {});

const selector = (state: RootState) => ({
  isIrClickIdEnabled: getIrClickIdEnabled(state),
  isVoluumEnabled: getVoluumEnabled(state),
  isSnapchatEnabled: getSnapchatEnabled(state),
  isTwitterMarketingEnabled: getTwitterMarketingEnabled(state),
  isFacebookMarketingEnabled: getFacebookMarketingEnabled(state),
  isBingMarketingEnabled: getBingMarketingEnabled(state),
  isGoogleMarketingEnabled: getGoogleMarketingEnabled(state),
  isTiktokMarketingEnabled: getTiktokMarketingEnabled(state),
  isUtmParamsEnabled: getUtmParamsEnabled(state),
  visitData: marketingSelectors.getVisitData(state),
  isServerConfigReady: serverOwnedConfigSelectors.getIsServerConfigReady(state),
  fingerprint: deviceInfoSelectors.getDeviceFingerprint(state),
  analyticsKey: marketingSelectors.getAnalyticsKey(state),
  oneLinkTemplate: getOneLinkTemplate(state),
  googlePlayApkLink: getGooglePlayApkLink(state),
});

type GeneralParams =
  | {
      [MARKETING_PLATFORM.GENERAL]: {
        landing_url: string;
        referrer_url: string;
      };
    }
  | NonNullable<unknown>;

const useVisitMarketing = () => {
  const {
    isIrClickIdEnabled,
    isVoluumEnabled,
    isSnapchatEnabled,
    isTwitterMarketingEnabled,
    isFacebookMarketingEnabled,
    isBingMarketingEnabled,
    isGoogleMarketingEnabled,
    isTiktokMarketingEnabled,
    isUtmParamsEnabled,
    visitData,
    isServerConfigReady,
    fingerprint,
    analyticsKey,
    oneLinkTemplate,
    googlePlayApkLink,
  } = useSelector(selector, shallowEqual);

  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);

  const isVisitorPath = useIsPageForVisitor();

  useEffect(() => {
    if (analyticsKey && window?.AF_SMART_SCRIPT && isServerConfigReady) {
      const setAppsFlyerLinks = async () => {
        const sessionIdValue =
          (await searchParamsStorage.getItem(
            AdditionalDataPoints.SESSION_ID
          )) || DEFAULT_SESSION_ID;

        const afParametersWithAnalyticsKey = {
          googlePlayApkLink,
          oneLinkURL: oneLinkTemplate,
          afParameters: {
            ...afParameters,
            afCustom: [
              ...afParameters.afCustom,
              {
                paramKey: AdditionalDataPoints.SESSION_ID,
                keys: [AdditionalDataPoints.SESSION_ID],
                defaultValue: sessionIdValue,
              },
              {
                paramKey: AdditionalDataPoints.ANALYTICS_ID,
                keys: [AdditionalDataPoints.ANALYTICS_ID],
                defaultValue: analyticsKey,
              },
            ],
          },
        };

        await setAppsFlyerOneLinkURL(afParametersWithAnalyticsKey);
        await setAppsFlyerDirectLinkURL(afParametersWithAnalyticsKey);
      };

      setAppsFlyerLinks();
    }
  }, [analyticsKey, window?.AF_SMART_SCRIPT, isServerConfigReady]);

  const getSearchParams = async () => {
    const irClickId =
      (await searchParamsStorage.getItem(SearchParams.IR_CLICK_ID)) ||
      searchParams.get(SearchParams.IR_CLICK_ID);
    const voluumClickId =
      (await searchParamsStorage.getItem(SearchParams.VOLUUM)) ||
      searchParams.get(SearchParams.VOLUUM);
    const snapchatClickId =
      (await searchParamsStorage.getItem(SearchParams.SNAPCHAT)) ||
      searchParams.get(SearchParams.SNAPCHAT);
    const twitterClickId =
      (await searchParamsStorage.getItem(SearchParams.TWITTER)) ||
      searchParams.get(SearchParams.TWITTER);
    const facebookClickId =
      (await searchParamsStorage.getItem(SearchParams.FACEBOOK_CLICK_ID)) ||
      searchParams.get(SearchParams.FACEBOOK_CLICK_ID);
    const bingClickId =
      (await searchParamsStorage.getItem(SearchParams.BING_CLICK_ID)) ||
      searchParams.get(SearchParams.BING_CLICK_ID);
    const googleClickId =
      (await searchParamsStorage.getItem(SearchParams.GOOGLE_CLICK_ID)) ||
      searchParams.get(SearchParams.GOOGLE_CLICK_ID);
    const tiktokClickId =
      (await searchParamsStorage.getItem(SearchParams.TIKTOK_CLICK_ID)) ||
      searchParams.get(SearchParams.TIKTOK_CLICK_ID);
    const landing_url =
      (await searchParamsStorage.getItem(AdditionalDataPoints.LANDING_URL)) ||
      window.location.href;
    const referrer_url =
      (await searchParamsStorage.getItem(AdditionalDataPoints.REFERRER_URL)) ||
      document.referrer ||
      null;
    const flirtyLevel =
      (await searchParamsStorage.getItem(AdditionalDataPoints.MARKETING_FL)) ||
      searchParams.get(AdditionalDataPoints.MARKETING_FL);
    const snapchatCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.SNAPCHAT_COOKIE_ID
      )) || Cookies.get(SNAPCHAT_COOKIE_NAME);
    const fbcCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.FACEBOOK_C_COOKIE
      )) || Cookies.get(FACEBOOK_C_COOKIE_NAME);
    const fbpCookie =
      (await searchParamsStorage.getItem(
        AdditionalDataPoints.FACEBOOK_P_COOKIE
      )) || Cookies.get(FACEBOOK_P_COOKIE_NAME);

    const utmParams = (await UTM_PARAMS.reduce(async (accPromise, curr) => {
      const acc = await accPromise;
      const param =
        (await searchParamsStorage.getItem(curr)) || searchParams.get(curr);
      if (param) {
        return { ...acc, [curr]: param };
      }

      return acc;
    }, Promise.resolve({}))) as Record<UtmParams, string>;

    return {
      searchParams,
      irClickId,
      voluumClickId,
      snapchatClickId,
      twitterClickId,
      facebookClickId,
      bingClickId,
      googleClickId,
      tiktokClickId,
      landing_url,
      referrer_url,
      flirtyLevel,
      snapchatCookie,
      fbcCookie,
      fbpCookie,
      utmParams,
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const {
        irClickId,
        voluumClickId,
        snapchatClickId,
        twitterClickId,
        facebookClickId,
        bingClickId,
        googleClickId,
        tiktokClickId,
        landing_url,
        referrer_url,
        flirtyLevel,
        snapchatCookie,
        fbcCookie,
        fbpCookie,
        utmParams,
      } = await getSearchParams();

      const additionalMarketingParams: GeneralParams = {
        [MARKETING_PLATFORM.GENERAL]: {
          ...(isUtmParamsEnabled && Object.keys(utmParams).length !== 0
            ? {
                ...utmParams,
              }
            : {}),
          analyticsKey,
          snapchatCookie,
          fbcCookie,
          fbpCookie,
          flirtyLevel,
          landing_url,
          snapchatUserAgent: window.navigator.userAgent,
          referrer_url,
        },
      };

      if (visitData.isVisitRequestNeeded && isServerConfigReady) {
        const platformPayload = {
          ...createPlatformPayload(
            isIrClickIdEnabled,
            irClickId,
            MARKETING_PLATFORM.IMPACT
          ),
          ...createPlatformPayload(
            isVoluumEnabled,
            voluumClickId,
            MARKETING_PLATFORM.VOLUUM
          ),
          ...createPlatformPayload(
            isSnapchatEnabled,
            snapchatClickId,
            MARKETING_PLATFORM.SNAPCHAT
          ),
          ...createPlatformPayload(
            isTwitterMarketingEnabled,
            twitterClickId,
            MARKETING_PLATFORM.TWITTER
          ),
          ...createPlatformPayload(
            isFacebookMarketingEnabled,
            facebookClickId,
            MARKETING_PLATFORM.FACEBOOK
          ),
          ...createPlatformPayload(
            isBingMarketingEnabled,
            bingClickId,
            MARKETING_PLATFORM.BING
          ),
          ...createPlatformPayload(
            isGoogleMarketingEnabled,
            googleClickId,
            MARKETING_PLATFORM.GOOGLE
          ),
          ...createPlatformPayload(
            isTiktokMarketingEnabled,
            tiktokClickId,
            MARKETING_PLATFORM.TIKTOK
          ),
          ...additionalMarketingParams,
        };

        if (Object.keys(platformPayload).length !== 0) {
          sendVisitMarketing({
            body: { deviceId: fingerprint, platformPayload },
            isVisitorPath,
          })
            .then(() => {
              const reportedUrlParams = Object.fromEntries(
                searchParams.entries()
              );

              const body = {
                analyticsKey,
                landingUrl: landing_url,
                referrerUrl: referrer_url,
                utmSource: utmParams[UtmParams.UTM_SOURCE],
                utmMedium: utmParams[UtmParams.UTM_MEDIUM],
                utmCampaign: utmParams[UtmParams.UTM_CAMPAIGN],
                reportedUrlParams,
              };

              dataMeshTrackEvent(
                EventNames.MARKETING_CLIENT_VISIT,
                MarketingClientVisitEvent,
                body
              );
            })
            .catch((error) => {
              datadogLogs.logger.warn(
                error?.message,
                { kind: "marketing_visit" },
                error
              );
            })
            .finally(() => {
              cleanSearchParamsStorageByKeys([
                SearchParams.IR_CLICK_ID,
                SearchParams.VOLUUM,
                SearchParams.TWITTER,
                SearchParams.FACEBOOK_CLICK_ID,
                SearchParams.BING_CLICK_ID,
                SearchParams.GOOGLE_CLICK_ID,
                SearchParams.SNAPCHAT,
                SearchParams.TIKTOK_CLICK_ID,
                AdditionalDataPoints.SESSION_ID,
                AdditionalDataPoints.LANDING_URL,
                AdditionalDataPoints.FACEBOOK_C_COOKIE,
                AdditionalDataPoints.FACEBOOK_P_COOKIE,
                AdditionalDataPoints.REFERRER_URL,
                AdditionalDataPoints.SNAPCHAT_COOKIE_ID,
                ...UTM_PARAMS,
              ]);
              dispatch(
                setMarketingVisitData({
                  isVisitRequestNeeded: false,
                })
              );
            });
        }
      }
    };

    fetchData();
  }, [isServerConfigReady, visitData, isVisitorPath]);
};

export default useVisitMarketing;
