/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

const $root = $protobuf.roots.SocialStreamV1 || ($protobuf.roots.SocialStreamV1 = {});

export const StreamListResponse = $root.StreamListResponse = (() => {

    function StreamListResponse(properties) {
        this.records = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamListResponse.prototype.code = 1;
    StreamListResponse.prototype.sessionId = "";
    StreamListResponse.prototype.totalCount = 0;
    StreamListResponse.prototype.records = $util.emptyArray;
    StreamListResponse.prototype.requestId = "";
    StreamListResponse.prototype.viewerVector = null;

    StreamListResponse.create = function create(properties) {
        return new StreamListResponse(properties);
    };

    StreamListResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).int32(message.code);
        if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
            writer.uint32(18).string(message.sessionId);
        if (message.totalCount != null && Object.hasOwnProperty.call(message, "totalCount"))
            writer.uint32(29).sfixed32(message.totalCount);
        if (message.records != null && message.records.length)
            for (let i = 0; i < message.records.length; ++i)
                $root.StreamDetailType.encode(message.records[i], writer.uint32(34).fork()).ldelim();
        if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
            writer.uint32(42).string(message.requestId);
        if (message.viewerVector != null && Object.hasOwnProperty.call(message, "viewerVector"))
            $root.ViewerVector.encode(message.viewerVector, writer.uint32(50).fork()).ldelim();
        return writer;
    };

    StreamListResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamListResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamListResponse();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.code = reader.int32();
                    break;
                }
            case 2: {
                    message.sessionId = reader.string();
                    break;
                }
            case 3: {
                    message.totalCount = reader.sfixed32();
                    break;
                }
            case 4: {
                    if (!(message.records && message.records.length))
                        message.records = [];
                    message.records.push($root.StreamDetailType.decode(reader, reader.uint32()));
                    break;
                }
            case 5: {
                    message.requestId = reader.string();
                    break;
                }
            case 6: {
                    message.viewerVector = $root.ViewerVector.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("code"))
            throw $util.ProtocolError("missing required 'code'", { instance: message });
        return message;
    };

    StreamListResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamListResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamListResponse)
            return object;
        let message = new $root.StreamListResponse();
        switch (object.code) {
        default:
            if (typeof object.code === "number") {
                message.code = object.code;
                break;
            }
            break;
        case "OK":
        case 1:
            message.code = 1;
            break;
        case "FAILED_GENERAL":
        case 2:
            message.code = 2;
            break;
        case "FAILED_UNAUTHORIZED":
        case 3:
            message.code = 3;
            break;
        case "FAILED_WRONG_STREAM_ID":
        case 4:
            message.code = 4;
            break;
        case "FAILED_TERMINATED":
        case 5:
            message.code = 5;
            break;
        case "FAILED_EXPIRED":
        case 6:
            message.code = 6;
            break;
        case "FAILED_WRONG_GIFT_ID":
        case 7:
            message.code = 7;
            break;
        case "FAILED_UNSUFFICIENT_BALANCE":
        case 8:
            message.code = 8;
            break;
        case "FAILED_DUPLICATED_TRANSACTION_ID":
        case 9:
            message.code = 9;
            break;
        case "FAILED_EXCEED_REDEEM_LIMIT":
        case 10:
            message.code = 10;
            break;
        case "FAILED_VERIFY_PAYPAL_AUTHORIZATION_CODE":
        case 11:
            message.code = 11;
            break;
        case "FAILED_NO_PAYPAL_INFO":
        case 12:
            message.code = 12;
            break;
        case "FAILED_TOO_MANY_REDEEM_REQUEST":
        case 13:
            message.code = 13;
            break;
        case "FAILED_BAD_REQUEST":
        case 14:
            message.code = 14;
            break;
        }
        if (object.sessionId != null)
            message.sessionId = String(object.sessionId);
        if (object.totalCount != null)
            message.totalCount = object.totalCount | 0;
        if (object.records) {
            if (!Array.isArray(object.records))
                throw TypeError(".StreamListResponse.records: array expected");
            message.records = [];
            for (let i = 0; i < object.records.length; ++i) {
                if (typeof object.records[i] !== "object")
                    throw TypeError(".StreamListResponse.records: object expected");
                message.records[i] = $root.StreamDetailType.fromObject(object.records[i]);
            }
        }
        if (object.requestId != null)
            message.requestId = String(object.requestId);
        if (object.viewerVector != null) {
            if (typeof object.viewerVector !== "object")
                throw TypeError(".StreamListResponse.viewerVector: object expected");
            message.viewerVector = $root.ViewerVector.fromObject(object.viewerVector);
        }
        return message;
    };

    StreamListResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.records = [];
        if (options.defaults) {
            object.code = options.enums === String ? "OK" : 1;
            object.sessionId = "";
            object.totalCount = 0;
            object.requestId = "";
            object.viewerVector = null;
        }
        if (message.code != null && message.hasOwnProperty("code"))
            object.code = options.enums === String ? $root.ResponseCode[message.code] === undefined ? message.code : $root.ResponseCode[message.code] : message.code;
        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
            object.sessionId = message.sessionId;
        if (message.totalCount != null && message.hasOwnProperty("totalCount"))
            object.totalCount = message.totalCount;
        if (message.records && message.records.length) {
            object.records = [];
            for (let j = 0; j < message.records.length; ++j)
                object.records[j] = $root.StreamDetailType.toObject(message.records[j], options);
        }
        if (message.requestId != null && message.hasOwnProperty("requestId"))
            object.requestId = message.requestId;
        if (message.viewerVector != null && message.hasOwnProperty("viewerVector"))
            object.viewerVector = $root.ViewerVector.toObject(message.viewerVector, options);
        return object;
    };

    StreamListResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamListResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamListResponse";
    };

    return StreamListResponse;
})();

export const ResponseCode = $root.ResponseCode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "OK"] = 1;
    values[valuesById[2] = "FAILED_GENERAL"] = 2;
    values[valuesById[3] = "FAILED_UNAUTHORIZED"] = 3;
    values[valuesById[4] = "FAILED_WRONG_STREAM_ID"] = 4;
    values[valuesById[5] = "FAILED_TERMINATED"] = 5;
    values[valuesById[6] = "FAILED_EXPIRED"] = 6;
    values[valuesById[7] = "FAILED_WRONG_GIFT_ID"] = 7;
    values[valuesById[8] = "FAILED_UNSUFFICIENT_BALANCE"] = 8;
    values[valuesById[9] = "FAILED_DUPLICATED_TRANSACTION_ID"] = 9;
    values[valuesById[10] = "FAILED_EXCEED_REDEEM_LIMIT"] = 10;
    values[valuesById[11] = "FAILED_VERIFY_PAYPAL_AUTHORIZATION_CODE"] = 11;
    values[valuesById[12] = "FAILED_NO_PAYPAL_INFO"] = 12;
    values[valuesById[13] = "FAILED_TOO_MANY_REDEEM_REQUEST"] = 13;
    values[valuesById[14] = "FAILED_BAD_REQUEST"] = 14;
    return values;
})();

export const StreamDetailType = $root.StreamDetailType = (() => {

    function StreamDetailType(properties) {
        this.tags = [];
        this.relatedStreams = [];
        this.hashtags = [];
        this.streamerVector = [];
        this.capabilities = [];
        this.stateEntries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamDetailType.prototype.stream = null;
    StreamDetailType.prototype.anchor = null;
    StreamDetailType.prototype.anchorPoints = 0;
    StreamDetailType.prototype.viewerCount = 0;
    StreamDetailType.prototype.likeCount = 0;
    StreamDetailType.prototype.postId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    StreamDetailType.prototype.isPublic = false;
    StreamDetailType.prototype.source = "";
    StreamDetailType.prototype.totalPointsInStream = 0;
    StreamDetailType.prototype.uniqueViewerCount = 0;
    StreamDetailType.prototype.tags = $util.emptyArray;
    StreamDetailType.prototype.country = null;
    StreamDetailType.prototype.featured = false;
    StreamDetailType.prototype.ticketPrice = 0;
    StreamDetailType.prototype.redirectStreamId = "";
    StreamDetailType.prototype.relatedStreams = $util.emptyArray;
    StreamDetailType.prototype.subscriberCount = 0;
    StreamDetailType.prototype.restrictions = null;
    StreamDetailType.prototype.settings = null;
    StreamDetailType.prototype.viewInfo = null;
    StreamDetailType.prototype.hashtags = $util.emptyArray;
    StreamDetailType.prototype.landscape = false;
    StreamDetailType.prototype.approved = false;
    StreamDetailType.prototype.moderationLevel = 0;
    StreamDetailType.prototype.showAsShutdownable = false;
    StreamDetailType.prototype.pttActive = false;
    StreamDetailType.prototype.recordedVideoViewInfo = null;
    StreamDetailType.prototype.streamerVector = $util.emptyArray;
    StreamDetailType.prototype.youtubePointsInStream = 0;
    StreamDetailType.prototype.rating = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    StreamDetailType.prototype.promo = null;
    StreamDetailType.prototype.historyId = 0;
    StreamDetailType.prototype.capabilities = $util.emptyArray;
    StreamDetailType.prototype.stateEntries = $util.emptyArray;
    StreamDetailType.prototype.moderationScore = 0;
    StreamDetailType.prototype.nsfw = false;

    StreamDetailType.create = function create(properties) {
        return new StreamDetailType(properties);
    };

    StreamDetailType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.stream != null && Object.hasOwnProperty.call(message, "stream"))
            $root.StreamType.encode(message.stream, writer.uint32(10).fork()).ldelim();
        if (message.anchor != null && Object.hasOwnProperty.call(message, "anchor"))
            $root.AccountType.encode(message.anchor, writer.uint32(18).fork()).ldelim();
        if (message.anchorPoints != null && Object.hasOwnProperty.call(message, "anchorPoints"))
            writer.uint32(29).sfixed32(message.anchorPoints);
        if (message.viewerCount != null && Object.hasOwnProperty.call(message, "viewerCount"))
            writer.uint32(37).sfixed32(message.viewerCount);
        if (message.likeCount != null && Object.hasOwnProperty.call(message, "likeCount"))
            writer.uint32(45).sfixed32(message.likeCount);
        if (message.postId != null && Object.hasOwnProperty.call(message, "postId"))
            writer.uint32(49).sfixed64(message.postId);
        if (message.isPublic != null && Object.hasOwnProperty.call(message, "isPublic"))
            writer.uint32(56).bool(message.isPublic);
        if (message.source != null && Object.hasOwnProperty.call(message, "source"))
            writer.uint32(66).string(message.source);
        if (message.totalPointsInStream != null && Object.hasOwnProperty.call(message, "totalPointsInStream"))
            writer.uint32(77).sfixed32(message.totalPointsInStream);
        if (message.uniqueViewerCount != null && Object.hasOwnProperty.call(message, "uniqueViewerCount"))
            writer.uint32(85).sfixed32(message.uniqueViewerCount);
        if (message.tags != null && message.tags.length)
            for (let i = 0; i < message.tags.length; ++i)
                writer.uint32(90).string(message.tags[i]);
        if (message.country != null && Object.hasOwnProperty.call(message, "country"))
            $root.CountryType.encode(message.country, writer.uint32(98).fork()).ldelim();
        if (message.featured != null && Object.hasOwnProperty.call(message, "featured"))
            writer.uint32(104).bool(message.featured);
        if (message.ticketPrice != null && Object.hasOwnProperty.call(message, "ticketPrice"))
            writer.uint32(117).sfixed32(message.ticketPrice);
        if (message.redirectStreamId != null && Object.hasOwnProperty.call(message, "redirectStreamId"))
            writer.uint32(122).string(message.redirectStreamId);
        if (message.relatedStreams != null && message.relatedStreams.length)
            for (let i = 0; i < message.relatedStreams.length; ++i)
                $root.RelatedStream.encode(message.relatedStreams[i], writer.uint32(130).fork()).ldelim();
        if (message.subscriberCount != null && Object.hasOwnProperty.call(message, "subscriberCount"))
            writer.uint32(141).sfixed32(message.subscriberCount);
        if (message.restrictions != null && Object.hasOwnProperty.call(message, "restrictions"))
            $root.StreamRestrictions.encode(message.restrictions, writer.uint32(146).fork()).ldelim();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.StreamSettings.encode(message.settings, writer.uint32(154).fork()).ldelim();
        if (message.viewInfo != null && Object.hasOwnProperty.call(message, "viewInfo"))
            $root.StreamViewInfo.encode(message.viewInfo, writer.uint32(162).fork()).ldelim();
        if (message.hashtags != null && message.hashtags.length)
            for (let i = 0; i < message.hashtags.length; ++i)
                writer.uint32(170).string(message.hashtags[i]);
        if (message.landscape != null && Object.hasOwnProperty.call(message, "landscape"))
            writer.uint32(176).bool(message.landscape);
        if (message.approved != null && Object.hasOwnProperty.call(message, "approved"))
            writer.uint32(184).bool(message.approved);
        if (message.moderationLevel != null && Object.hasOwnProperty.call(message, "moderationLevel"))
            writer.uint32(197).sfixed32(message.moderationLevel);
        if (message.showAsShutdownable != null && Object.hasOwnProperty.call(message, "showAsShutdownable"))
            writer.uint32(200).bool(message.showAsShutdownable);
        if (message.pttActive != null && Object.hasOwnProperty.call(message, "pttActive"))
            writer.uint32(208).bool(message.pttActive);
        if (message.recordedVideoViewInfo != null && Object.hasOwnProperty.call(message, "recordedVideoViewInfo"))
            $root.RecordedVideoViewInfo.encode(message.recordedVideoViewInfo, writer.uint32(218).fork()).ldelim();
        if (message.streamerVector != null && message.streamerVector.length)
            for (let i = 0; i < message.streamerVector.length; ++i)
                writer.uint32(225).double(message.streamerVector[i]);
        if (message.youtubePointsInStream != null && Object.hasOwnProperty.call(message, "youtubePointsInStream"))
            writer.uint32(237).sfixed32(message.youtubePointsInStream);
        if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
            writer.uint32(241).sfixed64(message.rating);
        if (message.promo != null && Object.hasOwnProperty.call(message, "promo"))
            $root.PromoType.encode(message.promo, writer.uint32(250).fork()).ldelim();
        if (message.historyId != null && Object.hasOwnProperty.call(message, "historyId"))
            writer.uint32(261).sfixed32(message.historyId);
        if (message.capabilities != null && message.capabilities.length)
            for (let i = 0; i < message.capabilities.length; ++i)
                writer.uint32(264).int32(message.capabilities[i]);
        if (message.stateEntries != null && message.stateEntries.length)
            for (let i = 0; i < message.stateEntries.length; ++i)
                $root.StreamDetailStateEntry.encode(message.stateEntries[i], writer.uint32(274).fork()).ldelim();
        if (message.moderationScore != null && Object.hasOwnProperty.call(message, "moderationScore"))
            writer.uint32(281).double(message.moderationScore);
        if (message.nsfw != null && Object.hasOwnProperty.call(message, "nsfw"))
            writer.uint32(288).bool(message.nsfw);
        return writer;
    };

    StreamDetailType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamDetailType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamDetailType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.stream = $root.StreamType.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.anchor = $root.AccountType.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.anchorPoints = reader.sfixed32();
                    break;
                }
            case 4: {
                    message.viewerCount = reader.sfixed32();
                    break;
                }
            case 5: {
                    message.likeCount = reader.sfixed32();
                    break;
                }
            case 6: {
                    message.postId = reader.sfixed64();
                    break;
                }
            case 7: {
                    message.isPublic = reader.bool();
                    break;
                }
            case 8: {
                    message.source = reader.string();
                    break;
                }
            case 9: {
                    message.totalPointsInStream = reader.sfixed32();
                    break;
                }
            case 10: {
                    message.uniqueViewerCount = reader.sfixed32();
                    break;
                }
            case 11: {
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                }
            case 12: {
                    message.country = $root.CountryType.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.featured = reader.bool();
                    break;
                }
            case 14: {
                    message.ticketPrice = reader.sfixed32();
                    break;
                }
            case 15: {
                    message.redirectStreamId = reader.string();
                    break;
                }
            case 16: {
                    if (!(message.relatedStreams && message.relatedStreams.length))
                        message.relatedStreams = [];
                    message.relatedStreams.push($root.RelatedStream.decode(reader, reader.uint32()));
                    break;
                }
            case 17: {
                    message.subscriberCount = reader.sfixed32();
                    break;
                }
            case 18: {
                    message.restrictions = $root.StreamRestrictions.decode(reader, reader.uint32());
                    break;
                }
            case 19: {
                    message.settings = $root.StreamSettings.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.viewInfo = $root.StreamViewInfo.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    if (!(message.hashtags && message.hashtags.length))
                        message.hashtags = [];
                    message.hashtags.push(reader.string());
                    break;
                }
            case 22: {
                    message.landscape = reader.bool();
                    break;
                }
            case 23: {
                    message.approved = reader.bool();
                    break;
                }
            case 24: {
                    message.moderationLevel = reader.sfixed32();
                    break;
                }
            case 25: {
                    message.showAsShutdownable = reader.bool();
                    break;
                }
            case 26: {
                    message.pttActive = reader.bool();
                    break;
                }
            case 27: {
                    message.recordedVideoViewInfo = $root.RecordedVideoViewInfo.decode(reader, reader.uint32());
                    break;
                }
            case 28: {
                    if (!(message.streamerVector && message.streamerVector.length))
                        message.streamerVector = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.streamerVector.push(reader.double());
                    } else
                        message.streamerVector.push(reader.double());
                    break;
                }
            case 29: {
                    message.youtubePointsInStream = reader.sfixed32();
                    break;
                }
            case 30: {
                    message.rating = reader.sfixed64();
                    break;
                }
            case 31: {
                    message.promo = $root.PromoType.decode(reader, reader.uint32());
                    break;
                }
            case 32: {
                    message.historyId = reader.sfixed32();
                    break;
                }
            case 33: {
                    if (!(message.capabilities && message.capabilities.length))
                        message.capabilities = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.capabilities.push(reader.int32());
                    } else
                        message.capabilities.push(reader.int32());
                    break;
                }
            case 34: {
                    if (!(message.stateEntries && message.stateEntries.length))
                        message.stateEntries = [];
                    message.stateEntries.push($root.StreamDetailStateEntry.decode(reader, reader.uint32()));
                    break;
                }
            case 35: {
                    message.moderationScore = reader.double();
                    break;
                }
            case 36: {
                    message.nsfw = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamDetailType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamDetailType.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamDetailType)
            return object;
        let message = new $root.StreamDetailType();
        if (object.stream != null) {
            if (typeof object.stream !== "object")
                throw TypeError(".StreamDetailType.stream: object expected");
            message.stream = $root.StreamType.fromObject(object.stream);
        }
        if (object.anchor != null) {
            if (typeof object.anchor !== "object")
                throw TypeError(".StreamDetailType.anchor: object expected");
            message.anchor = $root.AccountType.fromObject(object.anchor);
        }
        if (object.anchorPoints != null)
            message.anchorPoints = object.anchorPoints | 0;
        if (object.viewerCount != null)
            message.viewerCount = object.viewerCount | 0;
        if (object.likeCount != null)
            message.likeCount = object.likeCount | 0;
        if (object.postId != null)
            if ($util.Long)
                (message.postId = $util.Long.fromValue(object.postId)).unsigned = false;
            else if (typeof object.postId === "string")
                message.postId = parseInt(object.postId, 10);
            else if (typeof object.postId === "number")
                message.postId = object.postId;
            else if (typeof object.postId === "object")
                message.postId = new $util.LongBits(object.postId.low >>> 0, object.postId.high >>> 0).toNumber();
        if (object.isPublic != null)
            message.isPublic = Boolean(object.isPublic);
        if (object.source != null)
            message.source = String(object.source);
        if (object.totalPointsInStream != null)
            message.totalPointsInStream = object.totalPointsInStream | 0;
        if (object.uniqueViewerCount != null)
            message.uniqueViewerCount = object.uniqueViewerCount | 0;
        if (object.tags) {
            if (!Array.isArray(object.tags))
                throw TypeError(".StreamDetailType.tags: array expected");
            message.tags = [];
            for (let i = 0; i < object.tags.length; ++i)
                message.tags[i] = String(object.tags[i]);
        }
        if (object.country != null) {
            if (typeof object.country !== "object")
                throw TypeError(".StreamDetailType.country: object expected");
            message.country = $root.CountryType.fromObject(object.country);
        }
        if (object.featured != null)
            message.featured = Boolean(object.featured);
        if (object.ticketPrice != null)
            message.ticketPrice = object.ticketPrice | 0;
        if (object.redirectStreamId != null)
            message.redirectStreamId = String(object.redirectStreamId);
        if (object.relatedStreams) {
            if (!Array.isArray(object.relatedStreams))
                throw TypeError(".StreamDetailType.relatedStreams: array expected");
            message.relatedStreams = [];
            for (let i = 0; i < object.relatedStreams.length; ++i) {
                if (typeof object.relatedStreams[i] !== "object")
                    throw TypeError(".StreamDetailType.relatedStreams: object expected");
                message.relatedStreams[i] = $root.RelatedStream.fromObject(object.relatedStreams[i]);
            }
        }
        if (object.subscriberCount != null)
            message.subscriberCount = object.subscriberCount | 0;
        if (object.restrictions != null) {
            if (typeof object.restrictions !== "object")
                throw TypeError(".StreamDetailType.restrictions: object expected");
            message.restrictions = $root.StreamRestrictions.fromObject(object.restrictions);
        }
        if (object.settings != null) {
            if (typeof object.settings !== "object")
                throw TypeError(".StreamDetailType.settings: object expected");
            message.settings = $root.StreamSettings.fromObject(object.settings);
        }
        if (object.viewInfo != null) {
            if (typeof object.viewInfo !== "object")
                throw TypeError(".StreamDetailType.viewInfo: object expected");
            message.viewInfo = $root.StreamViewInfo.fromObject(object.viewInfo);
        }
        if (object.hashtags) {
            if (!Array.isArray(object.hashtags))
                throw TypeError(".StreamDetailType.hashtags: array expected");
            message.hashtags = [];
            for (let i = 0; i < object.hashtags.length; ++i)
                message.hashtags[i] = String(object.hashtags[i]);
        }
        if (object.landscape != null)
            message.landscape = Boolean(object.landscape);
        if (object.approved != null)
            message.approved = Boolean(object.approved);
        if (object.moderationLevel != null)
            message.moderationLevel = object.moderationLevel | 0;
        if (object.showAsShutdownable != null)
            message.showAsShutdownable = Boolean(object.showAsShutdownable);
        if (object.pttActive != null)
            message.pttActive = Boolean(object.pttActive);
        if (object.recordedVideoViewInfo != null) {
            if (typeof object.recordedVideoViewInfo !== "object")
                throw TypeError(".StreamDetailType.recordedVideoViewInfo: object expected");
            message.recordedVideoViewInfo = $root.RecordedVideoViewInfo.fromObject(object.recordedVideoViewInfo);
        }
        if (object.streamerVector) {
            if (!Array.isArray(object.streamerVector))
                throw TypeError(".StreamDetailType.streamerVector: array expected");
            message.streamerVector = [];
            for (let i = 0; i < object.streamerVector.length; ++i)
                message.streamerVector[i] = Number(object.streamerVector[i]);
        }
        if (object.youtubePointsInStream != null)
            message.youtubePointsInStream = object.youtubePointsInStream | 0;
        if (object.rating != null)
            if ($util.Long)
                (message.rating = $util.Long.fromValue(object.rating)).unsigned = false;
            else if (typeof object.rating === "string")
                message.rating = parseInt(object.rating, 10);
            else if (typeof object.rating === "number")
                message.rating = object.rating;
            else if (typeof object.rating === "object")
                message.rating = new $util.LongBits(object.rating.low >>> 0, object.rating.high >>> 0).toNumber();
        if (object.promo != null) {
            if (typeof object.promo !== "object")
                throw TypeError(".StreamDetailType.promo: object expected");
            message.promo = $root.PromoType.fromObject(object.promo);
        }
        if (object.historyId != null)
            message.historyId = object.historyId | 0;
        if (object.capabilities) {
            if (!Array.isArray(object.capabilities))
                throw TypeError(".StreamDetailType.capabilities: array expected");
            message.capabilities = [];
            for (let i = 0; i < object.capabilities.length; ++i)
                switch (object.capabilities[i]) {
                default:
                    if (typeof object.capabilities[i] === "number") {
                        message.capabilities[i] = object.capabilities[i];
                        break;
                    }
                case "UNKNOWN":
                case 0:
                    message.capabilities[i] = 0;
                    break;
                case "DATING_INVITE":
                case 1:
                    message.capabilities[i] = 1;
                    break;
                }
        }
        if (object.stateEntries) {
            if (!Array.isArray(object.stateEntries))
                throw TypeError(".StreamDetailType.stateEntries: array expected");
            message.stateEntries = [];
            for (let i = 0; i < object.stateEntries.length; ++i) {
                if (typeof object.stateEntries[i] !== "object")
                    throw TypeError(".StreamDetailType.stateEntries: object expected");
                message.stateEntries[i] = $root.StreamDetailStateEntry.fromObject(object.stateEntries[i]);
            }
        }
        if (object.moderationScore != null)
            message.moderationScore = Number(object.moderationScore);
        if (object.nsfw != null)
            message.nsfw = Boolean(object.nsfw);
        return message;
    };

    StreamDetailType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults) {
            object.tags = [];
            object.relatedStreams = [];
            object.hashtags = [];
            object.streamerVector = [];
            object.capabilities = [];
            object.stateEntries = [];
        }
        if (options.defaults) {
            object.stream = null;
            object.anchor = null;
            object.anchorPoints = 0;
            object.viewerCount = 0;
            object.likeCount = 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.postId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.postId = options.longs === String ? "0" : 0;
            object.isPublic = false;
            object.source = "";
            object.totalPointsInStream = 0;
            object.uniqueViewerCount = 0;
            object.country = null;
            object.featured = false;
            object.ticketPrice = 0;
            object.redirectStreamId = "";
            object.subscriberCount = 0;
            object.restrictions = null;
            object.settings = null;
            object.viewInfo = null;
            object.landscape = false;
            object.approved = false;
            object.moderationLevel = 0;
            object.showAsShutdownable = false;
            object.pttActive = false;
            object.recordedVideoViewInfo = null;
            object.youtubePointsInStream = 0;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.rating = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.rating = options.longs === String ? "0" : 0;
            object.promo = null;
            object.historyId = 0;
            object.moderationScore = 0;
            object.nsfw = false;
        }
        if (message.stream != null && message.hasOwnProperty("stream"))
            object.stream = $root.StreamType.toObject(message.stream, options);
        if (message.anchor != null && message.hasOwnProperty("anchor"))
            object.anchor = $root.AccountType.toObject(message.anchor, options);
        if (message.anchorPoints != null && message.hasOwnProperty("anchorPoints"))
            object.anchorPoints = message.anchorPoints;
        if (message.viewerCount != null && message.hasOwnProperty("viewerCount"))
            object.viewerCount = message.viewerCount;
        if (message.likeCount != null && message.hasOwnProperty("likeCount"))
            object.likeCount = message.likeCount;
        if (message.postId != null && message.hasOwnProperty("postId"))
            if (typeof message.postId === "number")
                object.postId = options.longs === String ? String(message.postId) : message.postId;
            else
                object.postId = options.longs === String ? $util.Long.prototype.toString.call(message.postId) : options.longs === Number ? new $util.LongBits(message.postId.low >>> 0, message.postId.high >>> 0).toNumber() : message.postId;
        if (message.isPublic != null && message.hasOwnProperty("isPublic"))
            object.isPublic = message.isPublic;
        if (message.source != null && message.hasOwnProperty("source"))
            object.source = message.source;
        if (message.totalPointsInStream != null && message.hasOwnProperty("totalPointsInStream"))
            object.totalPointsInStream = message.totalPointsInStream;
        if (message.uniqueViewerCount != null && message.hasOwnProperty("uniqueViewerCount"))
            object.uniqueViewerCount = message.uniqueViewerCount;
        if (message.tags && message.tags.length) {
            object.tags = [];
            for (let j = 0; j < message.tags.length; ++j)
                object.tags[j] = message.tags[j];
        }
        if (message.country != null && message.hasOwnProperty("country"))
            object.country = $root.CountryType.toObject(message.country, options);
        if (message.featured != null && message.hasOwnProperty("featured"))
            object.featured = message.featured;
        if (message.ticketPrice != null && message.hasOwnProperty("ticketPrice"))
            object.ticketPrice = message.ticketPrice;
        if (message.redirectStreamId != null && message.hasOwnProperty("redirectStreamId"))
            object.redirectStreamId = message.redirectStreamId;
        if (message.relatedStreams && message.relatedStreams.length) {
            object.relatedStreams = [];
            for (let j = 0; j < message.relatedStreams.length; ++j)
                object.relatedStreams[j] = $root.RelatedStream.toObject(message.relatedStreams[j], options);
        }
        if (message.subscriberCount != null && message.hasOwnProperty("subscriberCount"))
            object.subscriberCount = message.subscriberCount;
        if (message.restrictions != null && message.hasOwnProperty("restrictions"))
            object.restrictions = $root.StreamRestrictions.toObject(message.restrictions, options);
        if (message.settings != null && message.hasOwnProperty("settings"))
            object.settings = $root.StreamSettings.toObject(message.settings, options);
        if (message.viewInfo != null && message.hasOwnProperty("viewInfo"))
            object.viewInfo = $root.StreamViewInfo.toObject(message.viewInfo, options);
        if (message.hashtags && message.hashtags.length) {
            object.hashtags = [];
            for (let j = 0; j < message.hashtags.length; ++j)
                object.hashtags[j] = message.hashtags[j];
        }
        if (message.landscape != null && message.hasOwnProperty("landscape"))
            object.landscape = message.landscape;
        if (message.approved != null && message.hasOwnProperty("approved"))
            object.approved = message.approved;
        if (message.moderationLevel != null && message.hasOwnProperty("moderationLevel"))
            object.moderationLevel = message.moderationLevel;
        if (message.showAsShutdownable != null && message.hasOwnProperty("showAsShutdownable"))
            object.showAsShutdownable = message.showAsShutdownable;
        if (message.pttActive != null && message.hasOwnProperty("pttActive"))
            object.pttActive = message.pttActive;
        if (message.recordedVideoViewInfo != null && message.hasOwnProperty("recordedVideoViewInfo"))
            object.recordedVideoViewInfo = $root.RecordedVideoViewInfo.toObject(message.recordedVideoViewInfo, options);
        if (message.streamerVector && message.streamerVector.length) {
            object.streamerVector = [];
            for (let j = 0; j < message.streamerVector.length; ++j)
                object.streamerVector[j] = options.json && !isFinite(message.streamerVector[j]) ? String(message.streamerVector[j]) : message.streamerVector[j];
        }
        if (message.youtubePointsInStream != null && message.hasOwnProperty("youtubePointsInStream"))
            object.youtubePointsInStream = message.youtubePointsInStream;
        if (message.rating != null && message.hasOwnProperty("rating"))
            if (typeof message.rating === "number")
                object.rating = options.longs === String ? String(message.rating) : message.rating;
            else
                object.rating = options.longs === String ? $util.Long.prototype.toString.call(message.rating) : options.longs === Number ? new $util.LongBits(message.rating.low >>> 0, message.rating.high >>> 0).toNumber() : message.rating;
        if (message.promo != null && message.hasOwnProperty("promo"))
            object.promo = $root.PromoType.toObject(message.promo, options);
        if (message.historyId != null && message.hasOwnProperty("historyId"))
            object.historyId = message.historyId;
        if (message.capabilities && message.capabilities.length) {
            object.capabilities = [];
            for (let j = 0; j < message.capabilities.length; ++j)
                object.capabilities[j] = options.enums === String ? $root.StreamDetailType.StreamCapability[message.capabilities[j]] === undefined ? message.capabilities[j] : $root.StreamDetailType.StreamCapability[message.capabilities[j]] : message.capabilities[j];
        }
        if (message.stateEntries && message.stateEntries.length) {
            object.stateEntries = [];
            for (let j = 0; j < message.stateEntries.length; ++j)
                object.stateEntries[j] = $root.StreamDetailStateEntry.toObject(message.stateEntries[j], options);
        }
        if (message.moderationScore != null && message.hasOwnProperty("moderationScore"))
            object.moderationScore = options.json && !isFinite(message.moderationScore) ? String(message.moderationScore) : message.moderationScore;
        if (message.nsfw != null && message.hasOwnProperty("nsfw"))
            object.nsfw = message.nsfw;
        return object;
    };

    StreamDetailType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamDetailType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamDetailType";
    };

    StreamDetailType.StreamCapability = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "DATING_INVITE"] = 1;
        return values;
    })();

    return StreamDetailType;
})();

export const ViewerVector = $root.ViewerVector = (() => {

    function ViewerVector(properties) {
        this.viewerVector = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    ViewerVector.prototype.footprint = "";
    ViewerVector.prototype.timestamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    ViewerVector.prototype.version = "";
    ViewerVector.prototype.viewerVector = $util.emptyArray;

    ViewerVector.create = function create(properties) {
        return new ViewerVector(properties);
    };

    ViewerVector.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.footprint != null && Object.hasOwnProperty.call(message, "footprint"))
            writer.uint32(10).string(message.footprint);
        if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
            writer.uint32(17).sfixed64(message.timestamp);
        if (message.version != null && Object.hasOwnProperty.call(message, "version"))
            writer.uint32(26).string(message.version);
        if (message.viewerVector != null && message.viewerVector.length)
            for (let i = 0; i < message.viewerVector.length; ++i)
                writer.uint32(33).double(message.viewerVector[i]);
        return writer;
    };

    ViewerVector.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    ViewerVector.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ViewerVector();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.footprint = reader.string();
                    break;
                }
            case 2: {
                    message.timestamp = reader.sfixed64();
                    break;
                }
            case 3: {
                    message.version = reader.string();
                    break;
                }
            case 4: {
                    if (!(message.viewerVector && message.viewerVector.length))
                        message.viewerVector = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.viewerVector.push(reader.double());
                    } else
                        message.viewerVector.push(reader.double());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    ViewerVector.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    ViewerVector.fromObject = function fromObject(object) {
        if (object instanceof $root.ViewerVector)
            return object;
        let message = new $root.ViewerVector();
        if (object.footprint != null)
            message.footprint = String(object.footprint);
        if (object.timestamp != null)
            if ($util.Long)
                (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
            else if (typeof object.timestamp === "string")
                message.timestamp = parseInt(object.timestamp, 10);
            else if (typeof object.timestamp === "number")
                message.timestamp = object.timestamp;
            else if (typeof object.timestamp === "object")
                message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
        if (object.version != null)
            message.version = String(object.version);
        if (object.viewerVector) {
            if (!Array.isArray(object.viewerVector))
                throw TypeError(".ViewerVector.viewerVector: array expected");
            message.viewerVector = [];
            for (let i = 0; i < object.viewerVector.length; ++i)
                message.viewerVector[i] = Number(object.viewerVector[i]);
        }
        return message;
    };

    ViewerVector.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.viewerVector = [];
        if (options.defaults) {
            object.footprint = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.timestamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.timestamp = options.longs === String ? "0" : 0;
            object.version = "";
        }
        if (message.footprint != null && message.hasOwnProperty("footprint"))
            object.footprint = message.footprint;
        if (message.timestamp != null && message.hasOwnProperty("timestamp"))
            if (typeof message.timestamp === "number")
                object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
            else
                object.timestamp = options.longs === String ? $util.Long.prototype.toString.call(message.timestamp) : options.longs === Number ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber() : message.timestamp;
        if (message.version != null && message.hasOwnProperty("version"))
            object.version = message.version;
        if (message.viewerVector && message.viewerVector.length) {
            object.viewerVector = [];
            for (let j = 0; j < message.viewerVector.length; ++j)
                object.viewerVector[j] = options.json && !isFinite(message.viewerVector[j]) ? String(message.viewerVector[j]) : message.viewerVector[j];
        }
        return object;
    };

    ViewerVector.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    ViewerVector.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ViewerVector";
    };

    return ViewerVector;
})();

export const StreamType = $root.StreamType = (() => {

    function StreamType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamType.prototype.id = "";
    StreamType.prototype.encryptedAccountId = "";
    StreamType.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    StreamType.prototype.title = "";
    StreamType.prototype.thumbnail = "";
    StreamType.prototype.thumbnailWidth = 0;
    StreamType.prototype.thumbnailHeight = 0;
    StreamType.prototype.status = 1;
    StreamType.prototype.streamKind = 1;
    StreamType.prototype.liveListUrl = "";
    StreamType.prototype.completeListUrl = "";
    StreamType.prototype.previewListUrl = "";
    StreamType.prototype.masterListUrl = "";
    StreamType.prototype.streamProtocol = 1;
    StreamType.prototype.egressUrl = "";
    StreamType.prototype.landscape = false;

    StreamType.create = function create(properties) {
        return new StreamType(properties);
    };

    StreamType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.id);
        writer.uint32(18).string(message.encryptedAccountId);
        writer.uint32(25).sfixed64(message.duration);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(34).string(message.title);
        if (message.thumbnail != null && Object.hasOwnProperty.call(message, "thumbnail"))
            writer.uint32(42).string(message.thumbnail);
        if (message.thumbnailWidth != null && Object.hasOwnProperty.call(message, "thumbnailWidth"))
            writer.uint32(53).sfixed32(message.thumbnailWidth);
        if (message.thumbnailHeight != null && Object.hasOwnProperty.call(message, "thumbnailHeight"))
            writer.uint32(61).sfixed32(message.thumbnailHeight);
        if (message.status != null && Object.hasOwnProperty.call(message, "status"))
            writer.uint32(64).int32(message.status);
        if (message.streamKind != null && Object.hasOwnProperty.call(message, "streamKind"))
            writer.uint32(72).int32(message.streamKind);
        if (message.liveListUrl != null && Object.hasOwnProperty.call(message, "liveListUrl"))
            writer.uint32(82).string(message.liveListUrl);
        if (message.completeListUrl != null && Object.hasOwnProperty.call(message, "completeListUrl"))
            writer.uint32(90).string(message.completeListUrl);
        if (message.previewListUrl != null && Object.hasOwnProperty.call(message, "previewListUrl"))
            writer.uint32(98).string(message.previewListUrl);
        if (message.masterListUrl != null && Object.hasOwnProperty.call(message, "masterListUrl"))
            writer.uint32(106).string(message.masterListUrl);
        if (message.streamProtocol != null && Object.hasOwnProperty.call(message, "streamProtocol"))
            writer.uint32(112).int32(message.streamProtocol);
        if (message.egressUrl != null && Object.hasOwnProperty.call(message, "egressUrl"))
            writer.uint32(122).string(message.egressUrl);
        if (message.landscape != null && Object.hasOwnProperty.call(message, "landscape"))
            writer.uint32(128).bool(message.landscape);
        return writer;
    };

    StreamType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.id = reader.string();
                    break;
                }
            case 2: {
                    message.encryptedAccountId = reader.string();
                    break;
                }
            case 3: {
                    message.duration = reader.sfixed64();
                    break;
                }
            case 4: {
                    message.title = reader.string();
                    break;
                }
            case 5: {
                    message.thumbnail = reader.string();
                    break;
                }
            case 6: {
                    message.thumbnailWidth = reader.sfixed32();
                    break;
                }
            case 7: {
                    message.thumbnailHeight = reader.sfixed32();
                    break;
                }
            case 8: {
                    message.status = reader.int32();
                    break;
                }
            case 9: {
                    message.streamKind = reader.int32();
                    break;
                }
            case 10: {
                    message.liveListUrl = reader.string();
                    break;
                }
            case 11: {
                    message.completeListUrl = reader.string();
                    break;
                }
            case 12: {
                    message.previewListUrl = reader.string();
                    break;
                }
            case 13: {
                    message.masterListUrl = reader.string();
                    break;
                }
            case 14: {
                    message.streamProtocol = reader.int32();
                    break;
                }
            case 15: {
                    message.egressUrl = reader.string();
                    break;
                }
            case 16: {
                    message.landscape = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("id"))
            throw $util.ProtocolError("missing required 'id'", { instance: message });
        if (!message.hasOwnProperty("encryptedAccountId"))
            throw $util.ProtocolError("missing required 'encryptedAccountId'", { instance: message });
        if (!message.hasOwnProperty("duration"))
            throw $util.ProtocolError("missing required 'duration'", { instance: message });
        return message;
    };

    StreamType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamType.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamType)
            return object;
        let message = new $root.StreamType();
        if (object.id != null)
            message.id = String(object.id);
        if (object.encryptedAccountId != null)
            message.encryptedAccountId = String(object.encryptedAccountId);
        if (object.duration != null)
            if ($util.Long)
                (message.duration = $util.Long.fromValue(object.duration)).unsigned = false;
            else if (typeof object.duration === "string")
                message.duration = parseInt(object.duration, 10);
            else if (typeof object.duration === "number")
                message.duration = object.duration;
            else if (typeof object.duration === "object")
                message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber();
        if (object.title != null)
            message.title = String(object.title);
        if (object.thumbnail != null)
            message.thumbnail = String(object.thumbnail);
        if (object.thumbnailWidth != null)
            message.thumbnailWidth = object.thumbnailWidth | 0;
        if (object.thumbnailHeight != null)
            message.thumbnailHeight = object.thumbnailHeight | 0;
        switch (object.status) {
        default:
            if (typeof object.status === "number") {
                message.status = object.status;
                break;
            }
            break;
        case "INITED":
        case 1:
            message.status = 1;
            break;
        case "LIVING":
        case 2:
            message.status = 2;
            break;
        case "TERMINATED":
        case 3:
            message.status = 3;
            break;
        case "SUSPENDED":
        case 4:
            message.status = 4;
            break;
        case "EXPIRED":
        case 5:
            message.status = 5;
            break;
        case "UNKNOWN":
        case 6:
            message.status = 6;
            break;
        }
        switch (object.streamKind) {
        default:
            if (typeof object.streamKind === "number") {
                message.streamKind = object.streamKind;
                break;
            }
            break;
        case "CHAT":
        case 1:
            message.streamKind = 1;
            break;
        case "PUBLIC":
        case 2:
            message.streamKind = 2;
            break;
        case "PRIVATE":
        case 3:
            message.streamKind = 3;
            break;
        case "TICKET_PRIVATE":
        case 4:
            message.streamKind = 4;
            break;
        case "ONBOARDING":
        case 5:
            message.streamKind = 5;
            break;
        case "DATING":
        case 6:
            message.streamKind = 6;
            break;
        }
        if (object.liveListUrl != null)
            message.liveListUrl = String(object.liveListUrl);
        if (object.completeListUrl != null)
            message.completeListUrl = String(object.completeListUrl);
        if (object.previewListUrl != null)
            message.previewListUrl = String(object.previewListUrl);
        if (object.masterListUrl != null)
            message.masterListUrl = String(object.masterListUrl);
        switch (object.streamProtocol) {
        default:
            if (typeof object.streamProtocol === "number") {
                message.streamProtocol = object.streamProtocol;
                break;
            }
            break;
        case "RTMP":
        case 1:
            message.streamProtocol = 1;
            break;
        case "SRT":
        case 2:
            message.streamProtocol = 2;
            break;
        }
        if (object.egressUrl != null)
            message.egressUrl = String(object.egressUrl);
        if (object.landscape != null)
            message.landscape = Boolean(object.landscape);
        return message;
    };

    StreamType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.id = "";
            object.encryptedAccountId = "";
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.duration = options.longs === String ? "0" : 0;
            object.title = "";
            object.thumbnail = "";
            object.thumbnailWidth = 0;
            object.thumbnailHeight = 0;
            object.status = options.enums === String ? "INITED" : 1;
            object.streamKind = options.enums === String ? "CHAT" : 1;
            object.liveListUrl = "";
            object.completeListUrl = "";
            object.previewListUrl = "";
            object.masterListUrl = "";
            object.streamProtocol = options.enums === String ? "RTMP" : 1;
            object.egressUrl = "";
            object.landscape = false;
        }
        if (message.id != null && message.hasOwnProperty("id"))
            object.id = message.id;
        if (message.encryptedAccountId != null && message.hasOwnProperty("encryptedAccountId"))
            object.encryptedAccountId = message.encryptedAccountId;
        if (message.duration != null && message.hasOwnProperty("duration"))
            if (typeof message.duration === "number")
                object.duration = options.longs === String ? String(message.duration) : message.duration;
            else
                object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber() : message.duration;
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
            object.thumbnail = message.thumbnail;
        if (message.thumbnailWidth != null && message.hasOwnProperty("thumbnailWidth"))
            object.thumbnailWidth = message.thumbnailWidth;
        if (message.thumbnailHeight != null && message.hasOwnProperty("thumbnailHeight"))
            object.thumbnailHeight = message.thumbnailHeight;
        if (message.status != null && message.hasOwnProperty("status"))
            object.status = options.enums === String ? $root.StreamStatus[message.status] === undefined ? message.status : $root.StreamStatus[message.status] : message.status;
        if (message.streamKind != null && message.hasOwnProperty("streamKind"))
            object.streamKind = options.enums === String ? $root.StreamKind[message.streamKind] === undefined ? message.streamKind : $root.StreamKind[message.streamKind] : message.streamKind;
        if (message.liveListUrl != null && message.hasOwnProperty("liveListUrl"))
            object.liveListUrl = message.liveListUrl;
        if (message.completeListUrl != null && message.hasOwnProperty("completeListUrl"))
            object.completeListUrl = message.completeListUrl;
        if (message.previewListUrl != null && message.hasOwnProperty("previewListUrl"))
            object.previewListUrl = message.previewListUrl;
        if (message.masterListUrl != null && message.hasOwnProperty("masterListUrl"))
            object.masterListUrl = message.masterListUrl;
        if (message.streamProtocol != null && message.hasOwnProperty("streamProtocol"))
            object.streamProtocol = options.enums === String ? $root.StreamProtocol[message.streamProtocol] === undefined ? message.streamProtocol : $root.StreamProtocol[message.streamProtocol] : message.streamProtocol;
        if (message.egressUrl != null && message.hasOwnProperty("egressUrl"))
            object.egressUrl = message.egressUrl;
        if (message.landscape != null && message.hasOwnProperty("landscape"))
            object.landscape = message.landscape;
        return object;
    };

    StreamType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamType";
    };

    return StreamType;
})();

export const AccountType = $root.AccountType = (() => {

    function AccountType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    AccountType.prototype.encryptedAccountId = "";
    AccountType.prototype.firstName = "";
    AccountType.prototype.lastName = "";
    AccountType.prototype.profilePictureUrl = "";
    AccountType.prototype.gender = 1;
    AccountType.prototype.profileThumbnailUrl = "";
    AccountType.prototype.isTop = false;
    AccountType.prototype.subscriptionLevel = 0;
    AccountType.prototype.guest = false;
    AccountType.prototype.verified = false;
    AccountType.prototype.vipConfigId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    AccountType.prototype.isIncognito = false;

    AccountType.create = function create(properties) {
        return new AccountType(properties);
    };

    AccountType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.encryptedAccountId);
        if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
            writer.uint32(18).string(message.firstName);
        if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
            writer.uint32(26).string(message.lastName);
        if (message.profilePictureUrl != null && Object.hasOwnProperty.call(message, "profilePictureUrl"))
            writer.uint32(34).string(message.profilePictureUrl);
        if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
            writer.uint32(40).int32(message.gender);
        if (message.profileThumbnailUrl != null && Object.hasOwnProperty.call(message, "profileThumbnailUrl"))
            writer.uint32(50).string(message.profileThumbnailUrl);
        if (message.isTop != null && Object.hasOwnProperty.call(message, "isTop"))
            writer.uint32(56).bool(message.isTop);
        if (message.subscriptionLevel != null && Object.hasOwnProperty.call(message, "subscriptionLevel"))
            writer.uint32(69).sfixed32(message.subscriptionLevel);
        if (message.guest != null && Object.hasOwnProperty.call(message, "guest"))
            writer.uint32(72).bool(message.guest);
        if (message.verified != null && Object.hasOwnProperty.call(message, "verified"))
            writer.uint32(80).bool(message.verified);
        if (message.vipConfigId != null && Object.hasOwnProperty.call(message, "vipConfigId"))
            writer.uint32(89).sfixed64(message.vipConfigId);
        if (message.isIncognito != null && Object.hasOwnProperty.call(message, "isIncognito"))
            writer.uint32(96).bool(message.isIncognito);
        return writer;
    };

    AccountType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    AccountType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AccountType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.encryptedAccountId = reader.string();
                    break;
                }
            case 2: {
                    message.firstName = reader.string();
                    break;
                }
            case 3: {
                    message.lastName = reader.string();
                    break;
                }
            case 4: {
                    message.profilePictureUrl = reader.string();
                    break;
                }
            case 5: {
                    message.gender = reader.int32();
                    break;
                }
            case 6: {
                    message.profileThumbnailUrl = reader.string();
                    break;
                }
            case 7: {
                    message.isTop = reader.bool();
                    break;
                }
            case 8: {
                    message.subscriptionLevel = reader.sfixed32();
                    break;
                }
            case 9: {
                    message.guest = reader.bool();
                    break;
                }
            case 10: {
                    message.verified = reader.bool();
                    break;
                }
            case 11: {
                    message.vipConfigId = reader.sfixed64();
                    break;
                }
            case 12: {
                    message.isIncognito = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("encryptedAccountId"))
            throw $util.ProtocolError("missing required 'encryptedAccountId'", { instance: message });
        return message;
    };

    AccountType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    AccountType.fromObject = function fromObject(object) {
        if (object instanceof $root.AccountType)
            return object;
        let message = new $root.AccountType();
        if (object.encryptedAccountId != null)
            message.encryptedAccountId = String(object.encryptedAccountId);
        if (object.firstName != null)
            message.firstName = String(object.firstName);
        if (object.lastName != null)
            message.lastName = String(object.lastName);
        if (object.profilePictureUrl != null)
            message.profilePictureUrl = String(object.profilePictureUrl);
        switch (object.gender) {
        default:
            if (typeof object.gender === "number") {
                message.gender = object.gender;
                break;
            }
            break;
        case "NONE":
        case 1:
            message.gender = 1;
            break;
        case "MALE":
        case 2:
            message.gender = 2;
            break;
        case "FEMALE":
        case 3:
            message.gender = 3;
            break;
        case "BOTH":
        case 4:
            message.gender = 4;
            break;
        }
        if (object.profileThumbnailUrl != null)
            message.profileThumbnailUrl = String(object.profileThumbnailUrl);
        if (object.isTop != null)
            message.isTop = Boolean(object.isTop);
        if (object.subscriptionLevel != null)
            message.subscriptionLevel = object.subscriptionLevel | 0;
        if (object.guest != null)
            message.guest = Boolean(object.guest);
        if (object.verified != null)
            message.verified = Boolean(object.verified);
        if (object.vipConfigId != null)
            if ($util.Long)
                (message.vipConfigId = $util.Long.fromValue(object.vipConfigId)).unsigned = false;
            else if (typeof object.vipConfigId === "string")
                message.vipConfigId = parseInt(object.vipConfigId, 10);
            else if (typeof object.vipConfigId === "number")
                message.vipConfigId = object.vipConfigId;
            else if (typeof object.vipConfigId === "object")
                message.vipConfigId = new $util.LongBits(object.vipConfigId.low >>> 0, object.vipConfigId.high >>> 0).toNumber();
        if (object.isIncognito != null)
            message.isIncognito = Boolean(object.isIncognito);
        return message;
    };

    AccountType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.encryptedAccountId = "";
            object.firstName = "";
            object.lastName = "";
            object.profilePictureUrl = "";
            object.gender = options.enums === String ? "NONE" : 1;
            object.profileThumbnailUrl = "";
            object.isTop = false;
            object.subscriptionLevel = 0;
            object.guest = false;
            object.verified = false;
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.vipConfigId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.vipConfigId = options.longs === String ? "0" : 0;
            object.isIncognito = false;
        }
        if (message.encryptedAccountId != null && message.hasOwnProperty("encryptedAccountId"))
            object.encryptedAccountId = message.encryptedAccountId;
        if (message.firstName != null && message.hasOwnProperty("firstName"))
            object.firstName = message.firstName;
        if (message.lastName != null && message.hasOwnProperty("lastName"))
            object.lastName = message.lastName;
        if (message.profilePictureUrl != null && message.hasOwnProperty("profilePictureUrl"))
            object.profilePictureUrl = message.profilePictureUrl;
        if (message.gender != null && message.hasOwnProperty("gender"))
            object.gender = options.enums === String ? $root.Gender[message.gender] === undefined ? message.gender : $root.Gender[message.gender] : message.gender;
        if (message.profileThumbnailUrl != null && message.hasOwnProperty("profileThumbnailUrl"))
            object.profileThumbnailUrl = message.profileThumbnailUrl;
        if (message.isTop != null && message.hasOwnProperty("isTop"))
            object.isTop = message.isTop;
        if (message.subscriptionLevel != null && message.hasOwnProperty("subscriptionLevel"))
            object.subscriptionLevel = message.subscriptionLevel;
        if (message.guest != null && message.hasOwnProperty("guest"))
            object.guest = message.guest;
        if (message.verified != null && message.hasOwnProperty("verified"))
            object.verified = message.verified;
        if (message.vipConfigId != null && message.hasOwnProperty("vipConfigId"))
            if (typeof message.vipConfigId === "number")
                object.vipConfigId = options.longs === String ? String(message.vipConfigId) : message.vipConfigId;
            else
                object.vipConfigId = options.longs === String ? $util.Long.prototype.toString.call(message.vipConfigId) : options.longs === Number ? new $util.LongBits(message.vipConfigId.low >>> 0, message.vipConfigId.high >>> 0).toNumber() : message.vipConfigId;
        if (message.isIncognito != null && message.hasOwnProperty("isIncognito"))
            object.isIncognito = message.isIncognito;
        return object;
    };

    AccountType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    AccountType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AccountType";
    };

    return AccountType;
})();

export const CountryType = $root.CountryType = (() => {

    function CountryType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    CountryType.prototype.iso2 = "";
    CountryType.prototype.country = "";
    CountryType.prototype.icon = "";

    CountryType.create = function create(properties) {
        return new CountryType(properties);
    };

    CountryType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.iso2);
        writer.uint32(18).string(message.country);
        writer.uint32(26).string(message.icon);
        return writer;
    };

    CountryType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    CountryType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CountryType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.iso2 = reader.string();
                    break;
                }
            case 2: {
                    message.country = reader.string();
                    break;
                }
            case 3: {
                    message.icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("iso2"))
            throw $util.ProtocolError("missing required 'iso2'", { instance: message });
        if (!message.hasOwnProperty("country"))
            throw $util.ProtocolError("missing required 'country'", { instance: message });
        if (!message.hasOwnProperty("icon"))
            throw $util.ProtocolError("missing required 'icon'", { instance: message });
        return message;
    };

    CountryType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    CountryType.fromObject = function fromObject(object) {
        if (object instanceof $root.CountryType)
            return object;
        let message = new $root.CountryType();
        if (object.iso2 != null)
            message.iso2 = String(object.iso2);
        if (object.country != null)
            message.country = String(object.country);
        if (object.icon != null)
            message.icon = String(object.icon);
        return message;
    };

    CountryType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.iso2 = "";
            object.country = "";
            object.icon = "";
        }
        if (message.iso2 != null && message.hasOwnProperty("iso2"))
            object.iso2 = message.iso2;
        if (message.country != null && message.hasOwnProperty("country"))
            object.country = message.country;
        if (message.icon != null && message.hasOwnProperty("icon"))
            object.icon = message.icon;
        return object;
    };

    CountryType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    CountryType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CountryType";
    };

    return CountryType;
})();

export const RelatedStream = $root.RelatedStream = (() => {

    function RelatedStream(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    RelatedStream.prototype.thumbnail = "";

    RelatedStream.create = function create(properties) {
        return new RelatedStream(properties);
    };

    RelatedStream.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.thumbnail);
        return writer;
    };

    RelatedStream.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    RelatedStream.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RelatedStream();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.thumbnail = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("thumbnail"))
            throw $util.ProtocolError("missing required 'thumbnail'", { instance: message });
        return message;
    };

    RelatedStream.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    RelatedStream.fromObject = function fromObject(object) {
        if (object instanceof $root.RelatedStream)
            return object;
        let message = new $root.RelatedStream();
        if (object.thumbnail != null)
            message.thumbnail = String(object.thumbnail);
        return message;
    };

    RelatedStream.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.thumbnail = "";
        if (message.thumbnail != null && message.hasOwnProperty("thumbnail"))
            object.thumbnail = message.thumbnail;
        return object;
    };

    RelatedStream.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    RelatedStream.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RelatedStream";
    };

    return RelatedStream;
})();

export const StreamRestrictions = $root.StreamRestrictions = (() => {

    function StreamRestrictions(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamRestrictions.prototype.init = null;
    StreamRestrictions.prototype.remain = null;

    StreamRestrictions.create = function create(properties) {
        return new StreamRestrictions(properties);
    };

    StreamRestrictions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.init != null && Object.hasOwnProperty.call(message, "init"))
            $root.StreamInitRestriction.encode(message.init, writer.uint32(10).fork()).ldelim();
        if (message.remain != null && Object.hasOwnProperty.call(message, "remain"))
            $root.StreamInitRestriction.encode(message.remain, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StreamRestrictions.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamRestrictions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamRestrictions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.init = $root.StreamInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.remain = $root.StreamInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamRestrictions.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamRestrictions.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamRestrictions)
            return object;
        let message = new $root.StreamRestrictions();
        if (object.init != null) {
            if (typeof object.init !== "object")
                throw TypeError(".StreamRestrictions.init: object expected");
            message.init = $root.StreamInitRestriction.fromObject(object.init);
        }
        if (object.remain != null) {
            if (typeof object.remain !== "object")
                throw TypeError(".StreamRestrictions.remain: object expected");
            message.remain = $root.StreamInitRestriction.fromObject(object.remain);
        }
        return message;
    };

    StreamRestrictions.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.init = null;
            object.remain = null;
        }
        if (message.init != null && message.hasOwnProperty("init"))
            object.init = $root.StreamInitRestriction.toObject(message.init, options);
        if (message.remain != null && message.hasOwnProperty("remain"))
            object.remain = $root.StreamInitRestriction.toObject(message.remain, options);
        return object;
    };

    StreamRestrictions.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamRestrictions.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamRestrictions";
    };

    return StreamRestrictions;
})();

export const PromoType = $root.PromoType = (() => {

    function PromoType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    PromoType.prototype.title = "";
    PromoType.prototype.pictureUrl = "";
    PromoType.prototype.promotionId = "";
    PromoType.prototype.deeplink = "";

    PromoType.create = function create(properties) {
        return new PromoType(properties);
    };

    PromoType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
            writer.uint32(10).string(message.title);
        if (message.pictureUrl != null && Object.hasOwnProperty.call(message, "pictureUrl"))
            writer.uint32(18).string(message.pictureUrl);
        if (message.promotionId != null && Object.hasOwnProperty.call(message, "promotionId"))
            writer.uint32(26).string(message.promotionId);
        if (message.deeplink != null && Object.hasOwnProperty.call(message, "deeplink"))
            writer.uint32(34).string(message.deeplink);
        return writer;
    };

    PromoType.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    PromoType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PromoType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.title = reader.string();
                    break;
                }
            case 2: {
                    message.pictureUrl = reader.string();
                    break;
                }
            case 3: {
                    message.promotionId = reader.string();
                    break;
                }
            case 4: {
                    message.deeplink = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    PromoType.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    PromoType.fromObject = function fromObject(object) {
        if (object instanceof $root.PromoType)
            return object;
        let message = new $root.PromoType();
        if (object.title != null)
            message.title = String(object.title);
        if (object.pictureUrl != null)
            message.pictureUrl = String(object.pictureUrl);
        if (object.promotionId != null)
            message.promotionId = String(object.promotionId);
        if (object.deeplink != null)
            message.deeplink = String(object.deeplink);
        return message;
    };

    PromoType.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.title = "";
            object.pictureUrl = "";
            object.promotionId = "";
            object.deeplink = "";
        }
        if (message.title != null && message.hasOwnProperty("title"))
            object.title = message.title;
        if (message.pictureUrl != null && message.hasOwnProperty("pictureUrl"))
            object.pictureUrl = message.pictureUrl;
        if (message.promotionId != null && message.hasOwnProperty("promotionId"))
            object.promotionId = message.promotionId;
        if (message.deeplink != null && message.hasOwnProperty("deeplink"))
            object.deeplink = message.deeplink;
        return object;
    };

    PromoType.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    PromoType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/PromoType";
    };

    return PromoType;
})();

export const StreamDetailStateEntry = $root.StreamDetailStateEntry = (() => {

    function StreamDetailStateEntry(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamDetailStateEntry.prototype.stateName = "";
    StreamDetailStateEntry.prototype.serializedState = $util.newBuffer([]);

    StreamDetailStateEntry.create = function create(properties) {
        return new StreamDetailStateEntry(properties);
    };

    StreamDetailStateEntry.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.stateName != null && Object.hasOwnProperty.call(message, "stateName"))
            writer.uint32(10).string(message.stateName);
        if (message.serializedState != null && Object.hasOwnProperty.call(message, "serializedState"))
            writer.uint32(18).bytes(message.serializedState);
        return writer;
    };

    StreamDetailStateEntry.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamDetailStateEntry.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamDetailStateEntry();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.stateName = reader.string();
                    break;
                }
            case 2: {
                    message.serializedState = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamDetailStateEntry.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamDetailStateEntry.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamDetailStateEntry)
            return object;
        let message = new $root.StreamDetailStateEntry();
        if (object.stateName != null)
            message.stateName = String(object.stateName);
        if (object.serializedState != null)
            if (typeof object.serializedState === "string")
                $util.base64.decode(object.serializedState, message.serializedState = $util.newBuffer($util.base64.length(object.serializedState)), 0);
            else if (object.serializedState.length >= 0)
                message.serializedState = object.serializedState;
        return message;
    };

    StreamDetailStateEntry.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.stateName = "";
            if (options.bytes === String)
                object.serializedState = "";
            else {
                object.serializedState = [];
                if (options.bytes !== Array)
                    object.serializedState = $util.newBuffer(object.serializedState);
            }
        }
        if (message.stateName != null && message.hasOwnProperty("stateName"))
            object.stateName = message.stateName;
        if (message.serializedState != null && message.hasOwnProperty("serializedState"))
            object.serializedState = options.bytes === String ? $util.base64.encode(message.serializedState, 0, message.serializedState.length) : options.bytes === Array ? Array.prototype.slice.call(message.serializedState) : message.serializedState;
        return object;
    };

    StreamDetailStateEntry.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamDetailStateEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamDetailStateEntry";
    };

    return StreamDetailStateEntry;
})();

export const StreamStatus = $root.StreamStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "INITED"] = 1;
    values[valuesById[2] = "LIVING"] = 2;
    values[valuesById[3] = "TERMINATED"] = 3;
    values[valuesById[4] = "SUSPENDED"] = 4;
    values[valuesById[5] = "EXPIRED"] = 5;
    values[valuesById[6] = "UNKNOWN"] = 6;
    return values;
})();

export const StreamKind = $root.StreamKind = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "CHAT"] = 1;
    values[valuesById[2] = "PUBLIC"] = 2;
    values[valuesById[3] = "PRIVATE"] = 3;
    values[valuesById[4] = "TICKET_PRIVATE"] = 4;
    values[valuesById[5] = "ONBOARDING"] = 5;
    values[valuesById[6] = "DATING"] = 6;
    return values;
})();

export const StreamProtocol = $root.StreamProtocol = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "RTMP"] = 1;
    values[valuesById[2] = "SRT"] = 2;
    return values;
})();

export const Gender = $root.Gender = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "NONE"] = 1;
    values[valuesById[2] = "MALE"] = 2;
    values[valuesById[3] = "FEMALE"] = 3;
    values[valuesById[4] = "BOTH"] = 4;
    return values;
})();

export const StreamSettings = $root.StreamSettings = (() => {

    function StreamSettings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamSettings.prototype.videoEnabled = false;
    StreamSettings.prototype.audioEnabled = false;
    StreamSettings.prototype.incognitoEnabled = false;
    StreamSettings.prototype.muteGiftSound = false;
    StreamSettings.prototype.liveChatForFollowersOnly = false;
    StreamSettings.prototype.audioRoom = false;

    StreamSettings.create = function create(properties) {
        return new StreamSettings(properties);
    };

    StreamSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).bool(message.videoEnabled);
        writer.uint32(16).bool(message.audioEnabled);
        if (message.incognitoEnabled != null && Object.hasOwnProperty.call(message, "incognitoEnabled"))
            writer.uint32(24).bool(message.incognitoEnabled);
        if (message.muteGiftSound != null && Object.hasOwnProperty.call(message, "muteGiftSound"))
            writer.uint32(32).bool(message.muteGiftSound);
        if (message.liveChatForFollowersOnly != null && Object.hasOwnProperty.call(message, "liveChatForFollowersOnly"))
            writer.uint32(40).bool(message.liveChatForFollowersOnly);
        if (message.audioRoom != null && Object.hasOwnProperty.call(message, "audioRoom"))
            writer.uint32(48).bool(message.audioRoom);
        return writer;
    };

    StreamSettings.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.videoEnabled = reader.bool();
                    break;
                }
            case 2: {
                    message.audioEnabled = reader.bool();
                    break;
                }
            case 3: {
                    message.incognitoEnabled = reader.bool();
                    break;
                }
            case 4: {
                    message.muteGiftSound = reader.bool();
                    break;
                }
            case 5: {
                    message.liveChatForFollowersOnly = reader.bool();
                    break;
                }
            case 6: {
                    message.audioRoom = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("videoEnabled"))
            throw $util.ProtocolError("missing required 'videoEnabled'", { instance: message });
        if (!message.hasOwnProperty("audioEnabled"))
            throw $util.ProtocolError("missing required 'audioEnabled'", { instance: message });
        return message;
    };

    StreamSettings.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamSettings.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamSettings)
            return object;
        let message = new $root.StreamSettings();
        if (object.videoEnabled != null)
            message.videoEnabled = Boolean(object.videoEnabled);
        if (object.audioEnabled != null)
            message.audioEnabled = Boolean(object.audioEnabled);
        if (object.incognitoEnabled != null)
            message.incognitoEnabled = Boolean(object.incognitoEnabled);
        if (object.muteGiftSound != null)
            message.muteGiftSound = Boolean(object.muteGiftSound);
        if (object.liveChatForFollowersOnly != null)
            message.liveChatForFollowersOnly = Boolean(object.liveChatForFollowersOnly);
        if (object.audioRoom != null)
            message.audioRoom = Boolean(object.audioRoom);
        return message;
    };

    StreamSettings.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.videoEnabled = false;
            object.audioEnabled = false;
            object.incognitoEnabled = false;
            object.muteGiftSound = false;
            object.liveChatForFollowersOnly = false;
            object.audioRoom = false;
        }
        if (message.videoEnabled != null && message.hasOwnProperty("videoEnabled"))
            object.videoEnabled = message.videoEnabled;
        if (message.audioEnabled != null && message.hasOwnProperty("audioEnabled"))
            object.audioEnabled = message.audioEnabled;
        if (message.incognitoEnabled != null && message.hasOwnProperty("incognitoEnabled"))
            object.incognitoEnabled = message.incognitoEnabled;
        if (message.muteGiftSound != null && message.hasOwnProperty("muteGiftSound"))
            object.muteGiftSound = message.muteGiftSound;
        if (message.liveChatForFollowersOnly != null && message.hasOwnProperty("liveChatForFollowersOnly"))
            object.liveChatForFollowersOnly = message.liveChatForFollowersOnly;
        if (message.audioRoom != null && message.hasOwnProperty("audioRoom"))
            object.audioRoom = message.audioRoom;
        return object;
    };

    StreamSettings.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamSettings.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamSettings";
    };

    return StreamSettings;
})();

export const StreamViewInfo = $root.StreamViewInfo = (() => {

    function StreamViewInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamViewInfo.prototype.streamId = "";
    StreamViewInfo.prototype.hlsStreamInfo = null;
    StreamViewInfo.prototype.srtStreamInfo = null;
    StreamViewInfo.prototype.rtmpStreamInfo = null;
    StreamViewInfo.prototype.webRtcStreamInfo = null;

    StreamViewInfo.create = function create(properties) {
        return new StreamViewInfo(properties);
    };

    StreamViewInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.streamId);
        if (message.hlsStreamInfo != null && Object.hasOwnProperty.call(message, "hlsStreamInfo"))
            $root.HlsStreamInfo.encode(message.hlsStreamInfo, writer.uint32(18).fork()).ldelim();
        if (message.srtStreamInfo != null && Object.hasOwnProperty.call(message, "srtStreamInfo"))
            $root.SrtStreamInfo.encode(message.srtStreamInfo, writer.uint32(26).fork()).ldelim();
        if (message.rtmpStreamInfo != null && Object.hasOwnProperty.call(message, "rtmpStreamInfo"))
            $root.RtmpStreamInfo.encode(message.rtmpStreamInfo, writer.uint32(34).fork()).ldelim();
        if (message.webRtcStreamInfo != null && Object.hasOwnProperty.call(message, "webRtcStreamInfo"))
            $root.WebRtcStreamInfo.encode(message.webRtcStreamInfo, writer.uint32(42).fork()).ldelim();
        return writer;
    };

    StreamViewInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamViewInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamViewInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.streamId = reader.string();
                    break;
                }
            case 2: {
                    message.hlsStreamInfo = $root.HlsStreamInfo.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.srtStreamInfo = $root.SrtStreamInfo.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.rtmpStreamInfo = $root.RtmpStreamInfo.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.webRtcStreamInfo = $root.WebRtcStreamInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("streamId"))
            throw $util.ProtocolError("missing required 'streamId'", { instance: message });
        return message;
    };

    StreamViewInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamViewInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamViewInfo)
            return object;
        let message = new $root.StreamViewInfo();
        if (object.streamId != null)
            message.streamId = String(object.streamId);
        if (object.hlsStreamInfo != null) {
            if (typeof object.hlsStreamInfo !== "object")
                throw TypeError(".StreamViewInfo.hlsStreamInfo: object expected");
            message.hlsStreamInfo = $root.HlsStreamInfo.fromObject(object.hlsStreamInfo);
        }
        if (object.srtStreamInfo != null) {
            if (typeof object.srtStreamInfo !== "object")
                throw TypeError(".StreamViewInfo.srtStreamInfo: object expected");
            message.srtStreamInfo = $root.SrtStreamInfo.fromObject(object.srtStreamInfo);
        }
        if (object.rtmpStreamInfo != null) {
            if (typeof object.rtmpStreamInfo !== "object")
                throw TypeError(".StreamViewInfo.rtmpStreamInfo: object expected");
            message.rtmpStreamInfo = $root.RtmpStreamInfo.fromObject(object.rtmpStreamInfo);
        }
        if (object.webRtcStreamInfo != null) {
            if (typeof object.webRtcStreamInfo !== "object")
                throw TypeError(".StreamViewInfo.webRtcStreamInfo: object expected");
            message.webRtcStreamInfo = $root.WebRtcStreamInfo.fromObject(object.webRtcStreamInfo);
        }
        return message;
    };

    StreamViewInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.streamId = "";
            object.hlsStreamInfo = null;
            object.srtStreamInfo = null;
            object.rtmpStreamInfo = null;
            object.webRtcStreamInfo = null;
        }
        if (message.streamId != null && message.hasOwnProperty("streamId"))
            object.streamId = message.streamId;
        if (message.hlsStreamInfo != null && message.hasOwnProperty("hlsStreamInfo"))
            object.hlsStreamInfo = $root.HlsStreamInfo.toObject(message.hlsStreamInfo, options);
        if (message.srtStreamInfo != null && message.hasOwnProperty("srtStreamInfo"))
            object.srtStreamInfo = $root.SrtStreamInfo.toObject(message.srtStreamInfo, options);
        if (message.rtmpStreamInfo != null && message.hasOwnProperty("rtmpStreamInfo"))
            object.rtmpStreamInfo = $root.RtmpStreamInfo.toObject(message.rtmpStreamInfo, options);
        if (message.webRtcStreamInfo != null && message.hasOwnProperty("webRtcStreamInfo"))
            object.webRtcStreamInfo = $root.WebRtcStreamInfo.toObject(message.webRtcStreamInfo, options);
        return object;
    };

    StreamViewInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamViewInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamViewInfo";
    };

    return StreamViewInfo;
})();

export const HlsStreamInfo = $root.HlsStreamInfo = (() => {

    function HlsStreamInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    HlsStreamInfo.prototype.ldUrl = "";
    HlsStreamInfo.prototype.sdUrl = "";
    HlsStreamInfo.prototype.hdUrl = "";
    HlsStreamInfo.prototype.masterUrl = "";
    HlsStreamInfo.prototype.previewUrl = "";
    HlsStreamInfo.prototype.livePreviewUrl = "";

    HlsStreamInfo.create = function create(properties) {
        return new HlsStreamInfo(properties);
    };

    HlsStreamInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ldUrl != null && Object.hasOwnProperty.call(message, "ldUrl"))
            writer.uint32(10).string(message.ldUrl);
        if (message.sdUrl != null && Object.hasOwnProperty.call(message, "sdUrl"))
            writer.uint32(18).string(message.sdUrl);
        if (message.hdUrl != null && Object.hasOwnProperty.call(message, "hdUrl"))
            writer.uint32(26).string(message.hdUrl);
        if (message.masterUrl != null && Object.hasOwnProperty.call(message, "masterUrl"))
            writer.uint32(34).string(message.masterUrl);
        if (message.previewUrl != null && Object.hasOwnProperty.call(message, "previewUrl"))
            writer.uint32(42).string(message.previewUrl);
        if (message.livePreviewUrl != null && Object.hasOwnProperty.call(message, "livePreviewUrl"))
            writer.uint32(50).string(message.livePreviewUrl);
        return writer;
    };

    HlsStreamInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    HlsStreamInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.HlsStreamInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ldUrl = reader.string();
                    break;
                }
            case 2: {
                    message.sdUrl = reader.string();
                    break;
                }
            case 3: {
                    message.hdUrl = reader.string();
                    break;
                }
            case 4: {
                    message.masterUrl = reader.string();
                    break;
                }
            case 5: {
                    message.previewUrl = reader.string();
                    break;
                }
            case 6: {
                    message.livePreviewUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    HlsStreamInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    HlsStreamInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.HlsStreamInfo)
            return object;
        let message = new $root.HlsStreamInfo();
        if (object.ldUrl != null)
            message.ldUrl = String(object.ldUrl);
        if (object.sdUrl != null)
            message.sdUrl = String(object.sdUrl);
        if (object.hdUrl != null)
            message.hdUrl = String(object.hdUrl);
        if (object.masterUrl != null)
            message.masterUrl = String(object.masterUrl);
        if (object.previewUrl != null)
            message.previewUrl = String(object.previewUrl);
        if (object.livePreviewUrl != null)
            message.livePreviewUrl = String(object.livePreviewUrl);
        return message;
    };

    HlsStreamInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.ldUrl = "";
            object.sdUrl = "";
            object.hdUrl = "";
            object.masterUrl = "";
            object.previewUrl = "";
            object.livePreviewUrl = "";
        }
        if (message.ldUrl != null && message.hasOwnProperty("ldUrl"))
            object.ldUrl = message.ldUrl;
        if (message.sdUrl != null && message.hasOwnProperty("sdUrl"))
            object.sdUrl = message.sdUrl;
        if (message.hdUrl != null && message.hasOwnProperty("hdUrl"))
            object.hdUrl = message.hdUrl;
        if (message.masterUrl != null && message.hasOwnProperty("masterUrl"))
            object.masterUrl = message.masterUrl;
        if (message.previewUrl != null && message.hasOwnProperty("previewUrl"))
            object.previewUrl = message.previewUrl;
        if (message.livePreviewUrl != null && message.hasOwnProperty("livePreviewUrl"))
            object.livePreviewUrl = message.livePreviewUrl;
        return object;
    };

    HlsStreamInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    HlsStreamInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/HlsStreamInfo";
    };

    return HlsStreamInfo;
})();

export const SrtStreamInfo = $root.SrtStreamInfo = (() => {

    function SrtStreamInfo(properties) {
        this.regions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SrtStreamInfo.prototype.regions = $util.emptyArray;

    SrtStreamInfo.create = function create(properties) {
        return new SrtStreamInfo(properties);
    };

    SrtStreamInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.regions != null && message.regions.length)
            for (let i = 0; i < message.regions.length; ++i)
                $root.SrtRegionInfo.encode(message.regions[i], writer.uint32(10).fork()).ldelim();
        return writer;
    };

    SrtStreamInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SrtStreamInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SrtStreamInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.regions && message.regions.length))
                        message.regions = [];
                    message.regions.push($root.SrtRegionInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    SrtStreamInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SrtStreamInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.SrtStreamInfo)
            return object;
        let message = new $root.SrtStreamInfo();
        if (object.regions) {
            if (!Array.isArray(object.regions))
                throw TypeError(".SrtStreamInfo.regions: array expected");
            message.regions = [];
            for (let i = 0; i < object.regions.length; ++i) {
                if (typeof object.regions[i] !== "object")
                    throw TypeError(".SrtStreamInfo.regions: object expected");
                message.regions[i] = $root.SrtRegionInfo.fromObject(object.regions[i]);
            }
        }
        return message;
    };

    SrtStreamInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.regions = [];
        if (message.regions && message.regions.length) {
            object.regions = [];
            for (let j = 0; j < message.regions.length; ++j)
                object.regions[j] = $root.SrtRegionInfo.toObject(message.regions[j], options);
        }
        return object;
    };

    SrtStreamInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SrtStreamInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SrtStreamInfo";
    };

    return SrtStreamInfo;
})();

export const SrtRegionInfo = $root.SrtRegionInfo = (() => {

    function SrtRegionInfo(properties) {
        this.edges = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SrtRegionInfo.prototype.region = "";
    SrtRegionInfo.prototype.edges = $util.emptyArray;

    SrtRegionInfo.create = function create(properties) {
        return new SrtRegionInfo(properties);
    };

    SrtRegionInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.region);
        if (message.edges != null && message.edges.length)
            for (let i = 0; i < message.edges.length; ++i)
                $root.SrtEdge.encode(message.edges[i], writer.uint32(18).fork()).ldelim();
        return writer;
    };

    SrtRegionInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SrtRegionInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SrtRegionInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.region = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.edges && message.edges.length))
                        message.edges = [];
                    message.edges.push($root.SrtEdge.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("region"))
            throw $util.ProtocolError("missing required 'region'", { instance: message });
        return message;
    };

    SrtRegionInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SrtRegionInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.SrtRegionInfo)
            return object;
        let message = new $root.SrtRegionInfo();
        if (object.region != null)
            message.region = String(object.region);
        if (object.edges) {
            if (!Array.isArray(object.edges))
                throw TypeError(".SrtRegionInfo.edges: array expected");
            message.edges = [];
            for (let i = 0; i < object.edges.length; ++i) {
                if (typeof object.edges[i] !== "object")
                    throw TypeError(".SrtRegionInfo.edges: object expected");
                message.edges[i] = $root.SrtEdge.fromObject(object.edges[i]);
            }
        }
        return message;
    };

    SrtRegionInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.arrays || options.defaults)
            object.edges = [];
        if (options.defaults)
            object.region = "";
        if (message.region != null && message.hasOwnProperty("region"))
            object.region = message.region;
        if (message.edges && message.edges.length) {
            object.edges = [];
            for (let j = 0; j < message.edges.length; ++j)
                object.edges[j] = $root.SrtEdge.toObject(message.edges[j], options);
        }
        return object;
    };

    SrtRegionInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SrtRegionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SrtRegionInfo";
    };

    return SrtRegionInfo;
})();

export const SrtEdge = $root.SrtEdge = (() => {

    function SrtEdge(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    SrtEdge.prototype.ip = "";
    SrtEdge.prototype.port = 0;

    SrtEdge.create = function create(properties) {
        return new SrtEdge(properties);
    };

    SrtEdge.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.ip);
        writer.uint32(21).sfixed32(message.port);
        return writer;
    };

    SrtEdge.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    SrtEdge.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.SrtEdge();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ip = reader.string();
                    break;
                }
            case 2: {
                    message.port = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("ip"))
            throw $util.ProtocolError("missing required 'ip'", { instance: message });
        if (!message.hasOwnProperty("port"))
            throw $util.ProtocolError("missing required 'port'", { instance: message });
        return message;
    };

    SrtEdge.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    SrtEdge.fromObject = function fromObject(object) {
        if (object instanceof $root.SrtEdge)
            return object;
        let message = new $root.SrtEdge();
        if (object.ip != null)
            message.ip = String(object.ip);
        if (object.port != null)
            message.port = object.port | 0;
        return message;
    };

    SrtEdge.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.ip = "";
            object.port = 0;
        }
        if (message.ip != null && message.hasOwnProperty("ip"))
            object.ip = message.ip;
        if (message.port != null && message.hasOwnProperty("port"))
            object.port = message.port;
        return object;
    };

    SrtEdge.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    SrtEdge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/SrtEdge";
    };

    return SrtEdge;
})();

export const RtmpStreamInfo = $root.RtmpStreamInfo = (() => {

    function RtmpStreamInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    RtmpStreamInfo.prototype.url = "";

    RtmpStreamInfo.create = function create(properties) {
        return new RtmpStreamInfo(properties);
    };

    RtmpStreamInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(10).string(message.url);
        return writer;
    };

    RtmpStreamInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    RtmpStreamInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RtmpStreamInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.url = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("url"))
            throw $util.ProtocolError("missing required 'url'", { instance: message });
        return message;
    };

    RtmpStreamInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    RtmpStreamInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.RtmpStreamInfo)
            return object;
        let message = new $root.RtmpStreamInfo();
        if (object.url != null)
            message.url = String(object.url);
        return message;
    };

    RtmpStreamInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.url = "";
        if (message.url != null && message.hasOwnProperty("url"))
            object.url = message.url;
        return object;
    };

    RtmpStreamInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    RtmpStreamInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RtmpStreamInfo";
    };

    return RtmpStreamInfo;
})();

export const WebRtcStreamInfo = $root.WebRtcStreamInfo = (() => {

    function WebRtcStreamInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    WebRtcStreamInfo.prototype.enabled = false;
    WebRtcStreamInfo.prototype.pttActive = false;

    WebRtcStreamInfo.create = function create(properties) {
        return new WebRtcStreamInfo(properties);
    };

    WebRtcStreamInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(8).bool(message.enabled);
        if (message.pttActive != null && Object.hasOwnProperty.call(message, "pttActive"))
            writer.uint32(16).bool(message.pttActive);
        return writer;
    };

    WebRtcStreamInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    WebRtcStreamInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebRtcStreamInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.enabled = reader.bool();
                    break;
                }
            case 2: {
                    message.pttActive = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("enabled"))
            throw $util.ProtocolError("missing required 'enabled'", { instance: message });
        return message;
    };

    WebRtcStreamInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    WebRtcStreamInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.WebRtcStreamInfo)
            return object;
        let message = new $root.WebRtcStreamInfo();
        if (object.enabled != null)
            message.enabled = Boolean(object.enabled);
        if (object.pttActive != null)
            message.pttActive = Boolean(object.pttActive);
        return message;
    };

    WebRtcStreamInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.enabled = false;
            object.pttActive = false;
        }
        if (message.enabled != null && message.hasOwnProperty("enabled"))
            object.enabled = message.enabled;
        if (message.pttActive != null && message.hasOwnProperty("pttActive"))
            object.pttActive = message.pttActive;
        return object;
    };

    WebRtcStreamInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    WebRtcStreamInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/WebRtcStreamInfo";
    };

    return WebRtcStreamInfo;
})();

export const RecordedVideoViewInfo = $root.RecordedVideoViewInfo = (() => {

    function RecordedVideoViewInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    RecordedVideoViewInfo.prototype.masterUrl = "";
    RecordedVideoViewInfo.prototype.previewUrl = "";

    RecordedVideoViewInfo.create = function create(properties) {
        return new RecordedVideoViewInfo(properties);
    };

    RecordedVideoViewInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.masterUrl != null && Object.hasOwnProperty.call(message, "masterUrl"))
            writer.uint32(10).string(message.masterUrl);
        if (message.previewUrl != null && Object.hasOwnProperty.call(message, "previewUrl"))
            writer.uint32(18).string(message.previewUrl);
        return writer;
    };

    RecordedVideoViewInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    RecordedVideoViewInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RecordedVideoViewInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.masterUrl = reader.string();
                    break;
                }
            case 2: {
                    message.previewUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    RecordedVideoViewInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    RecordedVideoViewInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.RecordedVideoViewInfo)
            return object;
        let message = new $root.RecordedVideoViewInfo();
        if (object.masterUrl != null)
            message.masterUrl = String(object.masterUrl);
        if (object.previewUrl != null)
            message.previewUrl = String(object.previewUrl);
        return message;
    };

    RecordedVideoViewInfo.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.masterUrl = "";
            object.previewUrl = "";
        }
        if (message.masterUrl != null && message.hasOwnProperty("masterUrl"))
            object.masterUrl = message.masterUrl;
        if (message.previewUrl != null && message.hasOwnProperty("previewUrl"))
            object.previewUrl = message.previewUrl;
        return object;
    };

    RecordedVideoViewInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    RecordedVideoViewInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/RecordedVideoViewInfo";
    };

    return RecordedVideoViewInfo;
})();

export const StreamInitRestriction = $root.StreamInitRestriction = (() => {

    function StreamInitRestriction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamInitRestriction.prototype.or = null;
    StreamInitRestriction.prototype.and = null;
    StreamInitRestriction.prototype.gift = null;
    StreamInitRestriction.prototype.subscription = null;

    StreamInitRestriction.create = function create(properties) {
        return new StreamInitRestriction(properties);
    };

    StreamInitRestriction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.or != null && Object.hasOwnProperty.call(message, "or"))
            $root.StreamAccessInitRestriction.encode(message.or, writer.uint32(10).fork()).ldelim();
        if (message.and != null && Object.hasOwnProperty.call(message, "and"))
            $root.StreamAccessInitRestriction.encode(message.and, writer.uint32(18).fork()).ldelim();
        if (message.gift != null && Object.hasOwnProperty.call(message, "gift"))
            $root.StreamGiftFee.encode(message.gift, writer.uint32(26).fork()).ldelim();
        if (message.subscription != null && Object.hasOwnProperty.call(message, "subscription"))
            $root.StreamSubscriptionFee.encode(message.subscription, writer.uint32(34).fork()).ldelim();
        return writer;
    };

    StreamInitRestriction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamInitRestriction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamInitRestriction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.or = $root.StreamAccessInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.and = $root.StreamAccessInitRestriction.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.gift = $root.StreamGiftFee.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.subscription = $root.StreamSubscriptionFee.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StreamInitRestriction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamInitRestriction.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamInitRestriction)
            return object;
        let message = new $root.StreamInitRestriction();
        if (object.or != null) {
            if (typeof object.or !== "object")
                throw TypeError(".StreamInitRestriction.or: object expected");
            message.or = $root.StreamAccessInitRestriction.fromObject(object.or);
        }
        if (object.and != null) {
            if (typeof object.and !== "object")
                throw TypeError(".StreamInitRestriction.and: object expected");
            message.and = $root.StreamAccessInitRestriction.fromObject(object.and);
        }
        if (object.gift != null) {
            if (typeof object.gift !== "object")
                throw TypeError(".StreamInitRestriction.gift: object expected");
            message.gift = $root.StreamGiftFee.fromObject(object.gift);
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".StreamInitRestriction.subscription: object expected");
            message.subscription = $root.StreamSubscriptionFee.fromObject(object.subscription);
        }
        return message;
    };

    StreamInitRestriction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.or = null;
            object.and = null;
            object.gift = null;
            object.subscription = null;
        }
        if (message.or != null && message.hasOwnProperty("or"))
            object.or = $root.StreamAccessInitRestriction.toObject(message.or, options);
        if (message.and != null && message.hasOwnProperty("and"))
            object.and = $root.StreamAccessInitRestriction.toObject(message.and, options);
        if (message.gift != null && message.hasOwnProperty("gift"))
            object.gift = $root.StreamGiftFee.toObject(message.gift, options);
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.StreamSubscriptionFee.toObject(message.subscription, options);
        return object;
    };

    StreamInitRestriction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamInitRestriction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamInitRestriction";
    };

    return StreamInitRestriction;
})();

export const StreamSubscriptionFee = $root.StreamSubscriptionFee = (() => {

    function StreamSubscriptionFee(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamSubscriptionFee.prototype.level = 0;

    StreamSubscriptionFee.create = function create(properties) {
        return new StreamSubscriptionFee(properties);
    };

    StreamSubscriptionFee.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.level);
        return writer;
    };

    StreamSubscriptionFee.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamSubscriptionFee.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamSubscriptionFee();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.level = reader.sfixed32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("level"))
            throw $util.ProtocolError("missing required 'level'", { instance: message });
        return message;
    };

    StreamSubscriptionFee.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamSubscriptionFee.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamSubscriptionFee)
            return object;
        let message = new $root.StreamSubscriptionFee();
        if (object.level != null)
            message.level = object.level | 0;
        return message;
    };

    StreamSubscriptionFee.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            object.level = 0;
        if (message.level != null && message.hasOwnProperty("level"))
            object.level = message.level;
        return object;
    };

    StreamSubscriptionFee.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamSubscriptionFee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamSubscriptionFee";
    };

    return StreamSubscriptionFee;
})();

export const StreamGiftFee = $root.StreamGiftFee = (() => {

    function StreamGiftFee(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamGiftFee.prototype.ticketPriceInCredits = 0;
    StreamGiftFee.prototype.abonnement = null;

    StreamGiftFee.create = function create(properties) {
        return new StreamGiftFee(properties);
    };

    StreamGiftFee.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(13).sfixed32(message.ticketPriceInCredits);
        if (message.abonnement != null && Object.hasOwnProperty.call(message, "abonnement"))
            $root.Abonnement.encode(message.abonnement, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StreamGiftFee.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamGiftFee.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamGiftFee();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ticketPriceInCredits = reader.sfixed32();
                    break;
                }
            case 2: {
                    message.abonnement = $root.Abonnement.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("ticketPriceInCredits"))
            throw $util.ProtocolError("missing required 'ticketPriceInCredits'", { instance: message });
        return message;
    };

    StreamGiftFee.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamGiftFee.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamGiftFee)
            return object;
        let message = new $root.StreamGiftFee();
        if (object.ticketPriceInCredits != null)
            message.ticketPriceInCredits = object.ticketPriceInCredits | 0;
        if (object.abonnement != null) {
            if (typeof object.abonnement !== "object")
                throw TypeError(".StreamGiftFee.abonnement: object expected");
            message.abonnement = $root.Abonnement.fromObject(object.abonnement);
        }
        return message;
    };

    StreamGiftFee.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.ticketPriceInCredits = 0;
            object.abonnement = null;
        }
        if (message.ticketPriceInCredits != null && message.hasOwnProperty("ticketPriceInCredits"))
            object.ticketPriceInCredits = message.ticketPriceInCredits;
        if (message.abonnement != null && message.hasOwnProperty("abonnement"))
            object.abonnement = $root.Abonnement.toObject(message.abonnement, options);
        return object;
    };

    StreamGiftFee.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamGiftFee.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamGiftFee";
    };

    return StreamGiftFee;
})();

export const StreamAccessInitRestriction = $root.StreamAccessInitRestriction = (() => {

    function StreamAccessInitRestriction(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    StreamAccessInitRestriction.prototype.gift = null;
    StreamAccessInitRestriction.prototype.subscription = null;

    StreamAccessInitRestriction.create = function create(properties) {
        return new StreamAccessInitRestriction(properties);
    };

    StreamAccessInitRestriction.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.StreamGiftFee.encode(message.gift, writer.uint32(10).fork()).ldelim();
        $root.StreamSubscriptionFee.encode(message.subscription, writer.uint32(18).fork()).ldelim();
        return writer;
    };

    StreamAccessInitRestriction.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    StreamAccessInitRestriction.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StreamAccessInitRestriction();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.gift = $root.StreamGiftFee.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.subscription = $root.StreamSubscriptionFee.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        if (!message.hasOwnProperty("gift"))
            throw $util.ProtocolError("missing required 'gift'", { instance: message });
        if (!message.hasOwnProperty("subscription"))
            throw $util.ProtocolError("missing required 'subscription'", { instance: message });
        return message;
    };

    StreamAccessInitRestriction.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    StreamAccessInitRestriction.fromObject = function fromObject(object) {
        if (object instanceof $root.StreamAccessInitRestriction)
            return object;
        let message = new $root.StreamAccessInitRestriction();
        if (object.gift != null) {
            if (typeof object.gift !== "object")
                throw TypeError(".StreamAccessInitRestriction.gift: object expected");
            message.gift = $root.StreamGiftFee.fromObject(object.gift);
        }
        if (object.subscription != null) {
            if (typeof object.subscription !== "object")
                throw TypeError(".StreamAccessInitRestriction.subscription: object expected");
            message.subscription = $root.StreamSubscriptionFee.fromObject(object.subscription);
        }
        return message;
    };

    StreamAccessInitRestriction.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults) {
            object.gift = null;
            object.subscription = null;
        }
        if (message.gift != null && message.hasOwnProperty("gift"))
            object.gift = $root.StreamGiftFee.toObject(message.gift, options);
        if (message.subscription != null && message.hasOwnProperty("subscription"))
            object.subscription = $root.StreamSubscriptionFee.toObject(message.subscription, options);
        return object;
    };

    StreamAccessInitRestriction.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    StreamAccessInitRestriction.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/StreamAccessInitRestriction";
    };

    return StreamAccessInitRestriction;
})();

export const Abonnement = $root.Abonnement = (() => {

    function Abonnement(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    Abonnement.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    Abonnement.create = function create(properties) {
        return new Abonnement(properties);
    };

    Abonnement.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
            writer.uint32(9).sfixed64(message.amount);
        return writer;
    };

    Abonnement.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    Abonnement.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Abonnement();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.amount = reader.sfixed64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    Abonnement.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    Abonnement.fromObject = function fromObject(object) {
        if (object instanceof $root.Abonnement)
            return object;
        let message = new $root.Abonnement();
        if (object.amount != null)
            if ($util.Long)
                (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
            else if (typeof object.amount === "string")
                message.amount = parseInt(object.amount, 10);
            else if (typeof object.amount === "number")
                message.amount = object.amount;
            else if (typeof object.amount === "object")
                message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        return message;
    };

    Abonnement.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        let object = {};
        if (options.defaults)
            if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.amount = options.longs === String ? "0" : 0;
        if (message.amount != null && message.hasOwnProperty("amount"))
            if (typeof message.amount === "number")
                object.amount = options.longs === String ? String(message.amount) : message.amount;
            else
                object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
        return object;
    };

    Abonnement.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    Abonnement.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/Abonnement";
    };

    return Abonnement;
})();

export { $root as default };
