// modal
export const SHOW_MODAL = "lwc/modal/show";
export const DISMISS_MODAL = "lwc/modal/dismiss";
export const LOCK_MODAL = "lwc/modal/lock";

// screen
export const SHOW_BOTTOM_SCREEN = "lwc/bottomScreen/show";
export const HIDE_BOTTOM_SCREEN = "lwc/bottomScreen/hide";
export const BOTTOM_SCREEN_ANIMATION = "lwc/bottomScreen/animation";

// live feeds
export const LIVE_POSTS_BEGIN_FETCH = "lwc/liveFeed/fetchBegan";
export const LIVE_POSTS_END_FETCH = "lwc/liveFeed/fetchEnded";
export const LIVE_POSTS_MODE_SELECTED = "lwc/liveFeed/modeSelected";
export const LIVE_STREAM_FETCH_BY_ACCOUNT_ID = "lwc/liveStream/byAccountId";
export const LIVE_STREAM_FETCH_BY_ACCOUNT_IDS = "lwc/liveStream/byAccountIds";

// posts feed
export const POSTS_FEED_BEGIN_FETCH = "lwc/postsFeed/fetchBegan";
export const POSTS_FEED_END_FETCH = "lwc/postsFeed/fetchEnded";
export const SET_OTHER_FEED_OWNER = "lwc/postsFeed/setOtherFeedOwner";
export const SET_OTHER_SUB_ONLY_FEED_OWNER =
  "lwc/postsFeed/setOtherSubOnlyFeedOwner";
export const CLEAR_PHOTOS_SUBS_ONLY_FEED = "lwc/postsFeed/clearPhotosOnlyFeed";
export const UPDATE_LIKED_BY_ME = "lwc/postsFeed/updateLikedByMe";
export const CHANGE_POSTS_TYPE = "lwc/postsFeed/changeType";
export const DELETE_FEED_POST = "lwc/postsFeed/deletePost";

// top broadcasters
export const TOP_BROADCASTERS_BEGIN_FETCH = "lwc/topBroadcasters/fetchBegan";
export const TOP_BROADCASTERS_END_FETCH = "lwc/topBroadcasters/fetchEnded";
export const TOP_BROADCASTERS_INVALIDATE = "lwc/topBroadcasters/invalidate";

// following
export const FOLLOWING_LIST_BEGIN_FETCH = "lwc/followingList/fetchBegan";
export const FOLLOWING_LIST_END_FETCH = "lwc/followingList/fetchEnded";
export const FOLLOW_USER = "lwc/followingList/follow";

export const UNFOLLOW_USER_REALTIME_RECOMMENDATION =
  "lwc/followingList/unfollowRecommendations";
export const UNFOLLOW_USER = "lwc/followingList/unfollow";

export const UNLOCK_POST_SUCCESS = "lwc/postsFeed/unlockPostSuccess";

// user blocking
export const BLOCKED_USERS_LIST_BEGIN_FETCH = "lwc/blockedUsers/fetchBegan";
export const BLOCKED_USERS_LIST_END_FETCH = "lwc/blockedUsers/fetchEnded";

// gifts
export const CUSTOM_DRAWER_BEGIN_FETCH =
  "lwc/giftsList/customDrawer/fetchBegan";
export const CUSTOM_DRAWER_END_FETCH = "lwc/giftsList/customDrawer/fetchEnded";
export const GIFTS_LIST_BEGIN_FETCH = "lwc/giftsList/fetchBegan";
export const GIFTS_LIST_END_FETCH = "lwc/giftsList/fetchEnded";
export const GIFTS_LOADED_SINGLE_GIFT = "lwc/gifts/loadedSingle";
export const GIFTS_LIST_SET_AVAILABLE_VERSION =
  "lwc/giftsList/setAvailableVersion";
export const GIFTS_SET_CONTEXT = "lwc/gifts/setGiftingContext";
export const GIFT_SEND_OPTIMISTIC = "lwc/gifts/giftSendOptimistic";
export const GIFT_SENT = "lwc/gifts/giftSent";
export const GIFT_SENT_FAILED = "lwc/gifts/giftSentFailed";
export const GIFT_PLAYED = "lwc/gifts/giftPlayed";
export const COLLECTED_GIFTS_END_FETCH = "lwc/gifts/collectedGifts/fetchEnded";
export const GIFTS_SET_TARGET_COLLECTION = "lwc/gifts/setTargetCollection";
export const GIFTS_SET_DRAWER_TAB = "lwc/gifts/setDrawerTab";
export const GIFTS_SET_OPTION_LAST_CLICKED = "lwc/gifts/setOptionLastClicked";
export const ADD_GIFTS = "lwc/gifts/addGifts";

// settings
export const TOGGLE_GIFTS_DRAWER = "lwc/settings/giftsDrawer";
export const SET_GIFTS_DRAWER_VISIBILITY = "lwc/settings/giftsDrawer/set";
export const SETTINGS_BEGIN_FETCH = "lwc/settings/beginFetch";
export const SETTINGS_END_FETCH = "lwc/settings/endFetch";
export const SETTINGS_BEGIN_SAVE = "lwc/settings/beginSave";
export const SETTINGS_END_SAVE = "lwc/settings/endSave";

// connection manager
export const ACME_RECEIVED = "lwc/connectionManager/acme";
export const LIVE_RICH_NOTIFICATION_RECEIVED =
  "lwc/connectionManager/liveRichNotification";
export const LIVE_STREAM_STARTED_NOTIFICATION_RECEIVED =
  "lwc/connectionManager/liveStreamStarted";
export const CONNECTION_MANAGER_CONNECTED = "lwc/connectionManager/connected";
export const CONNECTION_MANAGER_DISCONNECTED =
  "lwc/connectionManager/disconnected";
export const CONNECTION_MANAGER_RECONNECT = "lwc/connectionManager/reconnect";
export const CONNECTION_MANAGER_CHANGE_USERNAME =
  "lwc/connectionManager/change/username";
export const SET_CONNECTION_MANAGER_WEB_PUSH_API_TOKEN =
  "lwc/connectionManager/setWebPushApiToken";

// notifications
export const INSERT_NOTIFICATION = "lwc/notification/enqueue";
export const UPDATE_NOTI_PERMISSION = "lwc/notification/updatePermission";
export const SET_NOTIFICATIONS_SUPPORTED =
  "lwc/notification/updateSupportedStatus";
export const CLEAR_NOTIFICATIONS = "lwc/notification/clear";
export const MUTE_NOTIFICATIONS = "lwc/notification/mute";

// profiles cache
export const SET_LOADING_PROFILE_IDS = "lwc/profiles/setLoadingProfiles";
export const FAILED_TO_LOAD_PROFILE_IDS =
  "lwc/profiles/failedToLoadRequestedIds";
export const LOADED_PROFILES = "lwc/profiles/loadedBatch";
export const SINGLE_PROFILE_BEGIN_FETCH = "lwc/profile/willLoad";
export const SINGLE_PROFILE_END_FETCH = "lwc/profile/loadEnded";
export const LOADED_PROFILE_BY_NICKNAME = "lwc/profile/loadedByNickname";
export const RESET_LOADING_PROFILE_ERRORS = "lwc/profiles/resetLoadingErrors";

// user stuff
export const BALANCE_BEGIN_FETCH = "lwc/user/balance/beginFetch";
export const BALANCE_END_FETCH = "lwc/user/balance/endFetch";
export const TOTAL_CREDITS_UPDATE = "lwc/user/balance/totalCredits";
export const ACCOUNT_TAGS_UPDATE = "lwc/user/tags";
export const CHANGE_TYPE_BUY_GIFT = "lwc/user/changeTypeBuyGift";
export const SET_STATUS_GIFT_WITH_DIAMOND =
  "lwc/user/isAvailableGiftingWithDiamonds";
export const GIFTS_BALANCE_BEGIN_FETCH = "lwc/user/giftsBalance/beginFetch";
export const GIFTS_BALANCE_END_FETCH = "lwc/user/giftsBalance/endFetch";
export const SET_GIFT_ID_TO_CONFIRM = "lwc/user/setGiftIdToConfirm";
export const SET_GIFT_AFTER_BUY = "lwc/user/setGiftAfterBuy";
export const SEND_GIFT_AFTER_BUY = "lwc/user/sendGiftAfterBuy";

// viewer session
export const VIEWER_SESSION_RESET = "lwc/viewerSession/reset";
export const VIEWER_SESSION_INITIALIZATION_END = "lwc/viewerSession/initEnd";
export const VIEWER_SESSION_SET_DIRTY_FLAGS = "lwc/viewerSession/setDirtyFlags";
export const VIEWER_SESSION_UPDATE = "lwc/viewerSession/updateSession";
export const VIEWER_SESSION_NOTIFICATIONS =
  "lwc/viewerSession/updateNotifications";
export const VIEWER_SESSION_LEFT = "lwc/viewerSession/left";
export const VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH =
  "lwc/viewerSession/forceEventsMaxLength";
export const VIEWER_SESSION_CLOSE_PIP_PLAYER =
  "lwc/viewerSession/closePipPlayer";
export const VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED =
  "lwc/viewerSession/giftEventSoundProcessed";

export const SEND_MESSAGE_TO_SESSION_BEGIN =
  "lwc/viewerSession/sendMessageStart";
export const SEND_MESSAGE_TO_SESSION_END = "lwc/viewerSession/sendMessageEnd";
export const VIEWER_SESSION_WILL_BUY_TICKET = "lwc/viewerSession/willBuyTicket";
export const VIEWER_SESSION_BOUGHT_TICKET = "lwc/viewerSession/boughtTicket";
export const VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT =
  "lwc/viewerSession/clearQueuedGiftEvent";
export const VIEWER_SESSION_RESET_QUEUE = "lwc/viewerSession/resetQueue";
export const VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT =
  "lwc/viewerSession/clearTopGifterEvent";
export const VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT =
  "lwc/viewerSession/topGifters/requestLimit";
export const VIEWER_SESSION_TOP_GIFTERS_BEGIN_FETCH =
  "lwc/viewerSession/topGifters/willRequest";
export const VIEWER_SESSION_TOP_GIFTERS_END_FETCH =
  "lwc/viewerSession/topGifters/didRequest";
export const VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW =
  "lwc/viewerSession/pullEvents/window/loaded";
export const VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT =
  "lwc/viewerSession/pullEvents/fragment/loaded";
export const VIEWER_SESSION_RESET_COMPETITION =
  "lwc/viewerSession/resetCompetition";
export const VIEWER_SESSION_PREMIUM_MB_START_WATCH =
  "lwc/viewerSession/premium/multiBroadcast/startWatch";

// live chat translator
export const VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN =
  "lwc/viewerSession/live/chat/translation/begin";
export const VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END =
  "lwc/viewerSession/live/chat/translation/end";
export const VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR =
  "lwc/viewerSession/live/chat/translation/error";
export const VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED =
  "lwc/viewerSession/live/chat/translation/setIsTranslated";
export const VIEWER_SESSION_SET_CLEAR_VIEW = "lwc/viewerSession/setClearView";

export const UPDATE_LIVE_STREAM_INFO = "lwc/stream/info";
export const UPDATE_LIVE_STREAM_BATCH_STATUSES = "lwc/stream/batchStatus";
export const UPDATE_LIVE_STREAM_SETTINGS = "lwc/stream/settings";
export const UPDATE_LIVE_STREAM_VOLUME = "lwc/stream/volume/update";
export const UPDATE_BATCH_LIVE_STREAM_VOLUME = "lwc/stream/volume/batch/update";
export const CLEAR_LIVE_STREAM_VOLUME = "lwc/stream/volume/clear";
// shop
export const PURCHASABLE_ITEMS_BEGIN_FETCH = "lwc/shop/beginFetch";
export const PURCHASABLE_ITEMS_END_FETCH = "lwc/shop/endFetch";
// promoShop
export const PROMO_PURCHASABLE_ITEMS_BEGIN_FETCH = "lwc/promoShop/beginFetch";
export const PROMO_PURCHASABLE_ITEMS_END_FETCH = "lwc/promoShop/endFetch";

// vipShop
export const VIP_PURCHASABLE_ITEMS_BEGIN_FETCH = "lwc/vipShop/beginFetch";
export const VIP_PURCHASABLE_ITEMS_END_FETCH = "lwc/vipShop/endFetch";

// mintroute shop
export const MINTROUTE_PURCHASABLE_ITEMS_BEGIN_FETCH =
  "lwc/mintrouteShop/beginFetch";
export const MINTROUTE_PURCHASABLE_ITEMS_END_FETCH =
  "lwc/mintrouteShop/endFetch";
export const MINTROUTE_SET_SUSPENDED_STATUS =
  "lwc/mintrouteShop/suspendedStatus";

// analytics - this is intercepted by middleware and not propagated deeper
export const ANALYTICS = "lwc/analytics";

// search
export const WILL_LOAD_USER_SEARCH = "lwc/userSearch/willLoad";
export const LOADED_USER_SEARCH = "lwc/userSearch/resultLoaded";
export const FAILED_TO_LOAD_USER_SEARCH = "lwc/userSearch/resultFailed";

// referrals
export const REFERRALS_LIST_FETCH_START = "lwc/referral/willLoad";
export const REFERRALS_LIST_FETCH_END = "lwc/referral/didLoad";

// NFT auction
export const AUCTIONS_LIST_FETCH_START = "lwc/nft/auctionsList/beginFetch";
export const AUCTIONS_LIST_FETCH_END = "lwc/nft/auctionsList/endFetch";
export const AUCTIONS_LIST_SET_STALE = "lwc/nft/auctionsList/setStale";

export const NFT_ACCOUNT_FETCH_START = "lwc/nft/account/beginFetch";
export const NFT_ACCOUNT_FETCH_END = "lwc/nft/account/endFetch";

export const NFT_PROFILE_SET_STALE = "lwc/nft/profile/setStale";

export const LOTS_LIST_FETCH_START = "lwc/nft/lotsList/beginFetch";
export const LOTS_LIST_FETCH_END = "lwc/nft/lotsList/endFetch";
export const LOTS_CLEAR = "lwc/nft/lot/clear";
export const USER_LOTS_CLEAR = "lwc/nft/lot/clearUserLots";

export const PROCESS_LOT_BID_START = "lwc/nft/lot/processBid/start";
export const PROCESS_LOT_BID_END = "lwc/nft/lot/processBid/end";
export const UPDATE_LOT_STATUS = "lwc/nft/lot/updateStatus";
export const UPDATE_LOTS = "lwc/nft/lot/updateLots";
export const SET_SCROLL = "lwc/nft/lot/setScroll";
export const RESET_SCROLL = "lwc/nft/lot/resetScroll";
export const ADD_LOT_TO_MY_LOT_LIST = "lwc/nft/lot/addToMy";
export const SET_LOT_LIST_STALE = "lwc/nft/lot/setLotListStale";

// promotion bonus config

// credit cards
export const SET_ERROR_MESSAGE_FOR_CREDIT_CARD =
  "lwc/creditCard/setErrorMessage";
export const REMOVE_ERROR_MESSAGE_FOR_CREDIT_CARD =
  "lwc/creditCard/RemoveMessage";
