import React, { useCallback, useEffect } from "react";
import Hls, { Fragment } from "hls.js";
import logger from "utils/logger";

/// The increase in media time in case of player decode error
const PLAYER_ADVANCE_DURATION = 1.002;

/// The increase in media time past the end of current chunk
const PLAYER_ADVANCE_PAST_END_OF_CHUNK_DURATION = 0.001;

type HlsJsInternals = {
  streamController: {
    fragPlaying: Fragment | null;
  };
};

const resumeTime = (video: HTMLMediaElement, hls: Hls) => {
  const streamController = (hls as unknown as HlsJsInternals).streamController;

  if (!streamController) {
    logger.warn("No streamController property on hls instance");
  }

  const mediaTime = video.currentTime;
  const fragPlaying = streamController?.fragPlaying;
  if (
    fragPlaying &&
    fragPlaying.startPTS &&
    fragPlaying.endPTS &&
    fragPlaying.startPTS <= mediaTime &&
    mediaTime <= fragPlaying.endPTS
  ) {
    // Effectively skip the rest of the media in currently playing chunk.
    return fragPlaying.endPTS + PLAYER_ADVANCE_PAST_END_OF_CHUNK_DURATION;
  }

  // Try skip 1 second of media
  return mediaTime + PLAYER_ADVANCE_DURATION;
};

/*
  React hook that sets up an error handler on the <video> element to catch
  `MediaError.MEDIA_ERR_DECODE` errors and trigger recovery by skipping the broken
  fragment using hls.js.
*/
export const useHlsJsVideoElementDecodeErrorRecovery = ({
  hlsRef,
  videoRef,
}: {
  hlsRef: React.RefObject<Hls | null>;
  videoRef: React.RefObject<HTMLVideoElement | null>;
}) => {
  const onError = useCallback(
    (event: ErrorEvent) => {
      if (!(event.currentTarget instanceof HTMLVideoElement)) {
        return;
      }
      const videoElement = event.currentTarget;
      const error = videoElement.error;
      if (!error) {
        logger.warn(
          "Video failed to load, but no specific error code was provided."
        );

        return;
      }

      logger.warn(
        `HTMLVideoElement error: code = ${error.code}, message = ${error.message}`
      );

      if (error.code === MediaError.MEDIA_ERR_DECODE) {
        const hls = hlsRef.current;
        if (!hls) {
          return;
        }
        const video = hls.media;
        if (video) {
          const time = resumeTime(video, hls);
          video.load();
          video.currentTime = time;
          hls.recoverMediaError();
        }
      }
    },
    [hlsRef]
  );

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      logger.log("no video ref");

      return;
    }

    video.addEventListener("error", onError);

    return () => {
      video.removeEventListener("error", onError);
    };
  }, [videoRef, onError]);
};
