import React from "react";
import { Helmet } from "react-helmet";

export const FAQCanonicalTags = () => (
  <Helmet>
    <link rel="alternate" hrefLang="en" href="https://tango.me/faq " />
    <link rel="alternate" hrefLang="tr" href="https://tango.me/tr/faq " />
    <link rel="alternate" hrefLang="x-default" href="https://tango.me/faq " />
    <link rel="canonical" href="https://tango.me/faq " />
  </Helmet>
);
