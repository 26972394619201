import { GiftDrawerTarget } from "src/features/giftsDrawer/api/giftDrawerTypes";
import { getIsGiftsDrawerV2Enabled } from "src/features/giftsDrawer/giftsDrawerSoc";
import {
  getCustomGiftStarActiveIcon,
  getCustomGiftStarIcon,
  getIsCustomGiftViewEnabled,
} from "src/features/giftsDrawer/imports/abTests";
import { CUSTOM_GIFT_CATEGORY_ID } from "src/features/giftsDrawer/imports/constants";
import {
  RootState,
  giftsCacheSelectors,
} from "src/features/giftsDrawer/imports/state";
import { DictionaryBy } from "src/features/giftsDrawer/imports/types";
import { Category } from "./action";

export const giftDrawerSelectors = {
  isLoading: (state: RootState) => {
    const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

    if (isGiftsDrawerV2Enabled) {
      return state.giftDrawer.isLoading;
    }

    return giftsCacheSelectors.isGiftDrawerLoading(state.giftsCache);
  },
  getDrawerInfo: (state: RootState, target: GiftDrawerTarget) => {
    const drawer = state.giftDrawer.drawers[target];

    return {
      drawerId: drawer?.drawerId,
      drawerVersion: drawer?.drawerVersion,
    };
  },
  getCategoryIdsList: (state: RootState, target?: GiftDrawerTarget) => {
    const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

    if (isGiftsDrawerV2Enabled && target) {
      const categoryIdsList = state.giftDrawer.drawers[target]?.categoryIdsList;

      if (categoryIdsList && categoryIdsList.length) {
        return categoryIdsList;
      }
    }

    return giftsCacheSelectors.getCategoryIdsList(
      state.giftsCache
    ) as Category["id"][];
  },
  getCategories: (state: RootState, target?: GiftDrawerTarget) => {
    const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

    if (isGiftsDrawerV2Enabled && target) {
      const categories = state.giftDrawer.drawers[target]?.categories;

      if (categories && Object.keys(categories).length) {
        return categories;
      }
    }

    return giftsCacheSelectors.getCategories(state.giftsCache) as DictionaryBy<
      Category,
      "id"
    >;
  },
  getCategoryById: (
    state: RootState,
    id: Category["id"],
    target?: GiftDrawerTarget
  ) => {
    const isCustomGiftViewEnabled = getIsCustomGiftViewEnabled(state);

    if (isCustomGiftViewEnabled && id === CUSTOM_GIFT_CATEGORY_ID) {
      const category: Category = {
        id: CUSTOM_GIFT_CATEGORY_ID,
        name: "Custom Gift",
        icon: getCustomGiftStarIcon(state),
        activeIcon: getCustomGiftStarActiveIcon(state),
        giftIds: [],
        collectionIds: [],
      };

      return category;
    }

    const isGiftsDrawerV2Enabled = getIsGiftsDrawerV2Enabled(state);

    if (isGiftsDrawerV2Enabled && target) {
      const category = state.giftDrawer.drawers[target]?.categories[id];

      if (category) {
        return category;
      }
    }

    return giftsCacheSelectors.getCategoryById(
      state.giftsCache,
      id
    ) as Category;
  },
};
