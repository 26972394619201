import {
  CanvasSupplier,
  MediaEngine,
  ReactPlayerAdapter,
} from "@video/video-framework";
import { UAParser } from "ua-parser-js";
import { getProxycadorHost } from "src/environment";
import { Nullable } from "src/types/common";

let mediaEngine: Nullable<MediaEngine> = null;

function makeMediaEngine(): MediaEngine {
  const engine = new MediaEngine(
    MediaEngine.makeDumpsterAnalyticsReporter(
      `${getProxycadorHost()}/dumpster/event`
    ),
    new UAParser()
  );

  return engine;
}

export function getMediaEngine(): MediaEngine {
  mediaEngine ??= makeMediaEngine();

  return mediaEngine;
}

export function makePlayerAdapter(canvas: CanvasSupplier): ReactPlayerAdapter {
  return new ReactPlayerAdapter(getMediaEngine(), canvas);
}
