import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { checkIfEventBufferIsStale } from "src/core/analytics/dataMesh/utils/bufferMethods/checkIfEventBufferIsStale";
import { checkIsEventBufferSizeExceeded } from "src/core/analytics/dataMesh/utils/bufferMethods/checkIsEventBufferSizeExceeded";
import { getEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/getEventBuffer";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { sendEventUtil } from "src/core/analytics/dataMesh/utils/sendEventUtil";
import { DataMeshEventPayload } from "src/core/analytics/types";

export const addEventToBuffer = async (event: DataMeshEventPayload) => {
  const eventBuffer = await getEventBuffer();

  checkIfEventBufferIsStale();

  const isBufferFlushReady = await checkIsEventBufferSizeExceeded();

  if (isBufferFlushReady) {
    sendEventUtil([...eventBuffer, event], true);

    return;
  }

  await dataMeshParamsStorage.setItem(
    StorageKeys.SESSION_BUFFER,
    JSON.stringify([...eventBuffer, event])
  );
};
