import { CategoriesResponse } from "src/features/tangoAcademy/common/types";
import {
  enhancedFetch,
  getProxycadorHost,
} from "src/features/tangoAcademy/imports/common";

export const getCategoriesApi = async (): Promise<CategoriesResponse> => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/academy/public/v1/categories?webclient=true`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};
