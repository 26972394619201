export interface GetCustomGiftsResponse {
  customGifts: CustomGiftType[];
  status?: GetCustomGiftsStatus;
  uid?: string;
}

export enum GetCustomGiftsStatus {
  SUCCESS = "SUCCESS",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  UNKNOWN = "UNKNOWN",
}

export interface CustomGiftType {
  encryptedCustomGiftId?: string;
  encryptedGiftId?: string;
  iconUrl?: string;
  priceInCredits?: number;
  status?: CustomGiftStatus;
}

export enum CustomGiftStatus {
  PROCESSING = "PROCESSING",
  REJECTED = "REJECTED",
  RELEASED = "RELEASED",
  UNKNOWN = "UNKNOWN",
  UNRELEASED = "UNRELEASED",
  WAITING_FOR_APPROVE = "WAITING_FOR_APPROVE",
}
