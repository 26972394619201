import { EventFields, EventNames, emitEvent } from "chat/imports/analytics";
import {
  ChatReactionAnalyticsParams,
  ChatReactionSentStatusAnalytic,
} from "chat/types";

export function emitReactionSentEvent(
  params: ChatReactionAnalyticsParams,
  apiRequestStatusParams: ChatReactionSentStatusAnalytic
): void {
  const {
    target,
    action,
    flags,
    chatId,
    messageId,
    tangoScreen,
    sourceId,
    recipientAccountId,
    itemType,
  } = params;
  const eventName = EventNames.UI_ACTION;

  const reactionParams = {
    [EventFields.TARGET]: target,
    [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
    [EventFields.CHAT_ID]: chatId,
    [EventFields.ACTION]: action,
    [EventFields.TANGO_SCREEN]: tangoScreen,
    [EventFields.ITEM_TYPE]: itemType,
    [EventFields.MESSAGE_ID]: messageId,
    [EventFields.SHARE_SOURCE_ID]: sourceId,
    [EventFields.FLAGS]: flags,
  };

  emitEvent(eventName, { ...reactionParams, ...apiRequestStatusParams });
}
