export enum Direction {
  FORWARD = "FORWARD",
  REVERSE = "REVERSE",
}

export enum FileType {
  IMAGE = "image",
  VIDEO = "video",
}

export enum ParamConversationState {
  ACTIVE,
  CHAT_REQUEST,
}

export enum ApiMessageType {
  AUDIO_MESSAGE = 2,
  DEEPLINK_MESSAGE = 59, // message sent by SCREAM (???), check if needed
  DEVICE_LOGIN_INFO_MESSAGE = 111,
  FAMILY_INVITE_MESSAGE = 104,
  GIF_MESSAGE = 117,
  GIFT_IN_CHAT = 18, // using payload
  GO_LIVE_TO_BC_MESSAGE = 101, // no payload
  GROUP_MEMBER_JOIN = 11,
  GROUP_MEMBER_LEAVE = 12,
  GROUP_NAME_CHANGE = 13,
  GROUP_PICTURE_CHANGE = 114,
  GROUP_SETTING_CHANGED = 116,
  GROUP_UPDATE = 17, // invisible, used only to notify client group muted setting is changed via regular ts-based request
  IMAGE_MESSAGE = 3,
  KYC_VERIFICATION_REQUESTED = 112,
  LIKE_MESSAGE = 61, // invisible, returned separately
  LIVE_STREAM = 89, // chat stream message, no payload
  MISSED_CALL_MESSAGE = 36,
  NORMAL_CALL_MESSAGE = 35,
  PHOTO_SAVED_INFO_MESSAGE = 108,
  PREMIUM_MESSAGE_SHARED = 113,
  PROFILE_MESSAGE = 34, // share profile message, using payload
  REFERRAL_MESSAGE = 110,
  SCREENSHOT_INFO_MESSAGE = 107,
  SDK_EXTERNAL_MESSAGE = 20, // used to sent messages from SCREAM, SUBSCRIPTION
  SOCIAL_POST_MESSAGE = 33, // feed repost message, using payload
  STICKER_MESSAGE = 58, // regular sticker, using payload
  SUBSCRIPTION = 100, // !!! payload is sent via BODY as JSON
  TANGO_SURPRISE_MESSAGE = 5, // animated sticker message, using payload
  TEXT_MESSAGE = 0,
  TEXT_MESSAGE_WITH_LINK = 115,
  UNKNOWN = 127,
  UNSUPPORTED = 126,
  VIDEO_MESSAGE = 1,
  VIDEO_SAVED_INFO_MESSAGE = 109,
  VIP_ASSIGNMENT_MESSAGE = 103,

  VOICE_MESSAGE_2 = 102,
}

export enum ResponseCode {
  RESPONSE_STATUS_CODE_OK,
  // Request is not authenticated
  RESPONSE_STATUS_CODE_UNAUTHENTICATED,
  // User is not allowed to perform the requested operation because not a group member
  RESPONSE_STATUS_CODE_UNAUTHORIZED_NOT_GROUP_MEMBER,
  // Group cannot be found in DB
  RESPONSE_STATUS_CODE_NONEXISTENT_GROUP,
  // Group id cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_GROUP_ID,
  // Account id cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_ACCOUNT_ID,
  // Peer id (account id or group id) cannot be decoded
  RESPONSE_STATUS_CODE_INVALID_PEER_ID,
  // Request not well formed
  RESPONSE_STATUS_CODE_INVALID_REQUEST,
  // User is not allowed to perform the requested operation
  RESPONSE_STATUS_CODE_UNAUTHORIZED,
  // User is blocked from sending to account
  RESPONSE_STATUS_CODE_BLOCKED,
  // Group size too big
  RESPONSE_GROUP_SIZE_TOO_BIG,
  // Message not found
  RESPONSE_STATUS_CODE_MESSAGE_NONEXISTENT,
  // Could not add user to group
  RESPONSE_STATUS_CODE_UNABLE_TO_ADD_USER,
  // Current message is sent but the next one would be blocked
  RESPONSE_STATUS_CODE_MESSAGE_QUOTA_EXCEEDED,
  // Server error
  RESPONSE_STATUS_CODE_SVR_ERR = 100,
}

export enum UIActionTarget {
  CHAT_REQUESTS = "chat_requests",
  OPEN_DIALOG = "open_dialog",
}

export enum UIActionMenuTarget {
  MUTE_CHAT = "mute_chat",
  PIN_CHAT = "pin_chat",
  UNMUTE_CHAT = "unmute_chat",
  UNPIN_CHAT = "unpin_chat",
}

export enum ChatSource {
  CHAT = "chat",
  CHAT_REQUEST = "chat_request",
}

export enum ConversationsListItemClickedText {
  FAMILY = "family",
  GROUP = "group",
  SINGLE = "single",
  SYSTEM = "system",
}

export enum TranslateItemType {
  CACHE = "translate_from_cache",
  SERVER = "translate_from_server",
}

export enum MessageStatusResult {
  FAILURE = 0,
  SUCCESS = 1,
}

export enum ReactionStatusResult {
  FAILURE = 0,
  SUCCESS = 1,
}

export enum ChatErrorType {
  SEND_MESSAGE_ERROR = "sendMessageError",
  TRANSLATION_ERROR = "translationError",
}

export enum StreamSourceChatType {
  GROUP = 1,
  LIVE_FAMILY = 2,
  SINGLE = 0,
}

export enum CHAT_EVENTS_TYPES {
  CHAT_MUTED = "CHAT_MUTED",
  CHAT_PINNED = "CHAT_PINNED",
  CHAT_UNMUTED = "CHAT_UNMUTED",
  CHAT_UNPINNED = "CHAT_UNPINNED",
  DELETE_CHAT_FOR_ME = "DELETE_CHAT_FOR_ME",
  DELETE_MESSAGE_FOR_ALL = "DELETE_MESSAGE_FOR_ALL",
  DELETE_MESSAGE_FOR_ME = "DELETE_MESSAGE_FOR_ME",
  EDIT_MESSAGE = "EDIT_MESSAGE",
}

export enum REACTION {
  CRYING = "😭",
  FIRE = "🔥",
  HANDS = "🙏",
  HEART = "❤️",
  LAUGH = "😂",
  THUMB_UP = "👍",
}

export enum DROPZONE_FILE_REJECTION_CODES {
  FILE_INVALID_TYPE = "file-invalid-type",
  FILE_TOO_LARGE = "file-too-large",
}
