import React, { ChangeEvent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CloseIcon, SendIcon } from "chat/imports/assets";
import {
  Button,
  GiftsCarousel,
  GrowingTextArea,
  Spinner,
  Typography,
} from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { useBreakpointPrecise } from "chat/imports/hooks";
import {
  RootState,
  getPremiumMessagesEnabled,
  getPremiumMessagesSendEnabled,
} from "chat/imports/state";
import { Gift as RawGift, VoidCallback } from "chat/imports/types";
import { ContentType } from "chat/premiumMessage/types";
import { conversationId } from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import styles from "./MediaPreview.scss";

const messages = defineMessages({
  captionPlaceholder: {
    id: "modal.send-media.caption.placeholder",
    defaultMessage: "Add caption...",
  },
  sendAsPremiumMessage: {
    id: "modal.send-media.premium",
    defaultMessage: "Send as Premium Message",
  },
});

type SendMediaToChatProps = {
  caption: string;
  conversationId: conversationId;
  gifts: RawGift[];
  mediaType: ContentType;
  mediaUrl: string;
  onChangeCaption: (caption: string) => void;
  onClickMore: VoidCallback;
  onClose: VoidCallback;
  onSelectGift: (giftId: string) => void;
  onSend: VoidCallback;
  selectedGiftId: string;
};

const MediaPreview = ({
  gifts,
  mediaUrl,
  mediaType,
  caption,
  selectedGiftId,
  onSelectGift,
  onSend,
  onClose,
  onChangeCaption,
  onClickMore,
  conversationId,
}: SendMediaToChatProps) => {
  const { formatMessage } = useIntl();
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const videoRef = React.createRef<HTMLVideoElement>();

  const isGroupChat = isGroupChatId(conversationId);

  const isPremiumMessageSendEnabled = useSelector(
    (state: RootState) =>
      getPremiumMessagesSendEnabled(state) &&
      getPremiumMessagesEnabled(state) &&
      !isGroupChat
  );

  const handleChangeCaption = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeCaption(e.target.value);
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current && !isDesktop) {
      videoRef.current.currentTime = 0.5;
    }
  };

  const preview =
    mediaType === ContentType.PHOTO ? (
      <img src={mediaUrl} className={styles.mediaPreview} alt="" />
    ) : (
      <video
        ref={videoRef}
        className={styles.mediaPreview}
        playsInline
        controls
        preload="metadata"
        src={mediaUrl}
        onLoadedMetadata={handleLoadedMetadata}
      />
    );

  return (
    <div className={styles.root} data-testid="send-media-modal">
      <div className={styles.mediaContainer}>
        {mediaUrl ? preview : <Spinner className={styles.spinner} />}
      </div>

      {isPremiumMessageSendEnabled && (
        <div>
          <div className={styles.premiumGiftsContainer}>
            <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH2}>
              {formatMessage(messages.sendAsPremiumMessage)}
            </Typography>
          </div>
          <GiftsCarousel
            gifts={gifts}
            selectedGiftId={selectedGiftId}
            onSelectGift={onSelectGift}
            onClickMore={onClickMore}
          />
        </div>
      )}

      <div className={styles.inputContainer}>
        <GrowingTextArea
          className={styles.input}
          placeholder={formatMessage(messages.captionPlaceholder)}
          value={caption}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleChangeCaption}
          data-testid="textarea"
        />
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.ICON_ONLY}
          className={styles.sendBtn}
          onClick={onSend}
          data-testid="send"
          disabled={!mediaUrl}
        >
          <SendIcon />
        </Button>
      </div>

      <Button
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.ICON_ONLY}
        onClick={onClose}
        className={styles.closeBtn}
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export default MediaPreview;
