export enum ClientSessionFields {
  APPS_FLYER_ID = "appsflyerId",
  BROWSER = "browser",
  BROWSER_VERSION = "browserVersion",
  DEVICE_CATEGORY = "deviceCategory",
  DEVICE_COUNTRY = "deviceCountry",
  DEVICE_ID = "deviceId",
  DEVICE_LANGUAGE = "deviceLanguage",
  DEVICE_MANUFACTURER = "deviceManufacturer",
  DEVICE_MODEL = "deviceModel",
  OS = "os",
  OS_VERSION = "osVersion",
  PLATFORM = "platform",
  TANGO_VERSION = "tangoVersion",
  USERNAME = "username",
  VISITOR_ID = "visitorId",
}

export enum StorageKeys {
  SESSION_BUFFER = "session_buffer",
  SESSION_START_ID = "session_start_id",
  SESSION_START_PARAMS = "session_start_params",
  SESSION_START_PAYLOAD = "session_start_payload",
  SESSION_START_TIME = "session_start_time",
}

export enum EventNames {
  CLIENT_SESSION = "client-session",
  MARKETING_CLIENT_PURCHASE = "marketing-client-purchase",
  MARKETING_CLIENT_VISIT = "marketing-client-visit",
  WEB_HLS_PLAYBACK = "web-hls-playback",
}

export const EVENT_SOURCE = "client";
