import { getProxycadorHost } from "src/features/customGift/imports/environment";
import { enhancedFetch } from "src/features/customGift/imports/utils";
import { GetCustomGiftsResponse } from "./customGiftTypes";

export const fetchCustomGifts = (): Promise<GetCustomGiftsResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/custom-gift-generator/public/custom-gifts`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    }
  ).then((resp) => resp.json());
