import { CHAT_EVENTS_TYPES, REACTION } from "chat/enums";
import { ContentType } from "chat/premiumMessage/types";
import { ChatMessageSentFlags, MessageType } from "chat/types";

export const CHAT_MAX_TEXT_LENGTH = 3000;
export const CHAT_OLDER_MESSAGES_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_PAGE_SIZE = 20;
export const CHAT_CONVERSATIONS_LIST_DEFAULT_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_LIST_REFRESH_MESSAGES_LIMIT = 1;
export const CHAT_CONVERSATIONS_LIST_REACTIONS_LIMIT = 10;
export const CHAT_CONVERSATIONS_LIST_REACTIONS_START_TS = 0;

export const CHAT_SOURCE_CHAT_LIST = 2;
export const GIFT_SOURCE_PREMIUM_MESSAGE = "Premium Message";

export const ACME_READ_RECEIPT_IDENTIFICATOR = "tc:readreceipt";

export const ACME_READ_MESSAGE_EVENT_IDENTIFICATOR = "tc:readmessageevent";

export const ACME_CHAT_EVENT_IDENTIFICATOR = "tc:chatevents";

export const ACME_CHAT_REACTION_IDENTIFICATOR = "chat_reactions:reactions";
export const ACME_CHAT_SELFREACTION_IDENTIFICATOR =
  "chat_reactions:selfreactions";

export const ALLOWED_CHAT_EVENTS = [
  CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ALL,
  CHAT_EVENTS_TYPES.DELETE_MESSAGE_FOR_ME,
  CHAT_EVENTS_TYPES.EDIT_MESSAGE,
  CHAT_EVENTS_TYPES.CHAT_MUTED,
  CHAT_EVENTS_TYPES.CHAT_UNMUTED,
  CHAT_EVENTS_TYPES.DELETE_CHAT_FOR_ME,
];

export const MAX_PIN_CHATS_NUMBER = 10;

export const systemMessageTypes = [
  MessageType.GROUP_MEMBER_JOIN,
  MessageType.GROUP_MEMBER_LEAVE,
  MessageType.GROUP_NAME_CHANGE,
  MessageType.LIKE_MESSAGE,
  MessageType.SCREENSHOT_INFO_MESSAGE,
  MessageType.VIDEO_SAVED_INFO_MESSAGE,
  MessageType.PHOTO_SAVED_INFO_MESSAGE,
];

export const ignoredMessagesTypes = [MessageType.GROUP_UPDATE];

export const ChatPremiumMessageTypeToFlagMap = {
  [ContentType.PHOTO]: ChatMessageSentFlags.IMAGE,
  [ContentType.VIDEO]: ChatMessageSentFlags.VIDEO,
};

export const CHAT_EVENTS_DEFAULT_TIMESTAMP = 0;

export const POSITION = {
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  TOP: "TOP",
};

export const REACTIONS_LIST: Array<REACTION> = [
  REACTION.HEART,
  REACTION.FIRE,
  REACTION.LAUGH,
  REACTION.CRYING,
  REACTION.HANDS,
  REACTION.THUMB_UP,
];

export const CHAT_ANALYTICS_PARAMS_EMOJI_TARGET = "react_emoji";
export const CHAT_ANALYTICS_PARAMS_CLICK_ACTION = "click";
export const CHAT_ANALYTICS_PARAMS_CHAT_SOURCE_ID = "chat";
export const CHAT_ANALYTICS_PARAMS_RECIPIENT_ACCOUNT_ID = "account_id";

export const CHAT_DEFAULT_LIVE_BAR_LIMIT = 12;
export const CHAT_DEFAULT_MODERATION_LEVEL = 5;

export const MESSAGE_MENU_ANIMATION_DURATION = 300;
