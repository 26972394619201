import { parseBooleanEnabledSOC } from "src/features/gifts/imports/abTest";

export const getIsARGiftsEnabled = parseBooleanEnabledSOC(
  "web.ar.gifts.enabled",
  "0"
);

export const getIsSendGiftBIv2Enabled = parseBooleanEnabledSOC(
  "web.send.gift.bi.v2.enabled",
  "0"
);

export const getIsSendGiftOptimisticEnabled = parseBooleanEnabledSOC(
  "web.send.gift.optimistic.enabled",
  "0"
);
