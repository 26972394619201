import { TouchEvent, useCallback, useRef } from "react";

const useLongPressTouch = ({
  onLongPress,
  options: { delay = 300 } = {},
}: {
  onLongPress: (event: TouchEvent) => void;
  options?: {
    delay?: number;
  };
}) => {
  const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
  const onLongPressRef = useRef(onLongPress);
  onLongPressRef.current = onLongPress;

  const start = useCallback(
    (event) => {
      timerIdRef.current = setTimeout(() => {
        onLongPressRef.current(event);
      }, delay);

      window.addEventListener(
        "scroll",
        () => {
          clearTimeout(timerIdRef.current);
        },
        { once: true }
      );
    },
    [delay]
  );

  const clear = useCallback(() => {
    clearTimeout(timerIdRef.current);
  }, []);

  return {
    onTouchStart: start,
    onTouchEnd: clear,
  };
};

export default useLongPressTouch;
