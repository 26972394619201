import {
  Gift,
  Nullable,
  SpecialOfferType,
} from "src/features/mos/imports/types";

export enum MosItemType {
  AI_GIFT = "AI_GIFT",
  ALGORITHM_PERSONAL_GIFT = "ALGORITHM_PERSONAL_GIFT",
  ALGORITHM_TOP_GIFT_PREFERRED_BY_USER = "ALGORITHM_TOP_GIFT_PREFERRED_BY_USER",
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = "ALGORITHM_TOP_GIFT_TOP_SENT_GIFT",
  DEFAULT_GIFT = "DEFAULT_GIFT",
  FOLLOW_GIFT = "FOLLOW_GIFT",
  INVENTORY_GIFT = "INVENTORY_GIFT",
  OFFER_PLACEHOLDER = "OFFER_PLACEHOLDER",
  STREAM_GAME_GIFT_PLACEHOLDER = "STREAM_GAME_GIFT_PLACEHOLDER",
  TANGO_CARD_ITEM = "TANGO_CARD_ITEM",
  UNRECOGNIZED_MOS_ITEM_TYPE = "UNRECOGNIZED_MOS_ITEM_TYPE",
  USER_CUSTOM_GIFT = "USER_CUSTOM_GIFT",
}

export enum MosItemTypeAbbreviation {
  AI_GIFT = "AI",
  ALGORITHM_PERSONAL_GIFT = "PG",
  ALGORITHM_TOP_GIFT_PREFERRED_BY_USER = "GP",
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = "TG",
  DEFAULT_GIFT = "DG",
  FOLLOW_GIFT = "FG",
  INVENTORY_GIFT = "IN",
  OFFER_PLACEHOLDER = "OF",
  STREAM_GAME_GIFT_PLACEHOLDER = "GG",
  TANGO_CARD_ITEM = "TC",
  USER_CUSTOM_GIFT = "CG",
}

export enum GiftVariant {
  CHAT = "chat",
  STREAM = "stream",
}

export enum SupportedMosItemType {
  ALGORITHM_PERSONAL_GIFT = MosItemType.ALGORITHM_PERSONAL_GIFT,
  ALGORITHM_TOP_GIFT_TOP_SENT_GIFT = MosItemType.ALGORITHM_TOP_GIFT_TOP_SENT_GIFT,
  DEFAULT_GIFT = MosItemType.DEFAULT_GIFT,
}

export enum SupportedStreamMosItemType {
  ALGORITHM_TOP_GIFT_PREFERRED_BY_USER = MosItemType.ALGORITHM_TOP_GIFT_PREFERRED_BY_USER,
  FOLLOW_GIFT = MosItemType.FOLLOW_GIFT,
  INVENTORY_GIFT = MosItemType.INVENTORY_GIFT,
  OFFER_PLACEHOLDER = MosItemType.OFFER_PLACEHOLDER,
}

export const isSupportedStreamType = (type: MosItemType): boolean =>
  type in SupportedMosItemType || type in SupportedStreamMosItemType;

type MosGiftTypeAbbreviationTypes = Exclude<
  MosItemTypeAbbreviation,
  MosItemTypeAbbreviation.OFFER_PLACEHOLDER
>;

export interface GiftBalance {
  amount: number;
  gift: GiftId;
}

export type GiftId = string;

export interface MosData {
  giftRichModels?: Gift[];
  items: MosUiItem[];
  missedGiftsIds: GiftId[];
}

export interface MosUiGiftItem {
  content: {
    gift: Gift;
  };
  type: MosItemType;
  typeAbbreviation: MosGiftTypeAbbreviationTypes;
}

export interface MosUiOfferItem {
  content: {
    gift: SpecialOfferType;
  };
  type: MosItemType;
  typeAbbreviation: MosItemTypeAbbreviation.OFFER_PLACEHOLDER;
}

export type MosUiItem = MosUiGiftItem | MosUiOfferItem;

export interface GiftClickParams {
  id: Gift["id"];
  position: number;
  typeAbbreviation: MosItemTypeAbbreviation;
}

export interface MosItem {
  giftReference?: {
    encryptedGiftId: string;
  };
  giftRichModel?: {
    gift: Gift;
  };
  type: MosItemType;
  typeAbbreviation: MosGiftTypeAbbreviationTypes;
}

export interface MosState {
  error: Nullable<string>;
  items: MosItem[];
  lineupId: Nullable<string>;
  loading: boolean;
}

export interface FetchMosLineupParams {
  streamId: string;
  streamerId: string;
}

export interface FetchMosLineupResponse {
  items: MosItem[];
  lineupId: string;
}

export enum MosError {
  SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
}

export interface RejectError {
  message: string;
}

export interface MosLineupRequestRejectError {
  rejectValue: RejectError;
}

export interface FreeFollowGift {
  giftAnchorId: string;
  giftAnchorType: number;
  giftId: string;
}

export interface FreeFollowGiftParams {
  giftId: string;
  streamId: string;
}

export enum ResponseCode {
  FAILED_GENERAL = "FAILED_GENERAL",
  OK = "OK",
}

export interface FreeFollowGiftResponse {
  additionInPoint: number;
  code: ResponseCode;
  finalCredit: number;
  finalPoint: number;
}

export interface GiftAnalyticsParams {
  [key: string]: Nullable<string>;
}
