import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMosLineup } from "src/features/mos/api/mos";
import {
  FetchMosLineupParams,
  FetchMosLineupResponse,
  MosError,
  MosLineupRequestRejectError,
} from "src/features/mos/types";

export const getMosAction = createAsyncThunk<
  FetchMosLineupResponse,
  { timeoutMs: number } & FetchMosLineupParams,
  MosLineupRequestRejectError
>(
  "mos/get",
  async ({ streamId, streamerId, timeoutMs }, { rejectWithValue }) => {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeoutMs);

      const response = await Promise.race([
        fetchMosLineup({
          streamId,
          streamerId,
          signal: controller.signal,
        }),

        new Promise<FetchMosLineupResponse>((_resolve, reject) =>
          setTimeout(() => {
            reject(new Error(MosError.TIMEOUT_ERROR));
          }, timeoutMs)
        ),
      ]);

      clearTimeout(timeoutId);

      return response;
    } catch (error) {
      if (error instanceof Error) {
        const message = error.message || MosError.SOMETHING_WENT_WRONG;

        return rejectWithValue({ message });
      }

      // Fallback if error does not contain expected structure
      return rejectWithValue({ message: MosError.SOMETHING_WENT_WRONG });
    }
  }
);
