import { shallowEqual, useSelector } from "react-redux";
import { useBroadcastOBSEnabled } from "src/features/broadcastExternal/hooks/useBroadcastOBSEnabled";
import { StreamEvents } from "src/features/broadcastExternal/imports/enums";
import {
  broadcastSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "src/features/broadcastExternal/imports/state";
import { RootState } from "src/features/broadcastExternal/imports/types";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";

const selector = (state: RootState) => ({
  events: viewerSessionSelectors.getEvents(state),
  eventIds: viewerSessionSelectors.getEventIds(state),
  streamId: viewerSessionSelectors.getStreamId(state),
  broadcastId: broadcastSelectors.broadcastId(state),
  broadcastExternalStatus:
    broadcastExternalSelectors.getBroadcastExternalStatus(state),
  myAccountId: userSelectors.getMyAccountId(state),
});

export const useBroadcastEventIds = (): string[] => {
  const isBroadcastOBSEnabled = useBroadcastOBSEnabled();

  const {
    events,
    eventIds,
    streamId,
    broadcastId,
    broadcastExternalStatus,
    myAccountId,
  } = useSelector(selector, shallowEqual);

  const filteredEvents = eventIds.filter((eventId: string) => {
    const event = events[eventId];

    return event.type !== StreamEvents.MULTI_BROADCAST;
  });

  if (
    isBroadcastOBSEnabled &&
    streamId === broadcastId &&
    broadcastExternalStatus
  ) {
    return filteredEvents.filter((eventId: string) => {
      const event = events[eventId];
      const isBroadcasterJoinedEvent =
        event.accountId === myAccountId && event.type === StreamEvents.JOIN;

      return !isBroadcasterJoinedEvent;
    });
  }

  return filteredEvents;
};
