import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MediaPreview from "chat/components/mediaPreview/MediaPreview";
import { useRefreshGifts } from "chat/components/sendMediaView/useRefreshGifts";
import {
  VIDEO_CONVERSION_COMMAND,
  VIDEO_CONVERSION_STATUS,
} from "chat/constants/conversionWorker";
import { Spinner } from "chat/imports/components";
import {
  RootState,
  giftsCacheActionCreators as actionCreators,
  giftsCacheSelectors,
  userSelectors,
} from "chat/imports/state";
import { Nullable, VoidCallback } from "chat/imports/types";
import { logger } from "chat/imports/utils";
import { ContentType } from "chat/premiumMessage/types";
import PremiumMessageGifts from "chat/premiumMessage/ui/components/premiumMessageGifts/PremiumMessageGifts";
import { sendMediaMessage } from "chat/state/flows";
import { SendMediaData } from "chat/types";
import { convertToJpeg } from "chat/utils/convertToJpeg";

const IMAGE_EXTENSIONS = [".heic", ".heif"];
const VIDEO_EXTENSIONS = [".mov", ".hevc"];

type SendMediaViewProps = {
  data: SendMediaData;
  onDismiss: VoidCallback;
};

const defaultGiftsSelector = (state: RootState) => {
  const defaultGiftIds =
    giftsCacheSelectors.getDefaultPremiumMessageGiftIds(state);

  return giftsCacheSelectors.getGiftsByIds(state, defaultGiftIds);
};

const getMediaType = (file: File) =>
  file.type.startsWith("image") ? ContentType.PHOTO : ContentType.VIDEO;

const SendMediaView: FC<SendMediaViewProps> = ({
  data: { file, conversationId, analyticsParams },
  onDismiss,
}) => {
  useRefreshGifts();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const defaultGifts = useSelector(defaultGiftsSelector, shallowEqual);
  const accountId = useSelector(userSelectors.getMyAccountId);
  const [caption, setCaption] = useState("");
  const [selectedGiftId, setSelectedGiftId] = useState("");
  const [showAllGifts, setShowAllGifts] = useState(false);
  const [mediaPreviewUrl, setMediaPreviewUrl] = useState("");

  const [convertorLoaded, setConvertorLoaded] = useState(false);
  const [convertedFile, setConvertedFile] = useState<Nullable<File>>(null);

  const workerRef = useRef<Nullable<Worker>>(null);
  const mediaType = getMediaType(file);

  const isImageConversionNeeded = IMAGE_EXTENSIONS.some((extension) =>
    file.name.endsWith(extension)
  );
  const isVideoConversionNeeded = VIDEO_EXTENSIONS.some((extension) =>
    file.name.toLowerCase().endsWith(extension)
  );

  useEffect(() => {
    workerRef.current = new Worker(
      new URL("chat/workers/ffmpegWorker.ts", import.meta.url)
    );
    workerRef.current.postMessage({ command: VIDEO_CONVERSION_COMMAND.LOAD });

    return () => {
      workerRef.current?.terminate();
    };
  }, []);

  useEffect(() => {
    if (convertorLoaded && workerRef.current && isVideoConversionNeeded) {
      workerRef.current.postMessage({
        command: VIDEO_CONVERSION_COMMAND.CONVERT,
        file,
      });
    }
  }, [convertorLoaded, file, isVideoConversionNeeded]);

  useEffect(() => {
    const handleWorkerMessage = (event: MessageEvent) => {
      const { status, data, error } = event.data;

      if (status === VIDEO_CONVERSION_STATUS.CONVERSION_COMPLETE) {
        const blob = new Blob([data], { type: "video/mp4" });
        setConvertedFile(blob as File);
        setMediaPreviewUrl(URL.createObjectURL(blob));
      } else if (status === VIDEO_CONVERSION_STATUS.CONVERSION_FAILED) {
        logger.error(`Conversion failed: ${error}`);
      } else if (status === VIDEO_CONVERSION_STATUS.FFMPEG_LOADED) {
        setConvertorLoaded(true);
      }
    };

    workerRef.current?.addEventListener("message", handleWorkerMessage);

    return () => {
      workerRef.current?.removeEventListener("message", handleWorkerMessage);
    };
  }, []);

  useEffect(() => {
    if (isImageConversionNeeded) {
      convertToJpeg(file)
        .then(setConvertedFile)
        .catch((err) => logger.error(err.message));
      setMediaPreviewUrl(URL.createObjectURL(file));
    } else if (!isVideoConversionNeeded) {
      setMediaPreviewUrl(URL.createObjectURL(file));
    }
  }, [file, isImageConversionNeeded, isVideoConversionNeeded]);

  const handleSend = async () => {
    if (file) {
      dispatch(
        sendMediaMessage({
          conversationId,
          mediaFile: convertedFile || file,
          mediaType,
          mediaUrl: mediaPreviewUrl,
          caption: caption.trim(),
          from: accountId,
          formatMessage,
          giftId: selectedGiftId,
          analyticsParams,
        })
      );
      onDismiss();
    }
  };

  const handleChangeCaption = (caption: string) => {
    setCaption(caption);
  };

  const handleSelectGift = (giftId: string) => {
    setSelectedGiftId((prevId) => (giftId === prevId ? "" : giftId));
  };

  const handleSelectAndSaveGift = (giftId: string) => {
    dispatch(actionCreators.selectPremiumMessageGift(giftId));
    setSelectedGiftId(giftId);
    setShowAllGifts(false);
  };

  if (showAllGifts) {
    return (
      <PremiumMessageGifts
        onClickBack={() => setShowAllGifts(false)}
        onSelectGift={handleSelectAndSaveGift}
      />
    );
  }

  return mediaPreviewUrl ? (
    <MediaPreview
      gifts={defaultGifts}
      mediaUrl={mediaPreviewUrl}
      mediaType={mediaType}
      caption={caption}
      selectedGiftId={selectedGiftId}
      onSelectGift={handleSelectGift}
      onSend={handleSend}
      onChangeCaption={handleChangeCaption}
      onClose={onDismiss}
      onClickMore={() => setShowAllGifts(true)}
      conversationId={conversationId}
    />
  ) : (
    <Spinner />
  );
};

export default SendMediaView;
