import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { loadPointsInfo } from "src/state/actionCreators/points";
import { PointsResponse } from "src/types/points";

export interface PointsState {
  data: PointsResponse | null;
  error: null | string;
  loading: boolean;
}

const initialState: PointsState = {
  data: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "pointsUserInfo",
  initialState,
  reducers: {
    setBalanceAction(state, action: PayloadAction<PointsResponse>) {
      state.data = action.payload;
      state.loading = false;
    },
    setFailedBalanceAction(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPointsInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadPointsInfo.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.loading = false;
      })
      .addCase(loadPointsInfo.rejected, (state, { payload }) => {
        state.error = payload as string;
        state.loading = false;
      });
  },
});

export const { setBalanceAction, setFailedBalanceAction } = slice.actions;

export const pointsUserInfo = slice.reducer;
