import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  RootState,
  actionCreators,
  getIsCustomGiftEnabled,
  getIsOfferInMosEnabled,
  getMosV2FallbackGifts,
  giftingRequestsSelectors,
  giftsCacheSelectors,
  giftsFetchingTimeout,
  loadGiftsBatch,
  mosSelectors,
  specialOffersSelectors,
} from "src/features/mos/imports/state";
import { Gift } from "src/features/mos/imports/types";
import { getMosAction } from "src/features/mos/state/getMosAction";
import {
  GiftId,
  MosData,
  MosItemType,
  MosItemTypeAbbreviation,
  MosUiItem,
  isSupportedStreamType,
} from "src/features/mos/types";

const getMosData = (state: RootState) => {
  const mosItems = mosSelectors.getMosItems(state);
  const mosError = mosSelectors.getMosError(state);
  const mosFallbackGiftsIds = getMosV2FallbackGifts(state);
  const isOfferEnabled = getIsOfferInMosEnabled(state);

  const specialOffersList = specialOffersSelectors.getLastActiveSpecialOffer(
    state,
    ["MOS"]
  );

  const items: MosUiItem[] = [];
  const giftRichModels: Gift[] = [];
  const missedGiftsIds: GiftId[] = [];

  mosItems.forEach((mosItem) => {
    const gift = mosItem?.giftRichModel?.gift;

    if (
      mosItem.type === MosItemType.OFFER_PLACEHOLDER &&
      specialOffersList &&
      isOfferEnabled
    ) {
      const {
        pricePoints: [offer],
        expirationDateMs: ts,
        sasTemplate,
        campaignId,
      } = specialOffersList;

      if (!offer) {
        return;
      }

      const specialOffer = {
        ...(offer || {}),
        ts,
        sasPricePoint: offer?.sasPricePoint,
        sasTemplate,
        campaignId,
      };

      items.push({
        content: { gift: specialOffer },
        type: mosItem.type,
        typeAbbreviation: mosItem.typeAbbreviation,
      });

      return;
    }

    if (gift) {
      const giftId = gift?.encryptedGiftId || gift.id;

      items.push({
        content: { gift: { ...gift, id: giftId } },
        type: mosItem.type,
        typeAbbreviation: mosItem.typeAbbreviation,
      });

      const isGiftInCache = Boolean(
        giftsCacheSelectors.getGiftById(state, giftId)
      );

      if (!isGiftInCache) {
        giftRichModels.push({ ...gift, id: giftId });
      }

      return;
    }

    const giftId = mosItem?.giftReference?.encryptedGiftId;

    if (giftId) {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: mosItem.type,
          typeAbbreviation: mosItem.typeAbbreviation,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    }
  });

  if (mosError) {
    mosFallbackGiftsIds.forEach((giftId: string) => {
      const gift = giftsCacheSelectors.getGiftById(state, giftId);

      if (gift) {
        items.push({
          content: { gift },
          type: MosItemType.DEFAULT_GIFT,
          typeAbbreviation: MosItemTypeAbbreviation.DEFAULT_GIFT,
        });
      } else {
        missedGiftsIds.push(giftId);
      }
    });
  }

  return { items, missedGiftsIds, giftRichModels };
};

const mosItemsSelector = (state: RootState): MosData => {
  const { items, missedGiftsIds, giftRichModels } = getMosData(state);

  const isCustomGiftsEnabled = getIsCustomGiftEnabled(state);

  return {
    items: items.filter(
      ({ type }) =>
        isSupportedStreamType(type) ||
        (isCustomGiftsEnabled && type === MosItemType.USER_CUSTOM_GIFT)
    ),
    missedGiftsIds,
    giftRichModels,
  };
};

const useLoaderForMissedGifts = (missedGiftsIds: GiftId[]): void => {
  const dispatch = useDispatch();
  const isLoading = useSelector(mosSelectors.getMosLoading);

  useEffect(() => {
    if (missedGiftsIds.length && !isLoading) {
      dispatch(loadGiftsBatch({ giftIds: missedGiftsIds, ignoreCache: true }));
    }
  }, [dispatch, missedGiftsIds, isLoading]);
};

const streamSelector = (state: RootState) => ({
  streamerId: giftingRequestsSelectors.getRecipientAccountId(state),
  streamId: giftingRequestsSelectors.getSecondaryRecipientId(state),
});

const useFetchForMosLineup = (): void => {
  const { streamerId, streamId } = useSelector(streamSelector);
  const dispatch = useDispatch();

  const timeoutDuration = useSelector(
    (state: RootState) => giftsFetchingTimeout(state) * 1000
  );

  useEffect(() => {
    if (streamerId && streamId) {
      dispatch(
        getMosAction({ streamId, streamerId, timeoutMs: timeoutDuration })
      );
    }
  }, [dispatch, streamId, streamerId, timeoutDuration]);
};

const useSetRichModelGiftInCache = (giftRichModels?: Gift[]): void => {
  const dispatch = useDispatch();

  if (giftRichModels?.length) {
    dispatch(actionCreators.addGifts(giftRichModels));
  }
};

export const useMosItems = (): MosUiItem[] => {
  const { items, missedGiftsIds, giftRichModels } = useSelector(
    mosItemsSelector,
    shallowEqual
  );

  useSetRichModelGiftInCache(giftRichModels);

  useLoaderForMissedGifts(missedGiftsIds);

  useFetchForMosLineup();

  return items;
};
