import React, { FC, useCallback, useContext, useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { MosGiftTypeLabel } from "src/features/mos/components/MosGiftDebugLabel/MosGiftTypeLabel";
import {
  useBreakpointMobileLayout,
  useBreakpointPrecise,
  useMakeAlternativeDomainUrl,
} from "src/features/mos/imports/hooks";
import {
  RootState,
  actionCreators,
  followCurrentBroadcaster,
  followingListSelectors,
  getFreeFollowGiftId,
  getGiftsAlternativeDomainContentSupportEnabled,
  getIsDebugMosGiftTypeEnabled,
  sendGift,
  sendGiftSchedule,
  userSelectors,
  viewerSessionSelectors,
} from "src/features/mos/imports/state";
import {
  CoinIconSize,
  FollowSource,
  GIFT_ORIGIN,
  Gift,
  RealTimeRecommendationsGiftFromType,
} from "src/features/mos/imports/types";
import {
  GiftCoinsView,
  GiftPriceContext,
  GiftRendererContext,
} from "src/features/mos/imports/ui";
import { removeItemsByType } from "src/features/mos/state/mosSlice";
import {
  GiftAnalyticsParams,
  GiftBalance,
  GiftClickParams,
  GiftVariant,
  MosItemType,
  MosItemTypeAbbreviation,
} from "src/features/mos/types";
import styles from "./MosGift.scss";

interface MosGift {
  gift: Gift;
  handleGiftClick?: (giftParams: GiftClickParams) => GiftAnalyticsParams;
  position: number;
  typeAbbreviation: MosItemTypeAbbreviation;
  variant: GiftVariant;
}

const coinSize = {
  [GiftVariant.CHAT]: CoinIconSize.MEDIUM,
  [GiftVariant.STREAM]: CoinIconSize.SMALL,
};

const GIFT_SPECIAL_MEDIA = "MEDIA";

const giftSelector = (giftId: string) => (state: RootState) => {
  const streamerId = viewerSessionSelectors.getBroadcasterId(state);

  return {
    isFreeFollowGift: getFreeFollowGiftId(state) === giftId,
    isFollowed: followingListSelectors.getIsFollowingByAccountId(
      state,
      streamerId
    ),
  };
};

const selector = (state: RootState) => ({
  isDebugEnabled: getIsDebugMosGiftTypeEnabled(state),
  giftsBalance: userSelectors.getGiftsBalance(state),
});

export const MosGift: FC<MosGift> = ({
  gift,
  typeAbbreviation,
  handleGiftClick,
  position,
  variant,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const breakpoint = useBreakpointPrecise();
  const isMobile = useBreakpointMobileLayout();
  const { isDebugEnabled, giftsBalance } = useSelector(selector);
  const { isFreeFollowGift, isFollowed } = useSelector(
    giftSelector(gift.id),
    shallowEqual
  );
  const isProcessingRef = useRef(false);

  const giftBalance = useMemo(() => {
    if (!gift?.id) {
      return 0;
    }

    return giftsBalance.find(
      (balance: GiftBalance) => balance.gift === gift?.id
    )?.amount;
  }, [gift?.id, giftsBalance]);

  const giftPriceSettings = useContext(GiftPriceContext);
  const { giftGetRenderPosition, giftSetRenderPosition } =
    useContext(GiftRendererContext);

  const coinsViewGift = {
    priceInCredit: gift.priceInCredit,
    withdrawInPoint: gift.withdrawInPoint || 0,
  };

  const handleGiftClickSuccess = () => {
    dispatch(followCurrentBroadcaster(FollowSource.AUTO_FOLLOW_GIFT));
    dispatch(removeItemsByType(MosItemType.FOLLOW_GIFT));
    isProcessingRef.current = false;
  };

  const onGiftClick = useCallback(() => {
    if (isProcessingRef.current) {
      return;
    }

    if (isFreeFollowGift) {
      isProcessingRef.current = true;
    }

    const giftAnalyticsParams = handleGiftClick?.({
      id: gift.id,
      typeAbbreviation,
      position,
    });

    batch(() => {
      if (variant === GiftVariant.STREAM) {
        dispatch(
          actionCreators.setOptionLastClickedGift({
            giftId: gift.id,
            positionIndex: giftGetRenderPosition(gift.id),
          })
        );
      }
      sendGiftSchedule(() =>
        dispatch(
          // @ts-ignore
          sendGift({
            giftId: gift.id,
            isMobile,
            giftSelectionOrigin: GIFT_ORIGIN.MOS,
            extendedAnalyticParams: giftAnalyticsParams,
            realTimeGiftFromType:
              RealTimeRecommendationsGiftFromType.GIFT_MOODS,
            ...(isFreeFollowGift && {
              onSuccess: handleGiftClickSuccess,
              onError: () => {
                isProcessingRef.current = false;
              },
            }),
            formatMessage,
          })
        )
      );
    });
  }, [
    gift,
    typeAbbreviation,
    position,
    handleGiftClick,
    isFreeFollowGift,
    formatMessage,
  ]);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  if (
    !gift ||
    gift?.special === GIFT_SPECIAL_MEDIA ||
    (isFreeFollowGift && isFollowed)
  ) {
    return null;
  }

  giftSetRenderPosition(gift?.id);

  return (
    <div
      className={classnames(styles[variant], styles[breakpoint], styles.gift)}
      data-testid={`mos-item-${typeAbbreviation}-${gift.id}`}
      onClick={onGiftClick}
      role="button"
      tabIndex={isProcessingRef.current ? -1 : 0}
    >
      {isDebugEnabled && (
        <MosGiftTypeLabel
          typeAbbreviation={typeAbbreviation}
          variant={variant}
        />
      )}
      <img
        src={makeAlternativeDomainUrl(gift.icon)}
        className={styles.giftIcon}
        alt="mos"
      />
      <span className={styles.price}>
        <GiftCoinsView
          settingsPrice={giftPriceSettings}
          gift={coinsViewGift}
          giftBalance={giftBalance}
          isFreeFollowGift={isFreeFollowGift}
          coinSize={coinSize[variant]}
          showBalance
        />
      </span>
    </div>
  );
};
