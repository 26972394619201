import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { Nullable } from "src/types/common";
import {
  SocialGamesAnalyticsItemTypes,
  SocialGamesResponseCode,
} from "src/features/socialGames/common/enums";
import {
  GroupedGames,
  SocialGamesResponseStateAction,
  SocialGamesSection,
} from "src/features/socialGames/common/types";

interface SearchState {
  searchValue: string;
  searchVisibility: boolean;
}

interface VisibleGame {
  id: string;
  itemType: string;
}

interface AlreadySentToAnalyticsAction {
  games: Array<string>;
  type: SocialGamesAnalyticsItemTypes;
}

export interface SocialGamesLobbyState {
  alreadySentToAnalyticsGamesIds: GroupedGames;
  error: Nullable<string>;
  loading: boolean;
  recentlyPlayedSectionUpdated: boolean;
  responseCode: Nullable<SocialGamesResponseCode>;
  search: SearchState;
  sections: SocialGamesSection[];
  visibleGamesIds: Array<VisibleGame>;
}

const initialState: SocialGamesLobbyState = {
  responseCode: null,
  sections: [],
  loading: false,
  error: null,
  visibleGamesIds: [],
  alreadySentToAnalyticsGamesIds: {},
  recentlyPlayedSectionUpdated: false,
  search: {
    searchVisibility: false,
    searchValue: "",
  },
};

const slice = createSlice({
  name: "socialGamesLobby",
  initialState,
  reducers: {
    loadSocialGamesListStart(state) {
      state.loading = true;
      state.responseCode = null;
      state.error = null;
    },
    loadSocialGamesListComplete(
      state,
      action: PayloadAction<SocialGamesResponseStateAction>
    ) {
      state.loading = false;
      state.responseCode = action.payload.response.responseCode;

      if (action.payload.response.sectionGames && !state.sections.length) {
        state.sections = action.payload.response.sectionGames;

        return;
      }

      if (
        action.payload.response.sectionGames &&
        action.payload.requestedSections.length
      ) {
        const filtered = state.sections.filter(
          (game) => !action.payload.requestedSections.includes(game.section)
        );
        state.sections = filtered.concat(action.payload.response.sectionGames);
      }
    },
    setRecentlyPlayedGamesStatus(state, action: PayloadAction<boolean>) {
      state.recentlyPlayedSectionUpdated = action.payload;
    },
    loadSocialGamesListFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addGameToVisibleGames(state, action: PayloadAction<VisibleGame>) {
      state.visibleGamesIds.push(action.payload);
    },
    addGamesToAlreadySentToAnalytics(
      state,
      action: PayloadAction<AlreadySentToAnalyticsAction>
    ) {
      if (state.alreadySentToAnalyticsGamesIds[action.payload.type]) {
        state.alreadySentToAnalyticsGamesIds[action.payload.type] =
          state.alreadySentToAnalyticsGamesIds[action.payload.type].concat(
            action.payload.games
          );
      } else {
        state.alreadySentToAnalyticsGamesIds[action.payload.type] =
          action.payload.games;
      }
    },
    resetSocialGamesAnalytics(state) {
      state.alreadySentToAnalyticsGamesIds = {};
      state.visibleGamesIds = [];
    },
    setSearchVisible(state) {
      state.search.searchVisibility = true;
    },
    setSearchInvisible(state) {
      state.search.searchVisibility = false;
    },
    setSearchValue(state, action: PayloadAction<string>) {
      state.search.searchValue = action.payload;
    },
  },
});

export const {
  loadSocialGamesListStart,
  loadSocialGamesListComplete,
  loadSocialGamesListFailure,
  addGameToVisibleGames,
  resetSocialGamesAnalytics,
  setSearchVisible,
  setSearchInvisible,
  setSearchValue,
  addGamesToAlreadySentToAnalytics,
  setRecentlyPlayedGamesStatus,
} = slice.actions;

export const socialGamesLobbyReducer = slice.reducer;
