import { BroadcastDesktopLobbyType } from "src/features/broadcastLobby/common/enums";
import { broadcastLobbyMessages } from "src/features/broadcastLobby/common/messages";

export const MEDIA_DEVICES_PERMISSION_SHOWN_KEY =
  "media_devices_permission_shown";

export const STREAM_TYPE_MAP = {
  [BroadcastDesktopLobbyType.Regular]: {
    label: broadcastLobbyMessages.regularStreamTitle,
    value: BroadcastDesktopLobbyType.Regular,
  },
  [BroadcastDesktopLobbyType.OBS]: {
    label: broadcastLobbyMessages.obsStreamTitle,
    value: BroadcastDesktopLobbyType.OBS,
  },
};

export const STREAM_TYPE_OPTIONS = Object.values(STREAM_TYPE_MAP);
