import { ClientEventsRequest } from "generated/proto/EventGateway";
import {
  EVENT_SOURCE,
  EventNames,
} from "src/core/analytics/dataMesh/common/enums";
import dataMesh from "src/core/analytics/dataMesh/dataMeshModule";
import { getAccountId } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";
import { getEnvironmentName } from "src/core/analytics/imports/environment";
import { DataMeshEventPayload } from "src/core/analytics/types";

interface TransformedEvent {
  accountId: string;
  name: string;
  parameters: Record<string, unknown>;
  payload: Uint8Array;
}

const transformEvents = (
  events: Array<DataMeshEventPayload>,
  accountId: string
): Array<TransformedEvent> =>
  events.map((event) => ({
    name: event.eventName,
    payload: event.payload,
    parameters: {
      uid: event.eventUid,
      clientSessionId: event.clientSessionId,
      env: getEnvironmentName(),
      eventTime: event.timestamp,
      eventSource: EVENT_SOURCE,
      screenName: "",
      screenState: "",
      ...event.params,
    },
    accountId,
  }));

export const sendEventUtil = (
  events: Array<DataMeshEventPayload>,
  isBufferFlushNeeded = false
) => {
  const accountId = getAccountId() || "";

  const body = ClientEventsRequest.encode({
    events: transformEvents(events, accountId),
  }).finish();

  dataMesh.then((analytics) => {
    analytics.track(EventNames.CLIENT_SESSION, { body, isBufferFlushNeeded });
  });
};
