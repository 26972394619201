export enum BroadcastType {
  PARTY = "party",
  PREMIUM = "premium",
  PUBLIC = "public",
}

export enum BroadcastDesktopLobbyType {
  OBS = "obs",
  Regular = "regular",
}

export enum BroadcastDesktopLobbySettingsAreaViewType {
  Default = "default",
  Gifts = "gifts",
  StreamSettings = "streamSettings",
}
