import { CustomGiftStatus } from "src/features/customGift/api/customGiftTypes";
import { RootState } from "src/features/customGift/imports/state";

export const customGiftSelectors = {
  getCustomGifts: (state: RootState) => state.customGift.customGifts,
  getCustomGiftsByStatus: (state: RootState, statuses: CustomGiftStatus[]) =>
    state.customGift.customGifts.filter(
      (gift) => gift.status && statuses.includes(gift.status)
    ),
  getCustomGiftByIdAsGift: (
    state: RootState,
    giftId: string,
    statuses: CustomGiftStatus[]
  ) => {
    const customGift = customGiftSelectors
      .getCustomGiftsByStatus(state, statuses)
      .find((gift) => gift.encryptedGiftId === giftId);

    if (customGift) {
      return {
        id: customGift.encryptedGiftId,
        priceInCredit: customGift.priceInCredits,
      };
    }
  },
};
