import React, { memo, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { REACTION } from "chat/enums";
import { LottieAnimation, Typography } from "chat/imports/components";
import {
  CustomTypographyType,
  HeadlineTypographyType,
} from "chat/imports/constants";
import { useMakeAlternativeDomainUrl } from "chat/imports/hooks";
import { getIsMessageAnimationEnabled } from "chat/soc/chatSoc";
import { disableEventBubbling } from "chat/utils/disableEventBubbling";
import { getLottieAlternativeDomainEnabled } from "state/abTests";
import styles from "./MessageReactions.scss";

const lottieReactionMap = {
  [REACTION.CRYING]: "Reaction_Cry_opt",
  [REACTION.FIRE]: "Reaction_Fire_opt",
  [REACTION.HANDS]: "Reaction_Pray_opt",
  [REACTION.HEART]: "Reaction_Heart_opt",
  [REACTION.LAUGH]: "Reaction_Laugh_opt",
  [REACTION.THUMB_UP]: "Reaction_Like_opt",
};

const getLottieReactionConfig = (reaction: REACTION) => {
  const reactionPath =
    lottieReactionMap[reaction] || lottieReactionMap[REACTION.THUMB_UP];

  return {
    path: `https://resources.tango.me/lottie_animations/reactions/${reactionPath}.json`,
    loop: false,
    renderer: "canvas",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
    autoplay: true,
  } as const;
};

interface MessageReactionsProps {
  handleLottieComplete: () => void;
  isMessageHasSingleReaction: boolean;
  isMyReaction: boolean;
  isShowAnimationId: boolean;
  onReactionClickHandler: (
    reaction: REACTION
  ) => (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>
  ) => void;
  reactionCount: number;
  reactionId: REACTION;
}

export const MessageReaction = memo(
  ({
    handleLottieComplete,
    onReactionClickHandler,
    reactionId,
    reactionCount,
    isMyReaction,
    isShowAnimationId,
    isMessageHasSingleReaction,
  }: MessageReactionsProps) => {
    const lottieConfig = getLottieReactionConfig(reactionId);
    const isMessageAnimation = useSelector(getIsMessageAnimationEnabled);

    const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
      getLottieAlternativeDomainEnabled
    );
    const changedLottieConfig = useMemo(
      () => ({
        ...lottieConfig,
        path: makeAlternativeDomainUrl(lottieConfig.path),
      }),
      []
    );

    return (
      <div
        className={classnames(styles.reaction, {
          [styles.myReaction]: isMyReaction,
        })}
        key={reactionId}
        onClick={onReactionClickHandler(reactionId)}
        onTouchStart={onReactionClickHandler(reactionId)}
        onMouseDown={disableEventBubbling}
      >
        <Typography type={HeadlineTypographyType.HEADLINE4}>
          {reactionId}
        </Typography>
        {isMessageAnimation && isShowAnimationId && (
          <LottieAnimation
            lottieConfig={changedLottieConfig}
            className={classnames(styles.lottie, {
              [styles.lottieFire]: reactionId === REACTION.FIRE,
            })}
            onComplete={handleLottieComplete}
          />
        )}
        {!isMessageHasSingleReaction && reactionCount > 1 && (
          <Typography
            className={styles.reactionCount}
            type={CustomTypographyType.MINI}
          >
            <FormattedNumber
              value={reactionCount}
              notation={reactionCount >= 1000 ? "compact" : undefined}
            />
          </Typography>
        )}
      </div>
    );
  }
);

MessageReaction.displayName = "MessageReaction";
