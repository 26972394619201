import { getProxycadorHost } from "environment";
import { PointsResponse } from "src/types/points";
import fetch from "./utils/enhancedFetch";

export const loadPoints = (params: {
  accountId: number;
  userName: string;
}): Promise<PointsResponse> =>
  fetch(`${getProxycadorHost()}/stream/social/v1/point/${params.userName}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "X-TangoAuth-AccountId": `${params.accountId}`,
    },
  }).then((resp) => resp.json());
