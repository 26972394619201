import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  CHAT_DEFAULT_LIVE_BAR_LIMIT,
  CHAT_DEFAULT_MODERATION_LEVEL,
} from "chat/constants";
import { fetchLiveBarRequest } from "chat/liveBar/state/asyncAction";

const LiveBar: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchLiveBarRequest({
        refresh: true,
        limit: CHAT_DEFAULT_LIVE_BAR_LIMIT,
        moderation_level: CHAT_DEFAULT_MODERATION_LEVEL,
        accessToPremium: true,
      })
    );
  }, [dispatch]);

  return <div />;
};

export default memo(LiveBar);
