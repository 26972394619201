export enum AnimatedMessagesAnchor {
  FOOTER = "FOOTER",
  HEADER = "HEADER",
  MESSAGE = "MESSAGE",
}
interface MessageAnimatedMenuOffsetParams {
  conversationListContainerHeight: number;
  footerTop: number;
  headerHeight: number;
  menuHeight: number;
  messageBottomCoordinate: number;
  messageHeight: number;
  messageTopCoordinate: number;
  reactionsHeight: number;
}

export interface MessageAnimatedOffsetData {
  menuAnchor: AnimatedMessagesAnchor.FOOTER | AnimatedMessagesAnchor.MESSAGE;
  menuOffset: number;
  messagesOffset: number;
  reactionsAnchor:
    | AnimatedMessagesAnchor.HEADER
    | AnimatedMessagesAnchor.MESSAGE;
  reactionsOffset: number;
}

export const getMessageAnimatedMenuOffset = ({
  messageHeight,
  messageTopCoordinate,
  reactionsHeight,
  headerHeight,
  menuHeight,
  footerTop,
  messageBottomCoordinate,
}: MessageAnimatedMenuOffsetParams): MessageAnimatedOffsetData => {
  const reactionsHeightAndGaps = reactionsHeight + 8;
  const menuHeightAndGaps = menuHeight + 8;
  const messageHeaderDiff = messageTopCoordinate - headerHeight;
  const messageFooterDiff = footerTop - messageBottomCoordinate;

  const scrollableAreaHeight = footerTop - headerHeight;

  if (
    messageHeight + reactionsHeightAndGaps + menuHeightAndGaps >
    scrollableAreaHeight
  ) {
    if (messageHeaderDiff > reactionsHeightAndGaps) {
      return {
        menuOffset: -menuHeightAndGaps - 4,
        reactionsOffset: reactionsHeightAndGaps - messageHeaderDiff,
        messagesOffset: reactionsHeightAndGaps - messageHeaderDiff,
        reactionsAnchor: AnimatedMessagesAnchor.MESSAGE,
        menuAnchor: AnimatedMessagesAnchor.FOOTER,
      };
    }

    if (messageFooterDiff > menuHeightAndGaps) {
      return {
        menuOffset: messageFooterDiff - menuHeightAndGaps,
        reactionsOffset: reactionsHeightAndGaps + 20,
        messagesOffset: messageFooterDiff - menuHeightAndGaps,
        reactionsAnchor: AnimatedMessagesAnchor.HEADER,
        menuAnchor: AnimatedMessagesAnchor.MESSAGE,
      };
    }

    return {
      menuOffset: -menuHeightAndGaps - 4,
      reactionsOffset: reactionsHeightAndGaps + 20,
      messagesOffset: 0,
      reactionsAnchor: AnimatedMessagesAnchor.HEADER,
      menuAnchor: AnimatedMessagesAnchor.FOOTER,
    };
  }

  // when not enough space from top
  if (
    messageHeaderDiff < reactionsHeightAndGaps &&
    messageFooterDiff > menuHeightAndGaps
  ) {
    const offset = reactionsHeightAndGaps - messageHeaderDiff;

    return {
      menuOffset: offset,
      reactionsOffset: offset,
      messagesOffset: offset,
      menuAnchor: AnimatedMessagesAnchor.MESSAGE,
      reactionsAnchor: AnimatedMessagesAnchor.MESSAGE,
    };
  }

  if (
    messageHeaderDiff > reactionsHeightAndGaps &&
    messageFooterDiff < menuHeightAndGaps
  ) {
    const offset = messageFooterDiff - menuHeightAndGaps;

    return {
      menuOffset: offset,
      reactionsOffset: offset,
      messagesOffset: offset,
      menuAnchor: AnimatedMessagesAnchor.MESSAGE,
      reactionsAnchor: AnimatedMessagesAnchor.MESSAGE,
    };
  }

  return {
    menuOffset: 0,
    reactionsOffset: 0,
    messagesOffset: 0,
    menuAnchor: AnimatedMessagesAnchor.MESSAGE,
    reactionsAnchor: AnimatedMessagesAnchor.MESSAGE,
  };
};
