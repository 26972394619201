import { parseBooleanEnabledSOC } from "src/features/giftsDrawer/imports/abTests";

export const getIsGiftsDrawerV2Enabled = parseBooleanEnabledSOC(
  "web.giftsDrawer.v2.enabled",
  "0"
);

export const getIsGiftingWithDiamondV2Enabled = parseBooleanEnabledSOC(
  "web.diamonds.gifting.enabled",
  "0"
);
