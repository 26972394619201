import React, {
  MutableRefObject,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  HEADER_DESKTOP_TOP_OFFSET,
  HEADER_MOBILE_TOP_OFFSET,
} from "chat/components/currentConversation/constants";
import {
  ConversationMessagesAnimatedMenuContext,
  ConversationMessagesAnimatedMenuContextData,
} from "chat/context/ConversatioMessagesAnimatedMenuContext";
import { useBreakpointPrecise } from "chat/imports/hooks";
import { Breakpoints } from "src/enums";

interface ConversationMessagesMenuContextProviderProps {
  children: React.ReactNode;
}
export const ConversationMessagesMenuContextProvider = forwardRef(
  (
    { children }: ConversationMessagesMenuContextProviderProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const breakpoint = useBreakpointPrecise();
    const [containerSize, setContainerSize] =
      useState<ConversationMessagesAnimatedMenuContextData>({
        top: 0,
        left: 0,
        right: 0,
        width: 0,
        height: 0,
        headerHeight: 0,
      });

    useEffect(() => {
      const current = (ref as MutableRefObject<HTMLDivElement>).current;
      if ((ref as MutableRefObject<HTMLDivElement>).current) {
        const containerRect = current.getBoundingClientRect();

        setContainerSize({
          top: containerRect.top,
          left: containerRect.left,
          width: containerRect.width,
          height: containerRect.height,
          right: containerRect.right,
          headerHeight:
            breakpoint === Breakpoints.DESKTOP
              ? HEADER_DESKTOP_TOP_OFFSET
              : HEADER_MOBILE_TOP_OFFSET,
        });
      }
    }, [ref, breakpoint]);

    return (
      <ConversationMessagesAnimatedMenuContext.Provider value={containerSize}>
        {children}
      </ConversationMessagesAnimatedMenuContext.Provider>
    );
  }
);
