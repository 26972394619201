import { defineMessages } from "react-intl";
import { getAppSettingsOptions } from "environment";
import { ONE_CLICK_GIFTING } from "src/constants";

export const NSFW_KEY = "nsfw";
export const PREMIUM_KEY = "accessToPremium";
export const FEED_KEY = "nsfw.allow.feed";
export const SEARCH_KEY = "nsfw.allow.search";
export const LEADERBOARD_KEY = "nsfw.allow.leaderboard";
export const BATTLE_DURATION_KEY = "competition.duration";
export const AI_ASSISTANCE_KEY = "chatsAIAssistant";

export const READ_RECEIPT_KEY = "sendReadReceipts";

export const [ENABLED, DISABLED] = ["1", "0"];

export const settingMessages = defineMessages({
  [NSFW_KEY]: {
    id: "settings.mobile.nsfw.title",
    defaultMessage: "Show NSFW content (18+)",
  },
  [PREMIUM_KEY]: {
    id: "settings.mobile.premium_access.title",
    defaultMessage: "Access to Premium streams",
  },
  [FEED_KEY]: {
    id: "settings.mobile.feed_access.title",
    defaultMessage: "Access to Feed",
  },
  [SEARCH_KEY]: {
    id: "settings.mobile.search_access.title",
    defaultMessage: "Access to Search",
  },
  [LEADERBOARD_KEY]: {
    id: "settings.mobile.leaderboard_access.title",
    defaultMessage: "Access to Leaderboard",
  },
  [ONE_CLICK_GIFTING]: {
    id: "modal.gift-confirmation.enable-1-click",
    defaultMessage: "Enable 1-click gifting",
  },
  [AI_ASSISTANCE_KEY]: {
    id: "chat_ai_assistant",
    defaultMessage: "AI Assistant",
  },
});

export const isSettingOptionAvailable = (option) => {
  const availableOptions = getAppSettingsOptions();

  return availableOptions.indexOf(option) > -1;
};
