export const HOME_PAGE_STRUCTURED_DATA = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Tango Live",
  url: "https://tango.me",
  logo: "https://tango.me/favicons/favicon-48x48.png",
  foundingDate: "2009",
  founder: {
    "@type": "Person",
    name: "Uri Raz",
  },
  employee: [
    {
      "@type": "Person",
      name: "Uri Raz",
      jobTitle: "CEO",
    },
  ],
  sameAs: [
    "https://x.com/tangome",
    "https://www.instagram.com/_tango_app",
    "https://www.youtube.com/@tangoapp",
    "https://www.tiktok.com/@tango.live",
    "https://www.linkedin.com/company/tango-me/",
    "https://en.wikipedia.org/wiki/Tango_Live",
  ],
};

export const FAQ_PAGE_STRUCTURED_DATA = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What types of videos can be uploaded on Tango's platform?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tango's platform allows various types of content as long as they abide by our Community Guidelines. Examples include original works, world travel, educational content, and creative pieces. However, we prohibit content that infringes on copyright or intellectual property laws, promotes hate speech, or violates personal privacy, as explained in our Community Guidelines.",
      },
    },
    {
      "@type": "Question",
      name: "How can I report inappropriate content?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "To report any inappropriate or offensive content, users can approach us via: support@tango.me. For users based in India, you can contact our grievance mechanism available on Tango's Terms of Use. Our team will review the report and take appropriate action.",
      },
    },
    {
      "@type": "Question",
      name: "Can I share personal information about others on Tango?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "No, sharing personal information about others on Tango's is prohibited without their consent. This includes sharing photos, videos, profiles, full names, addresses, phone numbers, or email addresses.",
      },
    },
    {
      "@type": "Question",
      name: "How can I protect my intellectual property rights on the platform?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tango respects and upholds intellectual property rights. Users can report any copyrighted content uploaded without permission through the email: copyright@tango.me. Our team will investigate and take necessary action.",
      },
    },
    {
      "@type": "Question",
      name: "Can I earn money from my content on the platform?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, users can monetize their content on Tango by joining the Artist Community.",
      },
    },
    {
      "@type": "Question",
      name: "How can I delete my account?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Users can delete their account at any time by going to account settings and selecting the option to delete. Tango will then delete all your content and account details. Tango reserves to right to retain any of your data that is necessary to comply with any applicable law or legal obligation.",
      },
    },
  ],
};

export const FAQ_TR_PAGE_STRUCTURED_DATA = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "Tango'nun platformuna hangi tür videolar yüklenebilir?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tango'nun platformu, Topluluk Kurallarımıza uydukları sürece çeşitli içerik türlerine izin verir. Örnekler arasında orijinal çalışmalar, dünya seyahati, eğitim içeriği ve yaratıcı parçalar yer almaktadır. Ancak, Topluluk Kurallarımızda açıklandığı gibi, telif hakkı veya fikri mülkiyet yasalarını ihlal eden, nefret söylemini teşvik eden veya kişisel gizliliği ihlal eden içerikleri yasaklıyoruz.",
      },
    },
    {
      "@type": "Question",
      name: "Uygunsuz içeriği nasıl bildirebilirim?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Herhangi bir uygunsuz veya saldırgan içeriği bildirmek için kullanıcılar bize şu yolla ulaşabilir: support@tango.me. Hindistan'da bulunan kullanıcılar, Tango'nun Kullanım Koşulları'nda bulunan şikayet mekanizmamızla iletişime geçebilir. Ekibimiz raporu inceleyecek ve uygun önlemleri alacaktır.",
      },
    },
    {
      "@type": "Question",
      name: "Tango'da başkalarının kişisel bilgilerini paylaşabilir miyim?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Hayır, başkalarının kişisel bilgilerini Tango's üzerinde paylaşmak, onların izni olmadan yasaktır. Bu, fotoğraflar, videolar, profiller, tam adlar, adresler, telefon numaraları veya e-posta adreslerini paylaşmayı içerir.",
      },
    },
    {
      "@type": "Question",
      name: "Platformda fikri mülkiyet haklarımı nasıl koruyabilirim?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tango, fikri mülkiyet haklarına saygı duyar ve bu hakları korur. Kullanıcılar, izinsiz yüklenen telif hakkıyla korunan içerikleri copyright@tango.me e-posta adresi üzerinden bildirebilirler. Ekibimiz durumu inceleyecek ve gerekli önlemleri alacaktır.",
      },
    },
    {
      "@type": "Question",
      name: "Platformda içeriğimden para kazanabilir miyim?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Evet, kullanıcılar Sanatçı Topluluğuna katılarak Tango'da içeriklerini paraya çevirebilirler.",
      },
    },
    {
      "@type": "Question",
      name: "Hesabımı nasıl silebilirim?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Kullanıcılar, hesap ayarlarına gidip silme seçeneğini seçerek hesaplarını istedikleri zaman silebilirler. Tango, ardından tüm içeriğinizi ve hesap bilgilerinizi silecektir. Tango, geçerli herhangi bir yasa veya yasal yükümlülüğe uymak için gerekli olan verilerinizi saklama hakkını saklı tutar.",
      },
    },
  ],
};
