import isEmpty from "lodash.isempty";
import merge from "lodash.merge";
import { StreamEvents } from "src/enums";
import { reverse } from "src/utils/immutableArrayUtils";
import { mapValues, omit, pick, without } from "src/utils/miniLodash";
// eslint-disable-next-line no-restricted-imports
import {
  ACME_RECEIVED,
  GIFT_SEND_OPTIMISTIC,
  GIFT_SENT,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  SEND_MESSAGE_TO_SESSION_BEGIN,
  SEND_MESSAGE_TO_SESSION_END,
  VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH,
  VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED,
  VIEWER_SESSION_LEFT,
  VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR,
  VIEWER_SESSION_NOTIFICATIONS,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_SET_DIRTY_FLAGS,
  VIEWER_SESSION_UPDATE,
} from "state/actionTypes";
import { getMessageType } from "state/tree/utils/getMessageType";
import parseMessageDetails from "state/tree/utils/parseMessageDetails";
import ensureParameter from "../utils/ensureParameter";
import createFakeGiftEventFromSentGiftAction from "./createFakeGiftEventFromSentGiftAction";

const initialState = {
  notificationsShouldBeUpdated: true,
  eventIds: [],
  events: {},
  eventsNeedingReconciliation: [],
  lastNotificationId: "",
};

export const filterOutOwnGifts = (ids, events, userId) =>
  ids.filter((id) => {
    const event = events[id];

    if (!event) {
      return true;
    }

    const isGift = event.type === StreamEvents.GIFT;
    const isCurrentUser = event.accountId === userId;

    return !(isGift && isCurrentUser);
  });

const getIsExcludedEventType = (eventType) =>
  [
    StreamEvents.MULTI_BROADCAST_GIFT,
    StreamEvents.WEB_GIFTER_BONUS,
    StreamEvents.WEB_STREAM_BONUS,
  ].includes(eventType);

const getHiddenEventIds = (context, event) => {
  const {
    giftsDisplayQueue: { queue },
  } = context;

  const alreadySentGift = queue.find(({ accountId, giftId, mediaGift }) => {
    const isSameAccountAndGift =
      accountId === event?.accountId && giftId === event.data?.giftId;

    if (!isSameAccountAndGift && !getIsExcludedEventType(event?.type)) {
      return false;
    }

    if (!isEmpty(mediaGift)) {
      return mediaGift.gfyId === event.data.mediaGift?.gfyId;
    }

    return true;
  });

  return (
    alreadySentGift?.eventIds.filter((eventId) => eventId !== event.id) || []
  );
};

export const reconcileEvents = (fakeEvents, eventsLookupMap, mergedEvents) => {
  const reconciledEvents = [];
  const realIdsToRemove = new Set();

  if (!fakeEvents || !fakeEvents.length || !eventsLookupMap) {
    return { reconciledEvents, realIdsToRemove };
  }

  for (const fakeId of fakeEvents) {
    const fakeEvent = mergedEvents[fakeId];
    if (!fakeEvent) {
      continue;
    }

    const key = `${fakeEvent.clientEventId}-${fakeEvent.accountId}`;
    const found = eventsLookupMap[key];
    if (found) {
      realIdsToRemove.add(found.id);
      mergedEvents[found.id] = fakeEvent;
      reconciledEvents.push(fakeId);
    }
  }

  return { reconciledEvents, realIdsToRemove };
};

export default (state = initialState, action, context) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET: {
      const { streamId } = context;
      if (action.payload === streamId || action.meta.multibroadcastSwitch) {
        const { keepEventTypes } = action.meta;
        if (!keepEventTypes || !keepEventTypes.length) {
          return {
            ...initialState,
            lastNotificationId: state.lastNotificationId,
          };
        }
        const { eventIds, events, eventsNeedingReconciliation } = state;
        const eventIdsToRemove = eventIds.filter(
          (id) => !events[id] || !keepEventTypes.includes(events[id].type)
        );

        return {
          ...state,
          eventIds: without(eventIds, ...eventIdsToRemove),
          events: omit(events, ...eventIdsToRemove),
          notificationsShouldBeUpdated: true,
          eventsNeedingReconciliation: without(
            eventsNeedingReconciliation,
            ...eventIdsToRemove
          ),
        };
      }

      return { ...initialState, streamId: action.payload };
    }
    case VIEWER_SESSION_LEFT: {
      return {
        ...state,
        events: mapValues(state.events, (x) => ({ ...x, leftoverEvent: true })),
      };
    }

    case VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED: {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload]: {
            ...state.events[action.payload],
            leftoverEvent: true,
          },
        },
      };
    }

    case VIEWER_SESSION_NOTIFICATIONS: {
      const {
        result,
        entities: { notifications },
      } = action.payload;
      if (!result || !result.length) {
        return state;
      }
      const existingNotificationIds = state.eventIds.filter((x) => {
        const { type } = state.events[x];

        return type === StreamEvents.WARNING || type === StreamEvents.PROMOTION;
      });
      const groupEventKey = (x) => `${x.data}.${x.type}`;
      const deduplicationMap = existingNotificationIds.reduce((a, x) => {
        const key = groupEventKey(state.events[x]);
        a[key] = x;

        return a;
      }, {});
      const deduplicatedEventIds = reverse(result).filter((x) => {
        if (existingNotificationIds.includes(x)) {
          return false;
        }
        const event = notifications[x];
        const key = groupEventKey(event);
        if (deduplicationMap[key]) {
          return false;
        }
        deduplicationMap[key] = x;

        return true;
      });
      if (!deduplicatedEventIds.length) {
        return {
          ...state,
          lastNotificationId: result[result.length - 1],
        };
      }

      return {
        ...state,
        eventIds: [...deduplicatedEventIds, ...state.eventIds],
        events: {
          ...state.events,
          ...pick(parseMessageDetails(notifications), ...deduplicatedEventIds),
        },
        lastNotificationId: result[result.length - 1],
      };
    }
    case GIFT_SENT:
    case GIFT_SEND_OPTIMISTIC: {
      const event = createFakeGiftEventFromSentGiftAction(action);
      const hiddenEventIds = getHiddenEventIds(context, event);

      return {
        ...state,
        eventIds: without(
          Array.from(new Set([event.id, ...state.eventIds])),
          ...hiddenEventIds
        ),
        events: {
          ...state.events,
          [event.id]: event,
        },
      };
    }
    case SEND_MESSAGE_TO_SESSION_BEGIN: {
      const { streamId } = context;
      const { eventIds, events, eventsNeedingReconciliation } = state;
      const {
        payload: message,
        meta: { currentUserId, requestId },
      } = action;
      const event = {
        id: `FAKE:${streamId}:${currentUserId}:${requestId}`,
        accountId: currentUserId,
        clientEventId: JSON.stringify(requestId),
        data: { content: message },
        type: StreamEvents.MESSAGE,
        pending: true,
      };

      return {
        ...state,
        eventIds: [event.id, ...eventIds],
        events: {
          ...events,
          [event.id]: event,
        },
        eventsNeedingReconciliation: [...eventsNeedingReconciliation, event.id],
      };
    }
    case SEND_MESSAGE_TO_SESSION_END: {
      const { requestId, currentUserId, censored, failedToSend } = action.meta;
      const { events, eventIds, eventsNeedingReconciliation } = state;
      const clientEventId = JSON.stringify(requestId);
      const event = Object.values(events).find(
        (event) =>
          event.clientEventId === clientEventId &&
          event.accountId === currentUserId
      );
      if (event) {
        if (action.error) {
          return {
            ...state,
            eventIds: without(eventIds, event.id),
            events: omit(events, event.id),
            eventsNeedingReconciliation: without(
              eventsNeedingReconciliation,
              event.id
            ),
          };
        }

        return {
          ...state,
          eventsShouldBeUpdated: true,
          events: merge({}, events, {
            [event.id]: {
              pending: false,
              type: getMessageType({ failedToSend, censored }),
            },
          }),
        };
      }

      return state;
    }
    case VIEWER_SESSION_UPDATE:
    case VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT: {
      if (action.error) {
        return state;
      }

      const {
        payload: { entities: { events, basicProfile } = {}, eventIds },
        meta: { currentUserId },
      } = action;

      if (!events || !eventIds?.length) {
        return state;
      }

      // Build new events with profile
      const eventsWithProfile = {};
      for (const [eventId, event] of Object.entries(events)) {
        eventsWithProfile[eventId] = {
          ...event,
          profile: basicProfile?.[event.accountId],
        };
      }

      // Filter out IDs that already exist
      const existingIds = new Set(Object.keys(state.events));
      let filteredIds = eventIds.filter((id) => !existingIds.has(id));

      // Filter out own gifts
      if (currentUserId) {
        filteredIds = filterOutOwnGifts(
          filteredIds,
          eventsWithProfile,
          currentUserId
        );
      }

      // Deep-merge state.events with eventsWithProfile
      const mergedEvents = merge({}, state.events, eventsWithProfile);

      const realIdsToRemove = new Set();

      // Handle hidden events or repeated gifts
      const giftMap = {};
      for (const id of filteredIds) {
        const event = mergedEvents[id];
        if (!event) {
          continue;
        }

        const hiddenIds = getHiddenEventIds(context, event);
        hiddenIds.forEach((hid) => realIdsToRemove.add(hid));

        if (event.type === StreamEvents.GIFT) {
          const { giftId = "", mediaGift: { gfyId = "" } = {} } =
            event.data || {};
          const giftSignature = `${giftId}-${gfyId}`;

          if (giftMap[event.accountId] === giftSignature) {
            realIdsToRemove.add(id);
          } else {
            giftMap[event.accountId] = giftSignature;
          }
        }
      }

      // Reverse new IDs and combine with old IDs
      filteredIds.reverse();
      const combinedIds = [...filteredIds, ...state.eventIds];

      // Prepare a lookup map for reconciliation
      const eventsLookupMap = {};
      for (const [id, e] of Object.entries(eventsWithProfile)) {
        if (e.clientEventId && e.accountId) {
          const key = `${e.clientEventId}-${e.accountId}`;
          eventsLookupMap[key] = { id, e };
        }
      }

      // Reconcile events
      const { reconciledEvents, realIdsToRemove: reconciledIdsToRemove } =
        reconcileEvents(
          state.eventsNeedingReconciliation,
          eventsLookupMap,
          mergedEvents
        );

      reconciledIdsToRemove.forEach((id) => realIdsToRemove.add(id));

      const finalIds = [];
      const seen = new Set();

      for (let i = 0; i < combinedIds.length; i++) {
        const id = combinedIds[i];
        if (!seen.has(id) && !realIdsToRemove.has(id)) {
          const event = mergedEvents[id];
          // Skip join events except for the very first in the final list
          if (i !== 0 && event?.type === StreamEvents.JOIN) {
            continue;
          }
          seen.add(id);
          finalIds.push(id);
        }
      }

      return {
        ...state,
        notificationsShouldBeUpdated: false,
        events: mergedEvents,
        eventIds: finalIds,
        eventsNeedingReconciliation: state.eventsNeedingReconciliation.filter(
          (id) => !reconciledEvents.includes(id)
        ),
      };
    }
    case ACME_RECEIVED: {
      const { serviceIdentifier } = action.payload;
      const [command] = serviceIdentifier.split(":");
      if (command === "notification") {
        return {
          ...state,
          notificationsShouldBeUpdated: true,
        };
      }

      return state;
    }
    case VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH: {
      const { maxSize } = action.payload;
      if (state.eventIds.length <= maxSize) {
        return state;
      }
      const discardedEvents = state.eventIds.slice(maxSize);

      return {
        ...state,
        eventIds: state.eventIds.slice(0, maxSize),
        events: omit(state.events, ...discardedEvents),
        eventsNeedingReconciliation: without(
          state.eventsNeedingReconciliation,
          ...discardedEvents
        ),
      };
    }
    case VIEWER_SESSION_SET_DIRTY_FLAGS: {
      const {
        notifications:
          notificationsShouldBeUpdated = state.notificationsShouldBeUpdated,
      } = action.payload;

      return {
        ...state,
        notificationsShouldBeUpdated,
      };
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED: {
      const {
        payload: {
          entities: { events = {} },
          eventIds = [],
        },
        meta: { myAccountId },
      } = action;

      const hiddenEventIds = [];
      const eventIdSet = new Set(state.eventIds);

      const newEventIds = eventIds.filter((x) => {
        const event = events[x];
        hiddenEventIds.push(...getHiddenEventIds(context, event));

        return !eventIdSet.has(x) && event?.accountId !== myAccountId;
      });

      if (!newEventIds.length) {
        return state;
      }

      return {
        ...state,
        eventIds: without(
          [...newEventIds, ...state.eventIds],
          ...hiddenEventIds
        ),
        events: {
          ...state.events,
          ...parseMessageDetails(events),
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN: {
      const {
        payload: { event },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslated: false,
            isTranslating: true,
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END: {
      const {
        payload: { event, language, locale, translated },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            language,
            translation: {
              ...state.events[id].translation,
              ...(translated && { [locale]: translated }),
            },
            isTranslated: true,
            isTranslating: false,
            ...(state.events[id].error && { error: undefined }),
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR: {
      const {
        payload: { event, error },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslating: false,
            error,
          },
        },
      };
    }
    case VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED: {
      const {
        payload: { event, isTranslated },
      } = action;
      const { id } = event;

      return {
        ...state,
        events: {
          ...state.events,
          [id]: {
            ...event,
            isTranslated,
          },
        },
      };
    }
  }

  return state;
};

const getEventIds = (state) =>
  state.eventIds.filter((x) => {
    const event = state.events[x];

    return event && !event.pending;
  });

export const selectors = {
  getEventIds,
  getEvent: (state, id) => ensureParameter(id, "id") && state.events[id],
  getEvents: (state) => state.events,
  getLastEvent: (state) => {
    if (!state.eventIds.length) {
      return null;
    }

    const lastEventId = state.eventIds[0];

    return state.events[lastEventId];
  },
  shouldUpdateNotifications: (state) => state.notificationsShouldBeUpdated,
  getLastNotificationId: (state) => state.lastNotificationId,
  getNonReconciledEvents: (state) => state.eventsNeedingReconciliation, // events that were sent, but we didn't receive them from the server yet, and we don't know their real ids
};
