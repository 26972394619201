import { batch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { fetchCustomGifts } from "src/features/customGift/exports/api";
import { GetCustomGiftsStatus } from "src/features/customGift/exports/enums";
import { fetchGiftDrawer } from "src/features/giftsDrawer/api/giftDrawerApi";
import {
  GetMyGiftDrawerResponseCode,
  GiftDrawerTarget,
  isGiftType,
} from "src/features/giftsDrawer/api/giftDrawerTypes";
import { getIsCustomGiftViewEnabled } from "src/features/giftsDrawer/imports/abTests";
import { setCustomGifts } from "src/features/giftsDrawer/imports/action";
import { RootState } from "src/features/giftsDrawer/imports/state";
import { loadGiftDrawerRejected, setGiftsDrawer } from "./action";
import { giftDrawerSelectors } from "./giftDrawerSelectors";

interface LoadGiftDrawerParams {
  isLoadLatest?: boolean;
  target: GiftDrawerTarget;
}

export const loadGiftDrawer =
  ({ target, isLoadLatest }: LoadGiftDrawerParams) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const state = getState();
      const info = giftDrawerSelectors.getDrawerInfo(state, target);
      const isCustomGiftViewEnabled = getIsCustomGiftViewEnabled(state);

      const [giftDrawerData, customGiftData] = await Promise.all([
        fetchGiftDrawer({
          target,
          ...(!isLoadLatest && info),
        }),
        isCustomGiftViewEnabled && fetchCustomGifts(),
      ]);

      const isCustomGiftsReceived =
        customGiftData &&
        customGiftData.status === GetCustomGiftsStatus.SUCCESS;

      if (giftDrawerData.status === GetMyGiftDrawerResponseCode.FAILED) {
        dispatch(loadGiftDrawerRejected());

        return;
      }

      if (
        giftDrawerData.status === GetMyGiftDrawerResponseCode.UPDATED ||
        giftDrawerData.status === GetMyGiftDrawerResponseCode.LATEST
      ) {
        const categories = giftDrawerData.giftDrawer.categories.map(
          (category) => {
            const { collectionIds, giftIds } = category.entities.reduce(
              (acc, gift) => {
                if (isGiftType(gift)) {
                  acc.giftIds.push(gift.gift.encryptedGiftId);
                } else {
                  acc.collectionIds.push(
                    gift.giftCollection.encryptedCollectionId
                  );
                }

                return acc;
              },
              {
                collectionIds: [],
                giftIds: [],
              } as {
                collectionIds: string[];
                giftIds: string[];
              }
            );

            return {
              id: category.encryptedCategoryId,
              name: category.categoryMetadata.name,
              icon: category.categoryMetadata.iconUrl,
              activeIcon: category.categoryMetadata.activeIconUrl,
              collectionIds,
              giftIds,
            };
          }
        );

        batch(() => {
          dispatch(
            setGiftsDrawer({
              target,
              drawerId: giftDrawerData.giftDrawer.encryptedDrawerId,
              drawerVersion: giftDrawerData.giftDrawer.drawerVersion,
              categories,
            })
          );

          if (isCustomGiftsReceived) {
            dispatch(setCustomGifts(customGiftData.customGifts || []));
          }
        });

        return;
      }

      batch(() => {
        if (isCustomGiftsReceived) {
          dispatch(setCustomGifts(customGiftData.customGifts || []));
        }

        dispatch(loadGiftDrawerRejected());
      });
    } catch (error) {
      dispatch(loadGiftDrawerRejected());
    }
  };
