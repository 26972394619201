import { defineMessages } from "react-intl";
export const broadcastLobbyMessages = defineMessages({
  invalidInput: {
    id: "broadcast.title.invalid",
    defaultMessage: "Please enter valid broadcast title",
  },
  streamCover: {
    id: "broadcast.lobby.stream.cover",
    defaultMessage: "Stream Cover",
  },
  notificationForFollowers: {
    id: "broadcast.lobby.title.label",
    defaultMessage: "Notification for Followers",
  },
  permissionTitle: {
    id: "broadcast.permissions",
    defaultMessage: "Permissions",
  },
  permissionDescription: {
    id: "broadcast.before-start",
    defaultMessage:
      "Before you start your broadcast, you will be asked to allow access to the following:",
  },
  permissionConfirm: {
    id: "broadcast.permissions.confirm",
    defaultMessage: "Got It",
  },
  camera: {
    id: "broadcast.option.camera",
    defaultMessage: "Camera",
  },
  microphone: {
    id: "broadcast.permission-microphone",
    defaultMessage: "Microphone",
  },
  permissionDeniedError: {
    id: "broadcast.lobby.error.permission.denied",
    defaultMessage: "Tango needs access {br} to your camera and microphone",
  },
  battleDuration: {
    id: "broadcast.lobby.battle.duration",
    defaultMessage: "Battle Duration",
  },
  mic: {
    id: "broadcast.lobby.mic",
    defaultMessage: "Mic",
  },
  livePartyInviteSetting: {
    id: "broadcast.lobby.live.party.invite.setting",
    defaultMessage: "Who can invite me in Party",
  },
  muted: {
    id: "broadcast.lobby.muted",
    defaultMessage: "Muted",
  },
  premiumBroadcast: {
    id: "broadcast.premium",
    defaultMessage: "Premium",
  },
  partyBroadcast: {
    id: "broadcast.party",
    defaultMessage: "Party",
  },
  livePartyBroadcast: {
    id: "broadcast.live.party",
    defaultMessage: "Live Party",
  },
  invalidBroadcastPicture: {
    id: "broadcast.lobby.invalid.picture",
    defaultMessage: "Please, set Stream Cover",
  },
  premiumEntryGift: {
    id: "broadcast.lobby.premium.entry.gift",
    defaultMessage: "Entry gift",
  },
  entryGift: {
    id: "broadcast.lobby.entry.gift",
    defaultMessage: "Select Entry Gift",
  },
  goPremium: {
    id: "broadcast.lobby.premium.go.live",
    defaultMessage: "Go Premium",
  },
  changeCover: {
    id: "broadcast.lobby.change.cover",
    defaultMessage: "Change Cover",
  },
  iStartedBroadcast: {
    id: "broadcast.lobby.i.started.broadcast",
    defaultMessage: "I started Broadcast",
  },
  giftSoundsSettingsTitle: {
    id: "broadcast.lobby.settings.bottomScreen.giftAnimationSounds",
    defaultMessage: "Gift Sounds",
  },
  streamSettings: {
    id: "broadcast.lobby.stream.settings",
    defaultMessage: "Stream Settings",
  },
  masksAndFiltersTitle: {
    id: "broadcast.lobby.masks.and.filters",
    defaultMessage: "Masks & Filters",
  },
  quickPicInvitesTitle: {
    id: "broadcast.lobby.quick.tips.invites.title",
    defaultMessage: "Collaborate and stream with your Friends",
  },
  quickPicInvitesDescription: {
    id: "broadcast.lobby.quick.tips.invites.description",
    defaultMessage:
      "Invite Friends to Live Party during stream{br} to boost the fun!",
  },
  quickTipsTitle: {
    id: "broadcast.lobby.quick.tips.title",
    defaultMessage: "QUICK TIP",
  },
  regularStreamTitle: {
    id: "broadcast.lobby.regular.stream",
    defaultMessage: "Regular Stream",
  },
  obsStreamTitle: {
    id: "broadcast.lobby.obs.stream",
    defaultMessage: "OBS Stream",
  },
});
