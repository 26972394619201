import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import { ConversationMessagesMenuContextProvider } from "chat/components/currentConversation/ConversationMessagesMenuContextProvider";
import { ArrowIconBottom as ArrowIcon } from "chat/imports/assets";
import { BottomMarker, Button } from "chat/imports/components";
import {
  Breakpoints,
  ButtonSize,
  ButtonVariant,
  DeviceType,
} from "chat/imports/constants";
import {
  useBreakpointPrecise,
  useExclusiveClickHandler,
  usePrevious,
  useResizeObserver,
} from "chat/imports/hooks";
import {
  RootState,
  deviceInfoSelectors,
  userSelectors,
} from "chat/imports/state";
import { Nullable, VoidCallback } from "chat/imports/types";
import styles from "./ConversationMessagesList.scss";

const isScrollListAtBottom = (
  scrollHeight: number,
  scrollTop: number,
  clientHeight: number
) => scrollHeight - scrollTop - clientHeight < 1;

type MessagesListProps = {
  className: string;
  isBlocked?: boolean;
  isChatRequestDisclaimerHidden?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  isShowDisclaimer?: boolean;
  latestMessageFrom: string | undefined;
  messageIds: string[];
  onAtBottom?: VoidCallback;
};

const ConversationMessagesList: React.FC<MessagesListProps> = ({
  children,
  messageIds,
  className,
  latestMessageFrom,
  onAtBottom,
  isChatRequestDisclaimerHidden = true,
  isBlocked = false,
  isShowDisclaimer = false,
  isHidden = false,
  isLoading = false,
  ...rest
}) => {
  const breakpoint = useBreakpointPrecise();
  const rootRef = useRef<HTMLDivElement>(null);
  const positionRef = useRef<HTMLDivElement>(null);
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const { isIos, isLatestMessageFromMe } = useSelector(
    useCallback(
      (state: RootState) => {
        const deviceType = deviceInfoSelectors.getDeviceType(state);
        const myAccountId = userSelectors.getMyAccountId(state);

        return {
          isIos:
            deviceType === DeviceType.IOS || deviceType === DeviceType.IPAD,
          isLatestMessageFromMe: myAccountId === latestMessageFrom,
        };
      },
      [latestMessageFrom]
    ),
    shallowEqual
  );
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const { height } = useResizeObserver(rootRef);
  const prevHeight = usePrevious(height);
  const autoScrollRefs = useRef<{
    isPaused: boolean;
    isScrolledOnResize: boolean;
    prevLastMessageId: Nullable<string>;
    prevMessagesCount: number;
    prevScrollHeight: number;
    prevScrollTop: number;
  }>({
    isPaused: false,
    isScrolledOnResize: false,
    prevMessagesCount: 0,
    prevLastMessageId: null,
    prevScrollHeight: 0,
    prevScrollTop: 0,
  }).current;
  const lastMessageId = messageIds[0];

  const scrollToBottom = useCallback(() => {
    if (rootRef.current) {
      rootRef.current.scrollTo(0, rootRef.current.scrollHeight);
    }
  }, [rootRef]);

  useEffect(() => {
    if (rootRef.current && !isChatRequestDisclaimerHidden) {
      scrollToBottom();
    }
  }, [
    isChatRequestDisclaimerHidden,
    isDesktop,
    rootRef.current,
    scrollToBottom,
  ]);

  useLayoutEffect(() => {
    const rootElement = rootRef.current;

    if (!rootElement) {
      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = rootElement;

    if (
      autoScrollRefs.prevMessagesCount === messageIds.length &&
      autoScrollRefs.prevLastMessageId === lastMessageId
    ) {
      return;
    }

    autoScrollRefs.prevMessagesCount = messageIds.length;

    if (isScrollListAtBottom(scrollHeight, scrollTop, clientHeight)) {
      // do nothing if already at the bottom of the list
      autoScrollRefs.prevLastMessageId = lastMessageId;

      return;
    }

    if (autoScrollRefs.isPaused) {
      if (
        autoScrollRefs.prevLastMessageId !== null &&
        autoScrollRefs.prevLastMessageId !== lastMessageId
      ) {
        autoScrollRefs.prevLastMessageId = lastMessageId;

        if (isLatestMessageFromMe) {
          // scroll to bottom after sending message
          scrollToBottom();

          return;
        }
        // skip scroll if unread messages loaded and user paused autoscroll
        setHasUnreadMessages(true);

        return;
      }

      if (isIos) {
        // preventing ios momentum scroll glitch
        rootElement.style.overflow = "hidden";
      }
      // restore current scroll after older messages loaded
      rootElement.scrollTo(
        0,
        autoScrollRefs.prevScrollTop +
          (scrollHeight - autoScrollRefs.prevScrollHeight)
      );

      if (isIos) {
        rootElement.style.removeProperty("overflow");
      }

      return;
    }

    // scroll to bottom if autoscroll is not paused by user
    autoScrollRefs.prevLastMessageId = lastMessageId;
    scrollToBottom();
  }, [
    messageIds.length,
    lastMessageId,
    autoScrollRefs,
    isIos,
    isLatestMessageFromMe,
    scrollToBottom,
  ]);

  useEffect(() => {
    if (isBlocked && isDesktop) {
      scrollToBottom();
    }
  }, [isBlocked, isDesktop]);

  useLayoutEffect(() => {
    if (
      rootRef.current &&
      prevHeight &&
      prevHeight !== height &&
      !autoScrollRefs.isPaused
    ) {
      // triggered only when autoscroll is active
      // keep chat list at the bottom on resize
      // similar behaviour as in telegram
      autoScrollRefs.isScrolledOnResize = true;
      scrollToBottom();
    }
  }, [height, prevHeight, autoScrollRefs, scrollToBottom]);

  const onClickMoreMessagesMarker = useExclusiveClickHandler(scrollToBottom);

  const handleScroll = useCallback(() => {
    if (!rootRef.current) {
      return;
    }
    if (autoScrollRefs.isScrolledOnResize) {
      autoScrollRefs.isScrolledOnResize = false;

      return;
    }

    const { scrollHeight, scrollTop, clientHeight } = rootRef.current;

    if (isScrollListAtBottom(scrollHeight, scrollTop, clientHeight)) {
      // resume autoscroll
      autoScrollRefs.isPaused = false;
      setHasUnreadMessages(false);
      onAtBottom && onAtBottom();

      return;
    }

    // we need to update prevScrollTop / prevScrollHeight only when autoscroll is paused
    autoScrollRefs.isPaused = true;
    autoScrollRefs.prevScrollTop = Math.max(scrollTop, 0);
    autoScrollRefs.prevScrollHeight = scrollHeight;
  }, [autoScrollRefs, onAtBottom]);

  return (
    <div
      className={classnames(
        styles.positioningContainer,
        className,
        styles[breakpoint]
      )}
      {...rest}
      ref={positionRef}
    >
      <ConversationMessagesMenuContextProvider ref={positionRef}>
        <div
          className={classnames(styles.scrollableContainer, {
            [styles.scrollableContainerWithDisclaimer]:
              !isChatRequestDisclaimerHidden &&
              isShowDisclaimer &&
              isHidden &&
              !isLoading,
            [styles.scrollableContainerWithDisclaimerForSender]:
              isShowDisclaimer && !isHidden,
          })}
          id="scrollableConversationMessages"
          data-testid="conversation-messages-list"
          ref={rootRef}
          onScroll={handleScroll}
        >
          <div className={styles.container}>{children}</div>
          <BottomMarker />
        </div>
        {hasUnreadMessages && (
          <Button
            className={styles.marker}
            size={ButtonSize.MEDIUM_32}
            variant={ButtonVariant.ANCHOR}
            onClick={onClickMoreMessagesMarker}
            data-testid="unread-messages-marker"
            leftIcon={ArrowIcon}
          >
            <FormattedMessage
              id="message-list.messages-marker"
              defaultMessage="More messages"
            />
          </Button>
        )}
      </ConversationMessagesMenuContextProvider>
    </div>
  );
};

export default ConversationMessagesList;
