import { StorageKeys } from "src/core/analytics/dataMesh/common/enums";
import { dataMeshParamsStorage } from "src/core/analytics/dataMesh/utils/dbMethods/dataMeshParamsStorage";
import { DataMeshEventPayload } from "src/core/analytics/types";

export const getEventBuffer = async (): Promise<
  Array<DataMeshEventPayload>
> => {
  const eventsBufferString = await dataMeshParamsStorage.getItem(
    StorageKeys.SESSION_BUFFER
  );

  return JSON.parse(eventsBufferString || "[]");
};
