import { createAsyncThunk } from "@reduxjs/toolkit";
import { isApiError } from "api/utils/enhancedFetch";
import { loadPoints } from "src/api/points";
import { PointsResponse } from "src/types/points";

interface LoadPointsInfoParams {
  accountId: number;
  userName: string;
}

export const loadPointsInfo = createAsyncThunk<
  PointsResponse,
  LoadPointsInfoParams
>(
  "lwc/pointsUserInfo/get",
  async ({ accountId, userName }, { rejectWithValue }) => {
    try {
      return await loadPoints({
        accountId,
        userName,
      });
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return rejectWithValue(error);
    }
  }
);
