import { getEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/getEventBuffer";
import { getDataMeshEventBufferSize } from "src/core/analytics/imports/environment";

export const checkIsEventBufferSizeExceeded = async () => {
  const bufferSize = getDataMeshEventBufferSize();

  const eventsBuffer = await getEventBuffer();

  return eventsBuffer.length >= bufferSize - 1;
};
