import { createSlice } from "@reduxjs/toolkit";
import {
  AsyncState,
  addAsyncCasesToBuilderV2,
  addUserSessionScopeReducer,
  initialFetcherStateMeta,
} from "chat/imports/state";
import * as actions from "./asyncAction";

export type LiveBarState = AsyncState;

export const initialState: LiveBarState = {
  data: null,
  meta: { ...initialFetcherStateMeta, stale: false },
};

const slice = createSlice({
  name: "liveBar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSessionScopeReducer(
      addAsyncCasesToBuilderV2({
        builder,
        action: actions.fetchLiveBarRequest,
        initialData: initialState.data,
        prepareData: (_, newConfig) => newConfig,
      }),
      () => initialState
    );
  },
});

export const liveBarReducer = slice.reducer;
