import { createSlice } from "@reduxjs/toolkit";
import { CustomGiftType } from "src/features/customGift/api/customGiftTypes";
import { addUserSessionScopeReducer } from "src/features/customGift/imports/state";
import { setCustomGifts } from "./action";

export interface CustomGiftState {
  customGifts: CustomGiftType[];
}

const initialState: CustomGiftState = {
  customGifts: [],
};

const slice = createSlice({
  name: "customGift",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addUserSessionScopeReducer(
      builder.addCase(setCustomGifts, (state, action) => {
        state.customGifts = action.payload;
      }),
      () => initialState
    );
  },
});

export const customGiftReducer = slice.reducer;
