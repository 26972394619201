import { getProxycadorHost } from "chat/imports/environment";
import { enhancedFetch as fetch } from "chat/imports/utils";
import {
  GetLiveBarRequestsParams,
  GetLiveBarRequestsResponse,
} from "chat/liveBar/types";
import { StreamListResponse } from "generated/proto/SocialStreamV1";
import { parseMessageFromBase64 } from "utils/protobufUtil";

export const getLiveBar = (
  params: GetLiveBarRequestsParams
): Promise<GetLiveBarRequestsResponse> =>
  fetch(`${getProxycadorHost()}/chat-live-bar/v1/live-bar/get`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((resp) => resp.json())
    .then((response) => {
      const streamListRecords = parseMessageFromBase64(
        response?.streamList,
        StreamListResponse
      )?.records;

      return {
        ...response,
        streamList: streamListRecords,
      };
    });
