import { EventFields } from "@analytics/enums";
import streamKindToAnalyticsStreamKind from "@analytics/streamKindToAnalyticsStreamKind";
import { HAPPY_MOMENTS, POST, PROFILE, STREAM } from "enums/giftRecipientType";
import { CustomGiftStatus } from "src/features/customGift/api/customGiftTypes";
import { customGiftSelectors } from "src/features/customGift/state/selectors";
import { giftDrawerSelectors } from "src/features/giftsDrawer/exports/state";
import { GiftCurrency } from "src/state/enums/giftCurrencyType";
import {
  giftingRequestsSelectors,
  giftsCacheSelectors,
  settingsSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import { giftRecipientTypeToGiftSource } from "./giftSource";

export const selectBaseGiftingParams = (state, giftId, target) => {
  const recipientType = giftingRequestsSelectors.getRecipientType(state);
  const recipientAccountId =
    giftingRequestsSelectors.getRecipientAccountId(state);
  const secondaryRecipientId =
    giftingRequestsSelectors.getSecondaryRecipientId(state);
  const oneClickGiftingEnabled =
    settingsSelectors.isOneClickGiftingEnabled(state);
  const giftOption = giftsCacheSelectors.getOptionLastClickedGift(state);

  const giftCategory = giftDrawerSelectors.getCategoryById(
    state,
    giftOption.categoryId,
    target
  );

  const analyticParams = {
    [EventFields.GIFT_SOURCE]: giftRecipientTypeToGiftSource(recipientType),
    [EventFields.GIFT_ID]: giftId,
    [EventFields.ONE_CLICK_GIFTING]: oneClickGiftingEnabled ? 1 : 0,
    [EventFields.ONE_CLICK_PURCHASE]: 0,
    [EventFields.ITEM_TYPE]: giftCategory?.name,
  };

  switch (recipientType) {
    case PROFILE: {
      analyticParams[EventFields.PROFILE_ID] = recipientAccountId;
      break;
    }
    case POST: {
      analyticParams[EventFields.FEED_POST_ID] = secondaryRecipientId;
      analyticParams[EventFields.FEED_POST_AUTHOR_ID] = recipientAccountId;
      break;
    }
    case STREAM: {
      analyticParams[EventFields.STREAM_ID] = secondaryRecipientId;
      analyticParams[EventFields.STREAM_KIND] = streamKindToAnalyticsStreamKind(
        viewerSessionSelectors.getStreamKind(state, secondaryRecipientId)
      );
      analyticParams[EventFields.ITEM_TYPE] =
        giftCategory?.name || "moods_on_screen";
      analyticParams[EventFields.POSITION] = giftOption.positionIndex + 1;

      break;
    }
    case HAPPY_MOMENTS: {
      analyticParams[EventFields.HAPPY_MOMENT_ID] = secondaryRecipientId;
      break;
    }
  }

  return analyticParams;
};

export const selectBaseGiftingParamV2 = (state, giftId) => {
  const recipientType = giftingRequestsSelectors.getRecipientType(state);
  const recipientAccountId =
    giftingRequestsSelectors.getRecipientAccountId(state);
  const secondaryRecipientId =
    giftingRequestsSelectors.getSecondaryRecipientId(state);
  const oneClickGiftingEnabled =
    settingsSelectors.isOneClickGiftingEnabled(state);
  const giftOption = giftsCacheSelectors.getOptionLastClickedGift(state);

  let gift = giftsCacheSelectors.getGiftById(state, giftId);

  if (!gift) {
    gift = customGiftSelectors.getCustomGiftByIdAsGift(state, giftId, [
      CustomGiftStatus.RELEASED,
    ]);
  }

  const isBuyWithCoins =
    giftsCacheSelectors.isBuyGiftWithCoins(state) || !gift.withdrawInPoint;

  const giftPrice = isBuyWithCoins ? gift.priceInCredit : gift.withdrawInPoint;

  const giftCurrency = isBuyWithCoins
    ? GiftCurrency.COINS
    : GiftCurrency.DIAMONDS;

  const analyticParams = {
    [EventFields.GIFT_SOURCE]: giftRecipientTypeToGiftSource(recipientType),
    [EventFields.GIFT_SOURCE_ID]: secondaryRecipientId,
    [EventFields.GIFT_ID]: giftId,
    [EventFields.IS_ONE_CLICK_GIFT]: oneClickGiftingEnabled ? 1 : 0,
    [EventFields.ONE_CLICK_PURCHASE]: 0,
    [EventFields.PEER_ID]: recipientAccountId,
    [EventFields.GIFT_CURRENCY]: giftCurrency,
    [EventFields.GIFT_PRICE]: giftPrice,
    [EventFields.IS_COMBO_GIFT]: false,
  };

  if (recipientType === STREAM) {
    analyticParams[EventFields.POSITION] = giftOption.positionIndex + 1;
  }

  return analyticParams;
};
