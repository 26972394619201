import debounce from "lodash.debounce";
import { getEventBuffer } from "src/core/analytics/dataMesh/utils/bufferMethods/getEventBuffer";
import { sendEventUtil } from "src/core/analytics/dataMesh/utils/sendEventUtil";
import { getDataMeshEventBufferFlushTime } from "src/core/analytics/imports/environment";

export const checkIfEventBufferIsStale = debounce(async () => {
  const eventsBuffer = await getEventBuffer();

  if (eventsBuffer.length) {
    sendEventUtil(eventsBuffer, true);
  }
}, getDataMeshEventBufferFlushTime());
