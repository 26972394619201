import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadRedeemConfig } from "src/features/finance/redeems/api/redeemsApi";
import { isApiError } from "src/features/finance/redeems/imports/utils";
import { RedeemConfigResponse } from "src/features/finance/redeems/root/type";

interface LoadRedeemConfigParams {
  accountId: number;
}

export const loadRedeemConfigInfo = createAsyncThunk<
  RedeemConfigResponse,
  LoadRedeemConfigParams
>("lwc/redeemConfig/get", async ({ accountId }, { rejectWithValue }) => {
  try {
    return await loadRedeemConfig({
      accountId,
    });
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return rejectWithValue(error);
  }
});
