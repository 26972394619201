import React from "react";
import { useSelector } from "react-redux";
import MessageActionsMenu from "chat/components/MessageMenu/MessageActionsMenu/MessageActionsMenu";
import { MessageAnimatedMenu } from "chat/components/MessageMenu/MessageAnimatedMenu/MessageAnimatedMenu";
import { getIsMessageAnimationEnabled } from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state";

interface MessageMenuBranchProps {
  accountId?: string;
  children: React.ReactNode;
  containerClassName: string;
  conversationId: string;
  isMessageMenuEnabled: boolean;
  isMyMessage: boolean;
  isPopoverOpen: boolean;
  message: StoredMessage;
  setPopoverOpen: (arg: boolean) => void;
}

export const MessageMenuBranch = ({
  children,
  isMessageMenuEnabled,
  message,
  isPopoverOpen,
  setPopoverOpen,
  conversationId,
  isMyMessage,
  containerClassName,
  accountId,
}: MessageMenuBranchProps) => {
  const isMessageAnimationEnabled = useSelector(getIsMessageAnimationEnabled);

  return isMessageAnimationEnabled ? (
    <MessageAnimatedMenu
      message={message}
      conversationId={conversationId}
      isMyMessage={isMyMessage}
      containerClassName={containerClassName}
      accountId={accountId}
    >
      {children}
    </MessageAnimatedMenu>
  ) : (
    <MessageActionsMenu
      isMessageMenuEnabled={isMessageMenuEnabled}
      message={message}
      isMyMessage={isMyMessage}
      isPopoverOpen={isPopoverOpen}
      setPopoverOpen={setPopoverOpen}
      accountId={accountId}
    >
      {children}
    </MessageActionsMenu>
  );
};
